import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { setAdminSliderImages, showDialog, showAlert } from './../../actions';
import { getDataFromServer } from './../../DataProvider';
import config from './../../config';
import store from './../../store';
import Grid from '@material-ui/core/Grid';
import Dialog from './../Dialog';
import SnackbarsCustom from './../SnackbarsCustom';
import FileUpload from './../FileUploadSlider';
import IconDelete from '@material-ui/icons/Delete';
import CheckBoxGeneral from './../CheckBoxGeneral';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: '20px 80px 80px 80px',      
    },
    cartImage: {
        width: '100%',
        height: '160px',
    },
    rowGrid: {
        marginTop: '20px',
    },
    productName:{
        'font-size' : '18px',
        'text-align': 'center',
        marginBottom: '10px',
        textDecoration: 'underline',         
    },
    mobileTitle:{
        'font-size' : '18px',
        'text-align': 'center',
        marginTop: '10px',
        textDecoration: 'underline',
    },
    mobileBodyFile:{
        margin: '10px auto',
        width: '95%',
    },
    mobileImageBody:{
        padding: '10px 30%',
    },
    mobileImage:{
        width: 'auto',
        height: 'auto',
        maxWidth: '200px',
        maxHeight: '400px',
    }
});

class AdminSlider extends Component{
    constructor(props){
        super(props);
        this.state = {removeImageId: '', removeMobileImage: ''};
    }

    componentDidMount(){
        this.getSliderImagesFromServer();
    }

    getSliderImagesFromServer = () => {
        getDataFromServer(config.params.server_path + '/list_slider_images')
        .then(response => {        
            if(response.data.success === true){
                store.dispatch(setAdminSliderImages(response.data.data));
            }
            console.log("AdminSlider.getImagesFromServer => ", response.data.data);
        })
        .catch(err => {console.log("AdminSlider.getImagesFromServer => error: ", err)});
    }

    handleCheckBoxChange = name => e => {
        console.log("AdminSlider", 'handleCheckBoxChange', e.target.checked, e.target.value);
        getDataFromServer(config.params.server_path + '/update_slider_images', {sliderObj: {enable: e.target.checked, id: e.target.value}})
        .then(response => {        
            if(response.data.success === true){
                this.getSliderImagesFromServer(); 
            }
            console.log("AdminSlider.update_slider_images => ", response.data.data);
        })
        .catch(err => {console.log("AdminSlider.update_slider_images => error: ", err)});
        //store.dispatch(adminEditProduct(e.target.value, e.target.checked));
    }

    removeImage = ()=>{
        this.handlCloseDialog();
        getDataFromServer(config.params.server_path + '/delete_slider_images', {sliderId: this.state.removeImageId})
        .then(response => {        
            if(response.data.success === true){
                this.getSliderImagesFromServer(); 
            }
            console.log("AdminSlider.removeImage => ", response.data.data);
        })
        .catch(err => {console.log("AdminSlider.removeImage => error: ", err)});
    }   

    removeImageDialog = (imageId) => {
        let dialogData = { 
            title: 'Warning', 
            text: 'Do you want to remove slader image ?', 
            buttons: [{event: this.removeImage, label: 'Remove slider image'}], 
            show: true 
        }
        this.state.removeImageId = imageId;
        store.dispatch(showDialog(dialogData));
    }

    removeMobileImageDialog = (imageId) => {
        let dialogData = { 
            title: 'Warning', 
            text: 'Do you want to remove slader mobile image ?', 
            buttons: [{event: this.handleRemoveMobileImage, label: 'Remove slider mobile image'}], 
            show: true 
        }
        this.state.removeMobileImage = imageId;
        store.dispatch(showDialog(dialogData));
    }

    handleAddImageDrop = (name) =>{
        let link = config.params.server_path + '/image/slider/' + name;      
        let image = {title: name, link: link, enable: false};
        console.log("AdminSlider.handleAddImageDrop => ", 'start', image);
        getDataFromServer(config.params.server_path + '/create_slider_images', {sliderObj: image})
        .then(response => {        
            if(response.data.success === true){
                this.getSliderImagesFromServer(); 
            }
            console.log("AdminSlider.handleAddImageDrop => ", response.data.data);
        })
        .catch(err => {console.log("AdminSlider.handleAddImageDrop => error: ", err)});        
    }

    handleUpdateImageDrop = (id, name) =>{
        let link = config.params.server_path + '/image/slider/' + name;      
        let image = {id: id, link_mobile: link};
        console.log("AdminSlider.handleAddImageDrop => ", 'start', image);
        getDataFromServer(config.params.server_path + '/update_slider_images', {sliderObj: image})
        .then(response => {        
            if(response.data.success === true){
                this.getSliderImagesFromServer(); 
            }
            console.log("AdminSlider.handleAddImageDrop => ", response.data.data);
        })
        .catch(err => {console.log("AdminSlider.handleAddImageDrop => error: ", err)});        
    }

    handleSaveRespoceImageDrop = (err, result) => {        
        let alert={};
        if(err){
            alert = {text: 'Faild save slider image, error: ' + err, variant: 'error', show: true, event: this.onAlertClose }
            store.dispatch(showAlert(alert));
        }else if(result.data.success !== true){
            alert = {text: 'Failed save slider image, error: ' + result.data.text, variant: 'error', show: true, event: this.onAlertClose }
            store.dispatch(showAlert(alert));
        }
    }

    handleRemoveMobileImage = (id, name) =>{ 
        this.handlCloseDialog();            
        let image = {id: this.state.removeMobileImage, link_mobile: ''};
        console.log("AdminSlider.handleAddImageDrop => ", 'start', image);
        getDataFromServer(config.params.server_path + '/update_slider_images', {sliderObj: image})
        .then(response => {        
            if(response.data.success === true){
                this.getSliderImagesFromServer(); 
            }
            console.log("AdminSlider.handleAddImageDrop => ", response.data.data);
        })
        .catch(err => {console.log("AdminSlider.handleAddImageDrop => error: ", err)});        
    }

    handlCloseDialog = (show) => {
        let dialogData = { title: '', text: '', buttons: [], show: false }
        store.dispatch(showDialog(dialogData));
    }

    onAlertClose = () => {
        let alert = {text: '', variant: 'success', show: false, event: ''};
        store.dispatch(showAlert(alert));
    }

    render(){
        let {classes, dialog, sliderImages} = this.props;
        let isLoad = sliderImages.length > 0;
        return(
            <div className={classes.root}>
                {alert.show ? 
                    <SnackbarsCustom data={alert} />
                    :''
                }
                <Dialog dialogData={ dialog } handlDialog={this.handlCloseDialog}/>
                <Grid container spacing={8} className={classes.rowGrid}>
                    <Grid item xs={12} sm={12} md={12}>
                        <FileUpload handleAddImageDrop={this.handleAddImageDrop} 
                                    handleSaveRespoceImageDrop={this.handleSaveRespoceImageDrop} 
                                    imageType='desktop' />        
                    </Grid>
                </Grid>
                <Grid container spacing={8} className={classes.rowGrid}>
                    {sliderImages.map((image, index)=>
                        <Grid item xs={4} sm={4} md={4}>
                            <Card key={index} className={classes.card}>
                                <Typography component="p" className={classes.productName} >
                                    Desktop picture
                                </Typography>                                
                                <img src={image.link} title={ image.title } alt={ image.title } className={classes.cartImage} />
                                <Typography component="p" className={classes.mobileTitle} >
                                    Mobile picture(optional)
                                </Typography>
                                {image.link_mobile === undefined || image.link_mobile === "" ? 
                                    <Typography component="div" className={classes.mobileBodyFile}>
                                        <FileUpload handleUpdateImageDrop={this.handleUpdateImageDrop} 
                                            handleSaveRespoceImageDrop={this.handleSaveRespoceImageDrop}
                                            imageType='mobile' 
                                            imageId={image._id} />
                                    </Typography>
                                    :
                                    <Card key={index} className={classes.card}>
                                        <Typography component="div" className={classes.mobileImageBody}>
                                            <img src={image.link_mobile} title={ image.title } alt={ image.title } className={classes.mobileImage} />
                                        </Typography>
                                        <CardActions className={classes.actions} disabnot found image directoryleActionSpacing>
                                            <Typography>
                                                Remove mobile image
                                            </Typography>                                            
                                            <IconButton aria-label="Delete slader mobile image" onClick={() => {this.removeMobileImageDialog(image._id);}}>
                                                <IconDelete />
                                            </IconButton>                         
                                        </CardActions>
                                    </Card>
                                }
                                <CardActions className={classes.actions} disabnot found image directoryleActionSpacing>
                                    <IconButton aria-label="Delete slader image" onClick={() => {this.removeImageDialog(image._id);}}>
                                        <IconDelete />
                                    </IconButton>                         
                                    <CheckBoxGeneral
                                        data={{checked: isLoad ? image.enable : false, 
                                            event: this.handleCheckBoxChange,
                                            value: image._id,
                                            label: 'Enabled'}}
                                    />                               
                                </CardActions>
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </div>
        );
    }
}

AdminSlider.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => {
    return {
      user: store.userState.user,
      sliderImages: store.adminState.sliderImages,
      dialog: store.dialogState.dialog,
      alert: store.alertState.alert,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(AdminSlider));
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { connect } from 'react-redux';
import { setFavorites } from './../../actions';
import { getDataFromServer } from './../../DataProvider';
import config from './../../config';
import DeleteIcon from '@material-ui/icons/Delete';
import store from './../../store';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: '700px',
    backgroundColor: theme.palette.background.paper,
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  userPropertyMainContainer: {
    textAlign: 'center',
    marginTop: '20px',
  },
  userPropertyContainer: {
    margin: 'auto',
    backgroundColor: '#f8f8f8',
    padding: '20px 80px 80px 80px',
    border: 'solid 1px #ccc',
    minWidth: '720px',
    borderRadius: '3px',
  },
  listItemTextCenter: {
    textAlign: 'center',
  }
});

class UserProfileFavorites extends Component {
  componentDidMount(){
    this.getFavoritesFromServer();
  }

  getFavoritesFromServer = () => {
    getDataFromServer(config.params.server_path + '/get_user_favorite', { email: this.props.user.login })
    .then(response => {        
        if(response.data.success === true){
            store.dispatch(setFavorites(response.data.data));
        }
        console.log("UserProfileFavorites.componentDidMount => ", response.data.data);
    })
    .catch(err => {console.log("UserProfileFavorites.componentDidMount => error: ", err)});
  }

  deleteFavorite = (productID) => {    
    getDataFromServer(config.params.server_path + '/remove_favorite_product', {productId: productID, email: this.props.user.login})
    .then(response => {        
      if(response.data.success === true){
        this.getFavoritesFromServer();
      }
      console.log("MainProductContantTab.handleFavorite => ", response.data.data);
    })
    .catch(err => {console.log("MainProductContantTab.handleFavorite => error: ", err)});
  }

  render(){
    const { classes, favorites} = this.props;
    return (
        <div className={classes.userPropertyMainContainer}>
            <div className={classes.userPropertyContainer} >
                <div className={classes.root}>
                {favorites.length === 0 ?
                    <List component="nav">
                        <ListItem button>
                            <ListItemText className={classes.listItemTextCenter} primary='No Favorites!' />  
                        </ListItem>
                    </List>
                    :
                    <List component="nav">
                    {favorites.map((favorite, index) => 
                        <ListItem button>
                            <ListItemText primary={favorite.title} />
                            <ListItemSecondaryAction>
                                <IconButton aria-label="Delete" onClick={(node) => {this.deleteFavorite(favorite._id);}}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )}
                    </List>
                }
                </div>
            </div>
        </div>
      );
  }  
}

UserProfileFavorites.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => {
    return {
        user: store.userState.user,
        favorites: store.userProfileState.favorites,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(UserProfileFavorites));

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import Button from '@material-ui/core/Button';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Link } from 'react-router-dom';
import "./ModuleCart.css";
import SocialButtons from './SocialButtons';

const styles = theme => ({
  card: {
    maxWidth: 400,
    [theme.breakpoints.down('sm')]: {
      marginRight: 'auto',
      marginLeft: 'auto',
    },
  },
  media: {
    height: 50,
    paddingTop: '56.25%', // 16:9
    '&:hover': {
            
    },   
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  button: {
     background: '#000',
     width: '100%',
     display: 'none',
     '&:hover': {
      backgroundColor: fade('#efce49', 0.9),      
    },      
  },
  separator: {
    'border': 'solid 1px #efce49',
    'width': '100%',
    'margin-bottom': '10px',
    'margin-top': '10px',
  },
  productName:{
    'font-size' : '18px',
    'text-align': 'center',
     minHeight: 55,
     maxHeight: 55,
  }
});

class ModuleCart extends React.Component {
  
  render() {
    const { classes, product, handleFavorite, user, showShareButtons, showSocialDialog} = this.props;
    console.log("social dialog: ", showSocialDialog);
    const arrPrice = product.price.toString().split('.');
    const price1 = arrPrice[0];
    const price2 = arrPrice.length > 1 ? '.' + arrPrice[1] : '';
    let selected = false;
    if(user.login !== undefined && product.favorite_by !== undefined){
        let index = product.favorite_by.findIndex(f => f === user.login);
        if(index !== -1){
          selected = true;
        }    
    }

    return (
      <Card className={classes.card} id="divCard">
       <Link to={'/product/' + product._id} style={{textDecoration: 'none'}}>
        <div>
          <figure><img src={product.images[0].link} title={ product.images[0].title } alt={ product.images[0].title } /></figure>
        </div>
        <CardContent>
          <Typography component="p" className={classes.productName} >
            { product.title }
          </Typography>
          <Typography component="div" className={classes.separator} >
          </Typography>
          <Typography component="p" style={{'text-align': 'center', 'margin-top':'30px'}}>
            <span style={{'font-size': '16px'}}>$</span>
            <span style={{'font-size': '40px', 'font-weight': 'normal'}}>
              { price1 }
            </span>
            <span style={{'font-size': '16px'}}>{price2}</span>
          </Typography>
        </CardContent>
       </Link>
        <CardActions className={classes.actions} disabnot found image directoryleActionSpacing>
          action={
            <div>            
            <IconButton aria-label="Add to favorites" onClick={(node) => {handleFavorite(node, product._id, selected);}}>
              <FavoriteIcon style={{ 'color': selected ?  '#efce49' : '' }}/>
            </IconButton>
            <IconButton aria-label="Share" onClick={(node) => { showShareButtons(product._id);}}>
              <ShareIcon />              
            </IconButton>
            {showSocialDialog === true && product._id === this.props.showSocialProductId ?
               <SocialButtons productId={product._id} name={product.title} description={product.description}/>
               :''}            
            </div>
          }          
          <Button variant="contained" color="primary" className={classes.button}>
             Add to Card
            <ShoppingCart className={classes.rightIcon}>send</ShoppingCart>
          </Button>
        </CardActions>
      </Card>
    );
  }
}

ModuleCart.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ModuleCart);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import UserProfileUserProperty from './UserProfileUserProperty';
import UserProfileOrderHistory from './UserProfileOrderHistory';
import UserProfileFavorites from './UserProfileFavorites';


function TabContainer(props) {
    return (
      <Typography component="div" style={{ "padding-top" : "20px" }}>
        {props.children}
      </Typography>
    );
  }
  
  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
  };
  
  const styles = theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      width: "100%",
      marginTop: '20px',
    },
    tabs: {
        backgroundColor: theme.palette.common.white,
     },
     tabTitle: {
        color: '#000',
     }
  });
  
  class UserProfile extends Component {
    state = {
      value: 0,
    };
  
    handleChange = (event, value) => {
      this.setState({ value });
    };
  
    render() {
      const { classes } = this.props;
      const { value } = this.state;
  
      return (
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs value={value} onChange={this.handleChange} indicatorColor="primary" className={classes.tabs}>
              <Tab label="User details" className={classes.tabTitle}/>
              <Tab label="User favorites" className={classes.tabTitle}/>
              <Tab label="History Orders" className={classes.tabTitle}/>
            </Tabs>
          </AppBar>
          {value === 0 && 
          <TabContainer>
            <UserProfileUserProperty />
          </TabContainer>}
          {value === 1 && 
          <TabContainer>
            <UserProfileFavorites />
          </TabContainer>}
          {value === 2 && 
            <TabContainer>
              <UserProfileOrderHistory />
            </TabContainer>}
        </div>
      );
    }
  }

UserProfile.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (store) => {
      return {
          user: store.userState.user,
      }
  }

  export default connect(mapStateToProps)(withStyles(styles)(UserProfile));

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AdminData from './admin/AdminData';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    color: 'white',
    'margin-bottom' : '10px',
    'margin-left' : '20px',
    'margin-top' : '5px',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
    color: '#000',
  },
  select: {
      '&:before':{
          borderColor: '#000',
      },
      '&:after': {
            borderColor: '#000',
        },
        '&:focus':{
            color: '#000',
        }
  },
  selectMenu: {color: '#000'},
  
  icon: {
      fill: '#000'
  },
  spanMaterialLabel:{
    textTransform: 'capitalize',
  },
});

class SortMaterialType extends React.Component {
  
  handleChange = event => {
    this.props.handleSort(event.target.value);
  };

  render() {
    const { classes, sort } = this.props;

    return (
      <form className={classes.root} autoComplete="off">
        <FormControl className={classes.formControl}>
          <Select
            value={sort}
            onChange={this.handleChange}
            name="designStyle"
            displayEmpty
            className={classes.select}
            inputProps={{
                    classes: {
                        icon: classes.icon,
                        select: classes.select,
                        selectMenu: classes.selectMenu,
                    },
                }}
          >
          <MenuItem value='' disabled>Material Type</MenuItem>
          {AdminData.Materials.map((material, index)=>
            <MenuItem key={index} value={material}>
              <span className={classes.spanMaterialLabel}>{material}</span>
            </MenuItem>
          )}
          </Select>
        </FormControl>
      </form>
    );
  }
}

SortMaterialType.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SortMaterialType);
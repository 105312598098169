import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { adminNewProductUpdateArrays, adminNewProductDeleteArrays } from './../../actions';
import store from './../../store';
import Grid from '@material-ui/core/Grid';
import CheckBoxGeneral from './../CheckBoxGeneral';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
      gridItem:{
        display: 'inline-flex' 
      },
      multiSelectTitle:{
        marginTop: '20px',
        fontSize: '17px',
        fontWeight: 'bold',         
    }
  });

class AdminPorductNewArrays extends Component{    

    handleCheckBoxChangeSizes = name => e => {
        console.log("handleCheckBoxChange params: ", e.target.value, e.target.checked);
        if(e.target.checked){
            store.dispatch(adminNewProductUpdateArrays(this.props.data.field, e.target.value));
        }else{
            store.dispatch(adminNewProductDeleteArrays(this.props.data.field, e.target.value));
        }        
    }

    render(){
        let { classes, newProduct, data } = this.props;        

        let renderSizes = data.items.map((item, index)=> {
            let selected = false;
            if(newProduct[data.field] !== undefined && newProduct[data.field].length > 0){
                newProduct[data.field].map((chitem, i)=>{
                    if(chitem === item){
                        selected = true;
                    }
                });
            }
            return <Grid item sm={data.column} className={classes.gridItem}>                            
                        <CheckBoxGeneral
                            data={
                                {checked: selected, 
                                event: this.handleCheckBoxChangeSizes,
                                value: item,
                                label: item }}
                        />           
                    </Grid>
        })        
        return(
            <Grid container spacing={8}>
                <Grid item sm={12} className={classes.gridItem}>
                    <Typography component="span" className={classes.multiSelectTitle}>
                        {data.label}
                    </Typography>
                </Grid>
                { renderSizes }
            </Grid>
        );
    }
}

AdminPorductNewArrays.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (store) => {
      return {
          user: store.userState.user,
          newProduct: store.adminState.newProduct,          
      }
  }

  export default connect(mapStateToProps)(withStyles(styles)(AdminPorductNewArrays));
import React from 'react';
import ChangeHistory from '@material-ui/icons/ChangeHistory';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CheckBoxOutlined from '@material-ui/icons/CheckBoxOutlineBlank';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import './SortShape.css';

function SortShape(props){       
        let { sortShapeObj } = props;
        return(
          <Menu
                id='ulSortShapes'
                anchorEl={sortShapeObj.sortShapeEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: -40, horizontal: 10 }}
                open={sortShapeObj.sortShapeIsOpen}
                onClose={sortShapeObj.handleSortShapeMenuClose}
            >
                <MenuItem onClick={(node) => sortShapeObj.handleSortShapeClose(node, 'triangle')}>
                    <div className='div-sort-color1'>
                        <ChangeHistory />
                    </div>
                    <div className='sort-shape-text'>
                        Triangle
                    </div>
                </MenuItem>
                <MenuItem onClick={(node) => sortShapeObj.handleSortShapeClose(node, 'square')}>
                    <div className='div-sort-color1'>
                        <CheckBoxOutlined />
                    </div>
                    <div className='sort-shape-text'>
                        Square
                    </div>
                </MenuItem>
                <MenuItem onClick={(node) => sortShapeObj.handleSortShapeClose(node, 'circle')}>
                    <div className='div-sort-color1'>
                        <RadioButtonUnchecked />
                    </div>
                    <div className='sort-shape-text'>
                        Circle
                    </div>
                </MenuItem>               
            </Menu>
        )
    
}

export default SortShape;
const initialShoppingCartState = {
  shoppingCartItems: [],
  countShoppingCartItems: 0,
  payPage: false,
  shoppingCartUserDetails: null,
  orderDescriptionState: '',
  buyNowItem: {},
  buyNow: false,
  shippingTotal: 0,
  shippingCost: 0,
};

export default function shoppingCart(state = initialShoppingCartState, action){
    switch(action.type){
        case "ADD_SHOPPING_CART":
          return { ...state, shoppingCartItems: [...state.shoppingCartItems, action.shoppingCartItems]};
        
        case "UPDATE_SHOPPING_CART":
          let result;
          result = state.shoppingCartItems.map((item, index)=>{
              if(item.product._id === action.shoppingCartItems.productId){
                item.selectedOptions.quantity = action.shoppingCartItems.quantity;
                return item; 
              }
              return item;
          });            
          return {...state, result};
        
        case "REMOVE_SHOPPING_CART":
          console.log("REMOVE_SHOPPING_CART", action);
          return {...state, shoppingCartItems: state.shoppingCartItems.filter(item => item.id !== action.shoppingCartItem)};

        case "COUNT_SHOPPING_CART_ITEMS":
          return {...state, countShoppingCartItems: action.countShoppingCartItems};  
        
        case "COME_FROM_PAY_PAGE":
          return {...state, payPage: action.payPage};

        case "SET_SHOPPING_CART_USER_PROPERTY":
          return { ...state, shoppingCartUserDetails: action.shoppingCartUserDetails };

        case "UPDATE_SHOPPING_CART_USER_PROPERTY":        
          return { ...state, shoppingCartUserDetails: 
            {...state.shoppingCartUserDetails, [action.shoppingCartUserDetailsKey]: action.shoppingCartUserDetailsValue }};
        
        case "UPDATE_SHOPPING_CART_FIELD":
            let updateFieldresult;
            updateFieldresult = state.shoppingCartItems.map((item, index)=>{
                if(item.product._id === action.productId){
                  item[action.key] = action.value;
                  return item; 
                }
                return item;
            });
            return {...state, updateFieldresult};
        
        case "UPDATE_SHOPPING_CART_DESCRIPTION":
            return {...state, orderDescriptionState: action.orderDescriptionState};

        case "CLEAR_SHOPPING_CART":
            return {...state, shoppingCartItems: action.shoppingCartItems};

        case "SET_BUY_NOW_ITEM":
            return {...state, buyNowItem: action.buyNowItem, buyNow: action.buyNow};

        case "SET_SHOPPING_CART_SHIPPING_TOTAL":
            return {...state, shippingTotal: action.shippingTotal};

        case "SET_SHOPPING_CART_SHIPPING_COST":
            return {...state, shippingCost: action.shippingCost};

        default:
        return state;
    }
        
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { setAdminOrders, showDialog, showAlert } from './../../actions';
import { getDataFromServer } from './../../DataProvider';
import config from './../../config';
import store from './../../store';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from './../Dialog';
import SnackbarsCustom from './../SnackbarsCustom';
import adminData from './AdminData';
import SelectedListGeneral from './../SelectedListGeneral';

const styles = theme => ({
    root: {
      flexGrow: 1,
      padding: '20px 80px 80px 80px',      
    },
    tabs: {
        backgroundColor: theme.palette.common.white,
     },
     tabTitle: {
        color: '#000',
     },
     paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
      paperLink: {
        display: 'block',
        width: '100%',
        cursor: 'pointer',
      },
      rowGrid: {
        height: '40px',
        borderBottom: 'solid 1px #ccc',
        padding: '10px',
        '&:hover':{
            backgroundColor: '#ccc',
        }
      },
      button: {
        marginTop: '-10px',
      },
      selectColumn: {
        marginTop: '-20px',
      }
  });

  class AdminOrders extends Component{
    constructor(props){
        super(props);
        this.state = {removeOrderId: ''};
    }  
    
    componentDidMount(){
        this.getOrdersFromServer();
    }

    getOrdersFromServer = () => {
        getDataFromServer(config.params.server_path + '/orders')
        .then(response => {        
            if(response.data.success === true){
                store.dispatch(setAdminOrders(response.data.data));
            }
            console.log("AdminOrders.getOrdersFromServer => ", response.data.data);
        })
        .catch(err => {console.log("AdminOrders.getOrdersFromServer => error: ", err)});
    }

    removeOrderDialog = (orderId) => {
        let dialogData = { 
            title: 'Warning', 
            text: 'Do you want to remove order ?', 
            buttons: [{event: this.removeOrder, label: 'Remove order'}], 
            show: true 
        }
        this.state.removeOrderId = orderId;
        store.dispatch(showDialog(dialogData));
    }

    removeOrder = () => {
        let alert={};
        this.handlCloseDialog();
        getDataFromServer(config.params.server_path + '/remove_order', {orderId: this.state.removeOrderId} )
        .then(response => {        
            if(response.data.success === true){
                alert = {text: 'Remove order success.', variant: 'success', show: true, event: this.onAlertClose }
                this.getOrdersFromServer();
            }else{
                alert = {text: 'Faild remove order, error: ' + response.data.text, variant: 'error', show: true, event: this.onAlertClose }
            }
            console.log("AdminOrders.removeOrder => ", response.data.data);
            store.dispatch(showAlert(alert));
        })
        .catch(err => {
            console.log("AdminOrders.removeOrder => error: ", err);
            alert = {text: 'Faild remove order, error: ' + err, variant: 'error', show: true, event: this.onAlertClose };
            store.dispatch(showAlert(alert));
        });
    }

    handleUpdateOrderStatus = (event, orderId) => {
        let alert={};        
        getDataFromServer(config.params.server_path + '/orders_update_status', {orderId: orderId, updateObj: {status: event.target.value}})
        .then(response => {        
            if(response.data.success === true){
                alert = {text: 'Update order status success.', variant: 'success', show: true, event: this.onAlertClose }
            }else{
                alert = {text: 'Faild update order status, error: ' + response.data.text, variant: 'error', show: true, event: this.onAlertClose }
            }
            console.log("AdminOrders.handleUpdateOrderStatus => ", response.data.data);
            store.dispatch(showAlert(alert));
            this.getOrdersFromServer();
        })
        .catch(err => {
            console.log("AdminOrders.handleUpdateOrderStatus => error: ", err);
            alert = {text: 'Faild update  order status, error: ' + err, variant: 'error', show: true, event: this.onAlertClose };
            store.dispatch(showAlert(alert));
        });
    }    

    handlCloseDialog = (show) => {
        let dialogData = { title: '', text: '', buttons: [], show: false }
        store.dispatch(showDialog(dialogData));
    }

    onAlertClose = () => {
        let alert = {text: '', variant: 'success', show: false, event: ''};
        store.dispatch(showAlert(alert));
    }

    render(){
        let {classes, orders, adminOrderHendler, dialog, alert} = this.props;
        return(
            <div className={classes.root}>
               {alert.show ? 
                    <SnackbarsCustom data={alert} />
                    :''
                }
               <Dialog dialogData={ dialog } handlDialog={this.handlCloseDialog}/>
               {orders.map((order, index)=> 
                    <Grid key={index} container spacing={8} className={classes.rowGrid}>
                        <Grid item xs={5} sm={5} md={8}>
                            {order.title}
                        </Grid>
                        <Grid item xs={2} sm={3} md={2} className={classes.selectColumn}>
                            <SelectedListGeneral
                                selected={order.status}
                                name={order.status}
                                items={adminData.ShippingStatus}
                                handleChange={(node) => {this.handleUpdateOrderStatus(node, order._id);}}
                            />
                        </Grid>
                        <Grid item xs={2} sm={2} md={1}>
                            <Button className={classes.button} onClick={(node) => { adminOrderHendler('edit', order._id); } }>Edit</Button>
                        </Grid>
                        <Grid item xs={3} sm={2} md={1}>
                            <Button className={classes.button} onClick={(node) => { this.removeOrderDialog(order._id); } }>Delete</Button>
                        </Grid>
                    </Grid>
                )}
               
            </div>
        );
    }
  }

  AdminOrders.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (store) => {
      return {
          user: store.userState.user,
          orders: store.adminState.orders,
          dialog: store.dialogState.dialog,
          alert: store.alertState.alert,
      }
  }

  export default connect(mapStateToProps)(withStyles(styles)(AdminOrders));
import React, {Component} from 'react';
import {Grid} from '@material-ui/core';
import ShoppingCartItem from './ShoppingCartItem';
import store from './../store';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Button from '@material-ui/core/Button';
import Dialog from './Dialog';
import { removeShoppingCart, 
    setCountShoppingCartItems, 
    updateShoppingCartField, 
    updateShoppingCart,
    showDialog } from './../actions';

const styles = theme => ({
  root: {
      marginTop: '20px',
      padding: '20px',
  },
  divButton: {
      paddingLeft: '90%'
  },
  button: {
     background: '#000',
     color: '#fff',
     'margin-top': '10px',
     '&:hover': {
       backgroundColor: fade('#efce49', 0.9) 
     },
  },
  allTotal: {
      fontWeight: 'bold',
      fontSize: '20px',
  },
  divAllTotalText:{
      paddingLeft: '80%',
      marginTop: '30px',
  },
  divSubTotalText:{
      paddingLeft: '80%',
      marginTop: '10px',
  },
  subTotalText: {
      fontSize: '20px',
  },
});

class ShoppingCart extends Component {
    constructor(props){
        super(props);
        this.state = {orderQuantity: ''};
      }

    removeShoppingCartItem = (itemId) => {        
        store.dispatch(removeShoppingCart(itemId));
        let count = parseInt(this.props.countShoppingCartItems) - 1; 
        store.dispatch(setCountShoppingCartItems(count));
    }

    handleBuy = () => {
        let isValid = true, {shoppingCartItems} = this.props;
        for (var i = 0; i < shoppingCartItems.length; i++){
            if(isNaN(shoppingCartItems[i].selectedOptions.quantity) === true || shoppingCartItems[i].selectedOptions.quantity === 0){
                isValid = false;
                break; 
            }
        }
        if(isValid === true){
          this.props.history.push('/shopping-address'); 
        }else{
            let dialogData = { title: 'Warning', text: 'Not valid quantity!', buttons: [], show: true };
            store.dispatch(showDialog(dialogData));
        }          
    }

    onDescriprionChange = (e, productId) => {
        store.dispatch(updateShoppingCartField(productId, e.target.id, e.target.value));  
    }

    onShoppingCartQuantityChange = (e, prodId) => {
        store.dispatch(updateShoppingCart({productId: prodId, quantity: parseInt(e.target.value)}));
        this.setState({orderQuantity: e.target.value});       
    }

    handlDialog = (show) => {
        let dialogData = { title: '', text: '', buttons: [], show: false }
        store.dispatch(showDialog(dialogData));
    }

    render(){
        const {shoppingCartItems, classes, dialog} = this.props;
        console.log("ShoppingCart render: ", this.props);
        console.log("ShoppingCart render: ", shoppingCartItems);

        let allTotal = 0, subTotal=0, shipping=0, items=shoppingCartItems.length;
        shoppingCartItems.map((item, index) => {
            let ship = item.product.shippingCost !== undefined ? parseFloat(item.product.shippingCost) : 0;            
            subTotal += parseFloat(item.product.price) * parseInt(item.selectedOptions.quantity);            
            shipping += parseInt(ship) * parseInt(item.selectedOptions.quantity);           
        });
        allTotal += subTotal + shipping;

        return(
            <Grid container spacing={16} className={classes.root}>
                <Dialog dialogData={ dialog } handlDialog={this.handlDialog}/>
                {shoppingCartItems.map((m,i) => 
                    <Grid item xs={12} sm={12}  md={12} lg={12}>
                        <ShoppingCartItem 
                            shoppingCatItem={m} 
                            removeItem={this.removeShoppingCartItem} 
                            onDescriprionChange={this.onDescriprionChange}
                            onShoppingCartQuantityChange={this.onShoppingCartQuantityChange}
                        />
                    </Grid>
                )}
                <Grid className={ classes.divSubTotalText }>
                    <span className={classes.subTotalText}>{'Subtotal('+ items +' items): $'}{isNaN(subTotal) ? 0 : subTotal }</span>
                </Grid>
                 <Grid className={ classes.divSubTotalText }>
                    <span className={classes.subTotalText}>Shipping cost: ${isNaN(shipping) ? 0 : shipping }</span>
                </Grid>
                <Grid className={ classes.divAllTotalText }>
                    <span id='spanAllTotal' className={classes.allTotal}>All Total: ${isNaN(allTotal) ? 0 : allTotal }</span>
                </Grid>
                <Grid className={ classes.divButton }>
                    <Button variant="contained" className={classes.button} onClick={this.handleBuy}>
                        Buy All
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = (store) => {
  return {
      shoppingCartItems: store.shoppingCartState.shoppingCartItems,
      countShoppingCartItems: store.shoppingCartState.countShoppingCartItems,
      shippingTotal: store.shoppingCartState.shippingTotal,
      dialog: store.dialogState.dialog,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(ShoppingCart));



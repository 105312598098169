import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import  './LoginUser.css';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import SyncIcon from '@material-ui/icons/Sync';
import $ from 'jquery';
import axios from 'axios';
import config from '../config';
import * as actionFun from './../actions';
import store from './../store';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SnackbarsCustom from './SnackbarsCustom';

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },

  button: {
    margin: theme.spacing.unit,
    "min-width": "140px",
    "font-size": "14px"
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  rightIcon: {
    marginLeft: theme.spacing.unit,
  },

  iconSmall: {
    fontSize: 20,
  },
});

 class LoginUser extends Component{
   constructor(props){
       super(props);       
       
       this.isValidEmail = this.isValidEmail.bind(this);
       this.onForgetPassword = this.onForgetPassword.bind(this);
       this.onLoginClick = this.onLoginClick.bind(this);
       this.onResetPassword = this.onResetPassword.bind(this);
       store.dispatch(actionFun.setUserForgetPassword(false));
    }


   isValidEmail(email) {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        return pattern.test(email);
    }

    onForgetPassword(){
         $("#txtLoginUserLogin").hide();
         $("#txtLoginUserPassword").hide();
         store.dispatch(actionFun.setUserForgetPassword(true));
    }

    onLoginClick(e){
        e.preventDefault();
        const log = $("#txtLoginUserLogin").val();
        const pwd = $("#txtLoginUserPassword").val();
        let validData = true;
        
        if(log.trim() === ''){
            $("#txtLoginUserLogin").addClass("validate-error");
            $("#txtLoginUserLogin").on('focus', () => {
               $("#txtLoginUserLogin").removeClass("validate-error");
            });
            validData = false;
        }
        if(pwd.trim() === ''){
            $("#txtLoginUserPassword").addClass("validate-error");
            $("#txtLoginUserPassword").on('focus', () => {
               $("#txtLoginUserPassword").removeClass("validate-error");
            });
            validData = false;
        }

        if(validData){
            const localUser = {login: log, password: pwd};
            let alert={};
            axios.post(config.params.server_path + "/login_user", { user: localUser })
            .then(res => {  
                console.log("onLoginClick -> login user result: ", res.data !== undefined ? res.data : "no data");
                if(res.data.success === true){
                    console.log("onLoginClick -> login user : ", res.data !== undefined ? res.data.data : "no data");                    
                    store.dispatch(actionFun.setUser(res.data.data));
                    this.props.history.push('/products');
                }else {
                    alert = {text: res.data.text, variant: 'error', show: true, event: this.onAlertClose };
                }
                store.dispatch(actionFun.showAlert(alert));
            })
            .catch(err => { 
                console.log("onLoginClick -> err login user: ", err);
                alert = {text: 'Faild login user, error: ' + err, variant: 'error', show: true, event: this.onAlertClose };
                store.dispatch(actionFun.showAlert(alert));
            });
        }
    }

    onResetPassword(){
        if($("#txtLoginUserEmail").val().trim() !== '' && this.isValidEmail($("#txtLoginUserEmail").val())){
            let alert={};
           axios.post(config.params.server_path + "/reset_password", { email: $("#txtLoginUserEmail").val() })
            .then(res => {
                console.log("onLoginClick -> : ", "response",  res);
                console.log("onLoginClick -> : ", "login user result", res.data !== undefined ? res.data : "no data");
                if(res.data.success === true){
                    alert = {text: 'Reset user password success.', variant: 'success', show: true, event: this.onAlertClose }
                }else{
                    alert = {text: 'Faild reset user password, error: ' + res.data.text, variant: 'error', show: true, event: this.onAlertClose }
                }
                store.dispatch(actionFun.showAlert(alert));                
            })
            .catch(err => { 
                console.log("onLoginClick -> err login user: ", err);
                alert = {text: 'Faild reset user password, error: ' + err, variant: 'error', show: true, event: this.onAlertClose };
                store.dispatch(actionFun.showAlert(alert)); 
            });
       }
    }

    onAlertClose = () => {
        let alert = {text: '', variant: 'success', show: false, event: ''};
        store.dispatch(actionFun.showAlert(alert));
    }

   render(){

    const { classes, forgetPassword, alert } = this.props;
    const inputTextStyle = {"height": "0px;"};
    return(
        <div id="divLoginUserContainer" className="container">
            {alert.show ? 
                <SnackbarsCustom data={alert} />
                :''
            }
            <div className="login-container">
            <div>
            <TextField
                className={classNames( classes.margin, classes.textfield )}
                style={inputTextStyle}
                id="txtLoginUserLogin"
                placeholder="User Name"
            />
            </div>
            <div>
            <TextField
                className={classes.margin}
                id="txtLoginUserPassword"
                type="password"
                placeholder="Password"
            />
            </div>
            {forgetPassword === true ?
            <div>
            <TextField
                className={classes.margin}
                id="txtLoginUserEmail"
                type="email"
                placeholder="Email"
            />
            </div>
            :
            ''
            }
            <div id="divLoginUserSave" style={ {marginTop: "20px"} }>
            {forgetPassword === false ?
                <Button variant="contained" color="primary" className={classes.button} onClick={this.onForgetPassword}>
                    Forget password
                    <SyncIcon className={classes.leftIcon} />
                </Button>
                :
                <Button variant="contained" color="primary" className={classes.button} onClick={this.onResetPassword}>
                    Reset password
                    <SyncIcon className={classes.leftIcon} />
                </Button>
            }
            {forgetPassword === false ?
                <Button variant="contained" color="primary" className={classes.button} onClick={this.onLoginClick}>
                    Login
                    <SaveIcon className={classes.rightIcon} />
                </Button>
                :''
            }
            </div>
            </div>
        </div>
    );
   }
}


LoginUser.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => {
    return {
        forgetPassword: store.userState.forgetPassword,
        alert: store.alertState.alert,
    }
}

export default withRouter(connect(mapStateToProps)(withStyles(styles)(LoginUser)));
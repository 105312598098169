import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    color: 'white',
    'margin-bottom' : '10px',
    'margin-left' : '20px',
    'margin-top' : '5px',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
    color: '#000',
  },
  select: {
      '&:before':{
          borderColor: '#000',
      },
      '&:after': {
            borderColor: '#000',
        },
        '&:focus':{
            color: '#000',
        }
  },
  selectMenu: {color: '#000'},
  
  icon: {
      fill: '#000'
  }
});

class SelectedList extends React.Component {
    
  render() {
    const { classes, selected, name, placeholder, items, handleChange, styles, rootStyle } = this.props;
    console.log("SelectedList", "input style: ", styles);
    let customFormControlClass = styles !== undefined ? styles : '';
    let customRootStyle = rootStyle !== undefined ? rootStyle : '';
    return (
      <form className={classNames(classes.root, customRootStyle)} autoComplete="off">
        <FormControl className={classNames(classes.formControl, customFormControlClass)}>
          <Select
            value={selected}
            onChange={handleChange}
            name={name}
            displayEmpty
            className={classes.select}
            inputProps={{
                    classes: {
                        icon: classes.icon,
                        select: classes.select,
                        selectMenu: classes.selectMenu,
                    },
                }}
          >
            {placeholder !== undefined ? <MenuItem value='' disabled>{placeholder}</MenuItem> : ''}         
            {items.map((item, index) => 
                <MenuItem value={item.value}>{item.label}</MenuItem>
            )}
          </Select>
        </FormControl>
      </form>
    );
  }
}

SelectedList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectedList);
const initialDialogtState = {
    historyOrders: [],
    favorites: [],    
  };
  
  export default function userProfile(state = initialDialogtState, action){
      switch(action.type){
          case "SET_HISTORY_ORDERS":
            return {...state, historyOrders: action.historyOrders};
          case "SET_FAVORITES":
            return {...state, favorites: action.favorites};
          default:
            return state;
      }    
      
  }
import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import Email from '@material-ui/icons/Email';
import * as actionFun from './../actions';
import store from './../store';
import { connect } from 'react-redux';
import config from './../config';
import { getDataFromServer } from './../DataProvider';
import { Link } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import { withRouter } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    color: '#000',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#000',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    color: theme.palette.common.black,
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  signupButton: {
    textDecoration: 'none',
    color: '#000',
  },
  linkMenuMobileView:{
    color: 'black',
    textDecoration: 'none',
    marginRight: '20px',
    '&:hover':{
      
    },
    '&:active':{
      color: 'black',
    }
  },
  userLoginName:{
    color: theme.palette.common.black,
    marginTop: 14,
  }
});

class NavBar extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleShoppingBasket = () => {
    alert("open Shopping Basket");
  }

  handleSearch = (e) => {
    let sortType = this.props.sort === 'lth' ? '1' : '-1';
    store.dispatch(actionFun.searchProduct(e.target.value));    
    getDataFromServer(config.params.server_path + '/search_products', {searchText: e.target.value, sortType: sortType})
      .then(response => {        
        if(response.data.success === true){
            store.dispatch(
              actionFun.getProducts(response.data.data)
          );
        }
        console.log("MainProductContantTab.componentDidMount => ", response.data.data);
        
      })
      .catch(err => {console.log("MainProductContantTab.handleSort => error: ", err)});
  }

  HandleLogout = () => {
    store.dispatch(actionFun.setUser({}));
    this.props.history.push('/products');
  }

  render() {
    const { anchorEl, mobileMoreAnchorEl } = this.state;
    const { classes, user } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const userProfileLink = user.identified !== undefined && user.identified ? '/userprofile' : '/userproperty';

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
      { user._id === undefined ?
        <div> 
          <MenuItem>
            <Link className={classes.signupButton} to='/login'>Login</Link>
          </MenuItem>        
          <MenuItem>
            <Link className={classes.signupButton} to='/signup'>Sign up</Link>
          </MenuItem>
        </div>
        :
        <div>
          <MenuItem>
            <Link className={classes.signupButton} to={userProfileLink}>Profile</Link>
          </MenuItem>
          {user.permission === 'admin'? 
             <MenuItem>
              <Link className={classes.signupButton} to='/admin'>Admin</Link>
            </MenuItem>
            :''
          }        
          <MenuItem onClick={(node) => { this.HandleLogout();}}>
            Logout
          </MenuItem>
        </div>
      }
      </Menu>
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}
      >
        <MenuItem>
        <Link to='/' className={classes.linkMenuMobileView}>
            <IconButton color="#000">
                <HomeIcon />
            </IconButton>
            Home
          </Link>
        </MenuItem>
        <MenuItem onClick={this.handleProfileMenuOpen}>
          <IconButton color="inherit">
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
        <MenuItem>
          <Link to='/shopping-cart' className={classes.linkMenuMobileView}>
            <IconButton color="#000">
              <Badge badgeContent={this.props.countShoppingCartItems} color="secondary">              
                <ShoppingCart />
              </Badge>              
            </IconButton>
              Cart
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to='/contact-us' className={classes.linkMenuMobileView}>
            <IconButton color="#000">
                <Email />
            </IconButton>
              Contact us
          </Link>
        </MenuItem>
      </Menu>
    );

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar style={{"backgroundColor" : "#fff"}}>
            <Typography className={classes.title} variant="title" color="#000" noWrap>
              <Link to='/products' style={{textDecoration: 'none'}}>
                <img src='image/logo.jpg' alt='House Of Mezuzah' title='House Of Mezuzah'></img>
              </Link>
            </Typography>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <Input
                placeholder="Search…"
                disableUnderline
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                onChange={this.handleSearch}
                value={this.props.searchText}
              />
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
            <Link to='/contact-us' style={{textDecoration: 'none'}}>
                <IconButton color="#000">
                    <Email />
                </IconButton>
              </Link>
              <Link to='/shopping-cart' style={{textDecoration: 'none'}}>
                <IconButton color="#000">
                  <Badge badgeContent={this.props.countShoppingCartItems} color="secondary">              
                    <ShoppingCart />
                  </Badge>
                </IconButton>
              </Link>
              <IconButton
                aria-owns={isMenuOpen ? 'material-appbar' : null}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="#000"
              >
                <AccountCircle />
              </IconButton>
              <span className={classes.userLoginName}>{user._id !== undefined ? user.name : ''}</span>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="#000">
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMenu}
        {renderMobileMenu}
      </div>
    );
  }
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => {
  return {
      searchText: store.productsState.searchText,
      sort: store.productsState.sort,
      countShoppingCartItems: store.shoppingCartState.countShoppingCartItems,
      user: store.userState.user,
  }
}

export default withRouter(connect(mapStateToProps)(withStyles(styles)(NavBar)));
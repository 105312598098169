import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { adminNewProduct, adminNewProductAddImage, adminNewProductRemoveImage, showDialog, showAlert } from './../../actions';
import { getDataFromServer } from './../../DataProvider';
import config from './../../config';
import store from './../../store';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import CheckBoxGeneral from './../CheckBoxGeneral';
import AdminPorductNewArrays from './AdminPorductNewArrays';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Dialog from './../Dialog';
import adminData from './AdminData';
import SnackbarsCustom from './../SnackbarsCustom';
import FileUpload from './../FileUpload';

const styles = theme => ({
    root: {
      flexGrow: 1,
      padding: '20px 80px 80px 80px',
      maxWidth: '70%',
      marginLeft: 'auto',
      marginRight: 'auto',      
    },
    tabs: {
        backgroundColor: theme.palette.common.white,
     },
    tabTitle: {
        color: '#000',
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperLink: {
        display: 'block',
        width: '100%',
        cursor: 'pointer',
    },
    textfield: {    
        width: '80%',
        fontSize: '15px',
    },
    margin: {
        margin: theme.spacing.unit,
    },
    labelSpan:{ 
        marginTop: '13px',
        fontSize: '17px',
        width: '150px',
    },
    gridItem:{
        display: 'inline-flex' 
    },
    separatorGrid:{
        marginTop: 20,
        marginBottom: 10,
        textAlign: 'center',
        borderBottom: 'solid 1px #ccc',
        fontWeight: 'bold',
    },
    multiSelectTitle:{
          marginTop: '20px',
          fontSize: '17px',
          fontWeight: 'bold',         
    },
    imageGrid:{
          marginTop: 20,
    },
    buttonSave: {
        margin: theme.spacing.unit,
        minWidth: "140px",
        fontSize: "14px",
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    backToListButton: {
        fontWeight: 'bold',
        textDecoration: 'underline',
    },
    productImage:{
        maxWidth: 320,
        maxHeight: 200,
        width: 'auto',
        height: 'auto',
    }
  });

class AdminPorductNew extends Component{
    constructor(){
        super();
        this.state = {imageLink: '', imageTitle: ''};
    }

    onTextChange = (e) => {
        store.dispatch(adminNewProduct(e.target.id, e.target.value));
    }

    handleSelectChange = (event) => {
        //store.dispatch(updateAdminEditOrder('status', event.target.value));
    }

    handleCheckBoxChange = name => e => {
        store.dispatch(adminNewProduct(e.target.value, e.target.checked));
    }

    handleAddImage = () =>{
        let image = {title: this.state.imageTitle, link: this.state.imageLink};        
        store.dispatch(adminNewProductAddImage(image));
    }

    handleRemoveImage = (e, image) =>{
        store.dispatch(adminNewProductRemoveImage(image));
    }

    handleAddImageDrop = (name) =>{
        let imageName = this.state.imageTitle === '' ? name : this.state.imageTitle;
        let link = config.params.server_path + '/image/products/' + name;      
        let image = {title: imageName, link: link};
        store.dispatch(adminNewProductAddImage(image));
    }

    handleSaveRespoceImageDrop = (err, result) =>{
        let alert={};
        if(err){
            alert = {text: 'Faild save product image, error: ' + err, variant: 'error', show: true, event: this.onAlertClose }
            store.dispatch(showAlert(alert));
        }else if(result.data.success !== true){
            alert = {text: 'Failed save product image, error: ' + result.data.text, variant: 'error', show: true, event: this.onAlertClose }
            store.dispatch(showAlert(alert));
        }
    }
    
    handleImageTextChange = (e) =>{        
        this.setState({[e.target.id]: e.target.value});
    }

    handleSaveProduct = () => {
        let arrNotValid = [], {newProduct} = this.props; ;

        if(newProduct.sku === undefined || newProduct.sku === ''){
            arrNotValid.push('Sku');
        }
        if(newProduct.title === undefined || newProduct.title === ''){
            arrNotValid.push('Title');
        }
        if(newProduct.description === undefined || newProduct.description === ''){
            arrNotValid.push('Description');
        }
        if(newProduct.price === undefined || newProduct.price === ''){
            arrNotValid.push('Price');
        }
        if(newProduct.installments === undefined || newProduct.installments === ''){
            arrNotValid.push('Installments');
        }
        if(newProduct.currencyId === undefined || newProduct.currencyId === ''){
            arrNotValid.push('CurrencyId');
        }
        if(newProduct.currencyFormat === undefined || newProduct.currencyFormat === ''){
            arrNotValid.push('CurrencyFormat');
        }
        if(newProduct.sizes.length === 0){
            arrNotValid.push('Sizes');
        }
        if(newProduct.styles.length === 0){
            arrNotValid.push('Styles');
        }
        if(newProduct.shapes.length === 0){
            arrNotValid.push('Shapes');
        }
        if(newProduct.colors.length === 0){
            arrNotValid.push('Colors');
        }
        if(newProduct.materials.length === 0){
            arrNotValid.push('Materials');
        }
        if(newProduct.images === undefined || newProduct.images.length === 0){
            arrNotValid.push('Images');
        }
        if(newProduct.isFreeShipping === false){
            if(newProduct.shippingCost === undefined || newProduct.shippingCost === ''){
                arrNotValid.push('Shipping Cost');
            }
        }      
        if(arrNotValid.length > 0){
            let dialogData = { 
                title: 'Warning', 
                text: 'Required fields are not filled: ' + arrNotValid.join(', '), 
                buttons: [], 
                show: true 
            }            
            store.dispatch(showDialog(dialogData));
        }else{
            let alert={};
            getDataFromServer(config.params.server_path + '/new_product', {product: this.props.newProduct} )
            .then(response => {        
                if(response.data.success === true){
                    alert = {text: 'Create product success.', variant: 'success', show: true, event: this.onAlertClose }
                }else{                
                    alert = {text: 'Faild update product, error: ' + response.data.text, variant: 'error', show: true, event: this.onAlertClose }
                }
                console.log("AdminPorductNew.handleSaveProduct => ", response.data.data);
                store.dispatch(showAlert(alert));
            })
            .catch(err => {
                console.log("AdminPorductNew.handleSaveProduct => error: ", err);
                alert = {text: 'Faild update product, error: ' + err, variant: 'error', show: true, event: this.onAlertClose }
                store.dispatch(showAlert(alert));
            });
        }
    }

    handlCloseDialog = (show) => {
        let dialogData = { title: '', text: '', buttons: [], show: false }
        store.dispatch(showDialog(dialogData));
    }

    onAlertClose = () => {
        let alert = {text: '', variant: 'success', show: false, event: ''};
        store.dispatch(showAlert(alert));
    }
    
    render(){
        let {classes, adminProductHendler, newProduct, dialog, alert} = this.props;
        console.log("new product obj: ", newProduct);        

        let sizesData = {column: 1, field: 'sizes', items: adminData.Sizes, label: 'Sizes:'};
        let stylesData = {column: 2, field: 'styles', items: adminData.Styles, label: 'Styles:'};
        let shapesData = {column: 2, field: 'shapes', items: adminData.Shapes, label: 'Shapes:'};
        let materialsData = {column: 2, field: 'materials', items: adminData.Materials, label: 'Materials:'};
        let colorsData = {column: 1, field: 'colors', items: adminData.Colors, label: 'Colors:'};
        
        return(
            <div>
                {alert.show ? 
                    <SnackbarsCustom data={alert} />
                    :''
                }
                <div className={classes.root}>
                    <Dialog dialogData={ dialog } handlDialog={this.handlCloseDialog}/>
                    <Grid container spacing={8}>
                        <Button className={classNames(classes.button, classes.backToListButton) } onClick={(node) => { adminProductHendler('list', null); } }>
                            <KeyboardArrowLeft />
                            Back to list
                        </Button>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item sm={12} className={classes.separatorGrid}>
                            <span className={classes.labelSpan}>New product</span>
                        </Grid>                        
                        <Grid item sm={12} className={classes.gridItem}>
                            <CheckBoxGeneral
                                data={
                                    {checked: newProduct.enabled !== undefined ? newProduct.enabled : '', 
                                     event: this.handleCheckBoxChange,
                                     value: 'enabled',
                                     label: 'Enabled'}}
                            />           
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>SKU:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="sku"
                                placeholder="SKU"
                                value={newProduct.sku !== undefined ? newProduct.sku : ''}                                
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Title:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="title"
                                placeholder="Title"
                                value={newProduct.title !== undefined ? newProduct.title : ''}
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>                      
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>CurrencyId:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="currencyId"
                                placeholder="CurrencyId"                                
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>

                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Currency Format:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="currencyFormat"
                                placeholder="Currency Format"                                
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>

                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Installments:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="installments"
                                placeholder="Installments"                                
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Price:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="price"
                                placeholder="Price"                                
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <CheckBoxGeneral
                                data={
                                    {checked: newProduct.isFreeShipping !== undefined ? newProduct.isFreeShipping : '', 
                                     event: this.handleCheckBoxChange,
                                     value: 'isFreeShipping',
                                     label: 'Free Shipping'}}
                            />                                       
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                        <span className={classes.labelSpan}>Shipping Cost:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="shippingCost"
                                placeholder="Shipping Cost"                                
                                onChange={(node) => this.onTextChange(node)}
                            />                                           
                        </Grid>
                        <Grid item sm={12} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Description:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="description"
                                label="Description"                                                                
                                onChange={(node) => this.onTextChange(node)}
                                multiline
                                rowsMax="6"
                                margin="normal"              
                                variant="outlined"
                            />           
                        </Grid>                          
                    </Grid>
                    
                    <Grid container spacing={8}>
                        <AdminPorductNewArrays data={sizesData} />      
                    </Grid>
                    <Grid container spacing={8}>
                        <AdminPorductNewArrays data={stylesData} />      
                    </Grid>
                    <Grid container spacing={8}>
                        <AdminPorductNewArrays data={shapesData} />      
                    </Grid>
                    <Grid container spacing={8}>
                        <AdminPorductNewArrays data={materialsData} />      
                    </Grid>
                    <Grid container spacing={8}>
                        <AdminPorductNewArrays data={colorsData} />      
                    </Grid>

                    <Grid container spacing={8} className={classes.imageGrid}>
                        <Grid item sm={12} className={classes.gridItem}>
                                <span className={classes.labelSpan}>Image name:</span>
                                <TextField
                                    className={classNames( classes.margin, classes.textfield )}
                                    id="imageTitle"
                                    placeholder="Image name"
                                    value= {this.state.imageTitle}
                                    onChange={this.handleImageTextChange}                                                               
                                />           
                        </Grid>
                        <Grid item sm={10} className={classes.gridItem}>
                                <span className={classes.labelSpan}>Image link:</span>
                                <TextField
                                    className={classNames( classes.margin, classes.textfield )}
                                    id="imageLink"
                                    placeholder="Image link"
                                    value= {this.state.imageLink}
                                    onChange={this.handleImageTextChange}
                                />           
                        </Grid>
                        <Grid item sm={2} className={classes.gridItem}>
                                <Button className={classes.button} onClick={(node) => { this.handleAddImage(); } }>
                                    Add Image
                                </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item sm={12} className={classes.gridItem}>
                            <FileUpload handleAddImageDrop={this.handleAddImageDrop} 
                                        handleSaveRespoceImageDrop={this.handleSaveRespoceImageDrop} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        {newProduct.images.map((image, index) =>
                             <Grid key={index} item sm={4} className={classes.gridItem}>
                                <Card className={classes.card}>
                                    <div>
                                        <img className={classes.productImage} alt={image.title} src={image.link} />
                                    </div>
                                    <CardActions className={classes.actions} disableActionSpacing>
                                    <IconButton aria-label="Delete Image" 
                                        onClick={(node) => {
                                            let obj = {title: image.title, link: image.link}; 
                                            this.handleRemoveImage(node, obj);
                                            }}>
                                        <Delete />
                                    </IconButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )}
                    </Grid>
                    <Grid container spacing={8} className={classes.imageGrid}>
                        <Grid item sm={12} className={classes.gridItem}>
                            <Button variant="contained" color="primary" className={classes.buttonSave} onClick={this.handleSaveProduct}>
                                Save
                                <SaveIcon className={classes.rightIcon} />
                            </Button>
                        </Grid>
                    </Grid> 
                </div>
            </div>
        );
    }
}

AdminPorductNew.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (store) => {
      return {
          user: store.userState.user,
          newProduct: store.adminState.newProduct,
          dialog: store.dialogState.dialog,
          alert: store.alertState.alert,         
      }
  }

  export default connect(mapStateToProps)(withStyles(styles)(AdminPorductNew));
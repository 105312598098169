import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import {Grid} from '@material-ui/core';
import SelectedList from './SelectedList';
import ViewHeadline from '@material-ui/icons/ViewHeadline';
import ViewModule from '@material-ui/icons/ViewModule';
import ModuleCart from './ModuleCart';
import HeadLineCart from './HeadlineCart';
import { getDataFromServer } from './../DataProvider';
import * as actionFun from './../actions';
import store from './../store';
import { connect } from 'react-redux';
import ColorLens from '@material-ui/icons/ColorLens';
import IconButton from '@material-ui/core/IconButton';
import config from './../config';
import './MainProductContantTab.css';
import SortShape from './SortShape';
import Category from '@material-ui/icons/Category';
import SortShapeDesign from './SortShapeDesign';
import SortMaterialType from './SortMaterialType';
import SortColor from './SortColor';
import ChangeHistory from '@material-ui/icons/ChangeHistory';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CheckBoxOutlined from '@material-ui/icons/CheckBoxOutlineBlank';
import Button from '@material-ui/core/Button';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ "padding-top" : "20px" }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  tabs: {
     backgroundColor: theme.palette.common.white,
  },
  tab: {
    width: "50px",
    'min-width': '50px',
    color: theme.palette.common.black,
    'font-size': '40px !important',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },  
  },
  iconButton: {
    'height': '48px',
    'margin': '7px',
  },
  clearDiv: {
    paddingTop: '15px',
  },
  clearA:{
    color: 'black',
    textDecoration: 'none',
    marginRight: '20px',
    '&:hover':{
      textDecoration: 'underline',
    },
    '&:active':{
      color: 'black',
    }
  },
  gridContainer:{
    paddingLeft: '10px',
    paddingRight: '10px',
    [theme.breakpoints.up('1750')]: {
      paddingLeft: '2.5%',
    },
  }
});

class MainProductContantTab extends React.Component {
  
  handleChange = (event, value) => {
    store.dispatch(actionFun.setActiveTab(value));
  };

  componentWillReceiveProps(nextPorps){
   const { sortPrice ,sortStyle, sortMaterial, sortColor, sortShape, searchText } = this.props;
   if(sortPrice !== nextPorps.sortPrice
      || sortStyle !== nextPorps.sortStyle 
      || sortMaterial !== nextPorps.sortMaterial
      || sortColor !== nextPorps.sortColor
      || sortShape !== nextPorps.sortShape
      || searchText !== nextPorps.searchText)
      {
        console.log("componentWillReceiveProps", 'run sort: ', true);

        let nextSortPrice = nextPorps.sortPrice === 'lth' ? '1' : '-1';
        
        let sortObj = {price: nextSortPrice, 
          styles: nextPorps.sortStyle, 
          material: nextPorps.sortMaterial, 
          color: nextPorps.sortColor, 
          shape: nextPorps.sortShape, 
          text: nextPorps.searchText};

        this.handleSort(sortObj);
      }
    console.log("componentWillReceiveProps", 'next props: ', nextPorps, 'this props: ', this.props);    
  }

  handleSort = (sortObj) => {
    getDataFromServer(config.params.server_path + '/sort_products', sortObj)
      .then(response => {        
        if(response.data.success === true){
            store.dispatch(actionFun.getProducts(response.data.data));
        }
        console.log("MainProductContantTab.componentDidMount => ", response.data.data);
      })
      .catch(err => {console.log("MainProductContantTab.handleSort => error: ", err)});
  }

  handlePriceSort = (value) => {
    store.dispatch(actionFun.sortProductPrice(value));
  }

  handleStyleSort = (value) => {
    store.dispatch(actionFun.sortProductStyle(value));
  }

  handleMaterialSort = (value) => {
    store.dispatch(actionFun.sortProductMaterial(value));
  }

  componentDidMount(){
    this.getProjectSortData();
  }

  getProjectSortData = () =>{
    let sortPrice = this.props.sortPrice === 'lth' ? '1' : '-1';
        
    let sortObj = { 
      price: sortPrice, 
      styles: this.props.sortStyle, 
      material: this.props.sortMaterial, 
      color: this.props.sortColor, 
      shape: this.props.sortShape, 
      text: this.props.searchText 
    };

    this.handleSort(sortObj);
  }

  handleSortColorMenuClose = (e) => {
    e.preventDefault();
    store.dispatch(actionFun.colorProductSelectOpen(false, null));
  };

  handleSortColorOpen = event => {
     event.preventDefault();
     let isOpen = this.props.sortColorIsOpen ? false : true;
     let elm = this.props.sortColorIsOpen ? null : event.currentTarget;
     store.dispatch(actionFun.colorProductSelectOpen(isOpen, elm));
  };

  handleSortColorClose = (e, color) => {
      e.preventDefault();
      store.dispatch(actionFun.colorProductSelectOpen(false, null));
      store.dispatch(actionFun.sortProductColor(color));
  }

  handleSortShapeMenuClose = (e) => {
      e.preventDefault();
      store.dispatch(actionFun.shapeProductSelectOpen(false, null));
  }

  handleSortShapeOpen = event => {
    event.preventDefault();    
    let isOpen = this.props.sortShapeIsOpen ? false : true;
    let elm = this.props.sortShapeIsOpen ? null : event.currentTarget;
    store.dispatch(actionFun.shapeProductSelectOpen(isOpen, elm));
  };

  handleSortShapeClose = (e, shape) => {
      e.preventDefault();
      store.dispatch(actionFun.shapeProductSelectOpen(false, null));
      store.dispatch(actionFun.sortProductShape(shape));
  }

  handleClear = () => {
    store.dispatch(actionFun.sortProductShape(''));
    store.dispatch(actionFun.sortProductStyle(''));
    store.dispatch(actionFun.sortProductColor(''));
    store.dispatch(actionFun.sortProductMaterial(''));
    store.dispatch(actionFun.sortProductPrice(''));
  }

  handleFavorite = (e, productID, selected) => {
    if(this.props.user.login === undefined){
        this.props.history.push('/signup');
    }
    let path = selected ? '/remove_favorite_product' : '/set_favorite_product';
    getDataFromServer(config.params.server_path + path, {productId: productID, email: this.props.user.login})
    .then(response => {        
      if(response.data.success === true){
        this.getProjectSortData();
      }
      console.log("MainProductContantTab.handleFavorite => ", response.data.data);
    })
    .catch(err => {console.log("MainProductContantTab.handleFavorite => error: ", err)});
  }

  showShareButtons = (productId) => {
    let show = this.props.showSocialButtons === true ? false : true;
    store.dispatch(actionFun.showSocialButtons(show, productId));
  }

  render() {
    const {classes} = this.props;
    const  valuesObj = this.props;
    
    const sortShapeObj = {
      sortShapeEl: this.props.sortShapeEl,       
      handleSortShapeClose: this.handleSortShapeClose,
      handleSortShapeMenuClose: this.handleSortShapeMenuClose,
      sortShapeIsOpen: this.props.sortShapeIsOpen,
    };

    const sortColorObj = {
      anchorEl: this.props.anchorEl,       
      handleSortColorClose: this.handleSortColorClose,
      handleSortColorMenuClose: this.handleSortColorMenuClose,
      sortColorIsOpen: this.props.sortColorIsOpen,
    }; 

    let ShapeSelectedIcon = Category;
    switch(valuesObj.sortShape){
      case 'triangle':
        ShapeSelectedIcon = ChangeHistory;
      break;
      case 'square':
        ShapeSelectedIcon = CheckBoxOutlined;
      break;
      case 'circle':
        ShapeSelectedIcon = RadioButtonUnchecked;
      break;
      default:
        ShapeSelectedIcon = Category;
      break;
    }    

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs 
            value={this.props.activeTab} 
            onChange={this.handleChange} 
            className={classes.tabs} 
            indicatorColor="primary"
            scrollable
            scrollButtons="on"
            id='sortTabs'
            >
            <Tab icon={<ViewModule />} className={classes.tab} />
            <Tab icon={<ViewHeadline /> } className={classes.tab} />
            <SelectedList sort={ this.props.sortPrice} handleSort={this.handlePriceSort}/>
            <SortShapeDesign sort={this.props.sortStyle} handleSort={this.handleStyleSort}/>
            <SortMaterialType sort={this.props.sortMaterial} handleSort={this.handleMaterialSort} />
             <IconButton color="#000" className={classes.iconButton} onClick={this.handleSortColorOpen} title='colors'>
                <ColorLens style={{ 'color': this.props.sortColor !== '' ?  this.props.sortColor : '' }}/>
                <SortColor sortColorObj={sortColorObj}/>
             </IconButton>
             <IconButton color="#000" className={classes.iconButton} onClick={this.handleSortShapeOpen} title='shapes'>
                <ShapeSelectedIcon />
                <SortShape sortShapeObj={sortShapeObj}/>
             </IconButton>
             <Grid className={classes.clearDiv}>
                <Button className={classes.button} onClick={(node) => { node.preventDefault(); this.handleClear(); } }>Clear</Button>
             </Grid>
          </Tabs>
        </AppBar>
        {this.props.activeTab === 0 && 
            <TabContainer>
                <Grid container spacing={8} className={classes.gridContainer}>
                  {this.props.products.map((m,i) =>
                    m.enable ? 
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <ModuleCart product={m} 
                                  handleFavorite={this.handleFavorite}
                                  showShareButtons={this.showShareButtons}
                                  showSocialDialog={this.props.showSocialButtons}
                                  showSocialProductId={this.props.showSocialProductId}
                                  user={valuesObj.user} />
                    </Grid>
                    :''
                  )}
                </Grid>
            </TabContainer>}
        {this.props.activeTab === 1 && 
          <TabContainer>
             <Grid container spacing={16}>
             {this.props.products.map((m,i) =>
                m.enable ? 
                <Grid item xs={12} sm={12}  md={12} lg={12}>
                    <HeadLineCart product={m}/>
                </Grid>
                :''
             )}
             </Grid>
          </TabContainer>}
      </div>
    );
  }
}

MainProductContantTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => {
  return {
      products: store.productsState.products,
      activeTab: store.productsState.activeTab,
      sortPrice: store.productsState.sortPrice,
      sortStyle: store.productsState.sortStyle,
      sortMaterial: store.productsState.sortMaterial,
      searchText: store.productsState.searchText,
      anchorEl: store.productsState.anchorEl,
      sortShapeEl: store.productsState.sortShapeEl,
      sortColorIsOpen: store.productsState.sortColorIsOpen,
      sortShapeIsOpen: store.productsState.sortShapeIsOpen,
      sortColor: store.productsState.sortColor,
      sortShape: store.productsState.sortShape,
      user: store.userState.user,
      showSocialButtons: store.productsState.showSocialButtons,
      showSocialProductId: store.productsState.showSocialProductId,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(MainProductContantTab));
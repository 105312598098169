import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { setAdminUsers, showDialog, showAlert } from './../../actions';
import { getDataFromServer } from './../../DataProvider';
import config from './../../config';
import store from './../../store';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from './../Dialog';
import SnackbarsCustom from './../SnackbarsCustom';

const styles = theme => ({
    root: {
      flexGrow: 1,
      padding: '20px 80px 80px 80px',      
    },
    tabs: {
        backgroundColor: theme.palette.common.white,
     },
     tabTitle: {
        color: '#000',
     },
     paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
      paperLink: {
        display: 'block',
        width: '100%',
        cursor: 'pointer',
      },
      rowGrid: {
        height: '40px',
        borderBottom: 'solid 1px #ccc',
        padding: '10px',
        '&:hover':{
            backgroundColor: '#ccc',
        }
      },
      button: {
        marginTop: '-10px',
      },
  });

class AdminUsers extends Component{
    constructor(props){
        super(props);
        this.state = {removeUserId: ''};
    }  
    
    componentDidMount(){
        this.getUsersFromServer();
    }

    getUsersFromServer = () =>{
        getDataFromServer(config.params.server_path + '/list_user')
        .then(response => {        
            if(response.data.success === true){
                store.dispatch(setAdminUsers(response.data.data));
            }
            console.log("AdminUsers.getUsersFromServer => ", response.data.data);
        })
        .catch(err => {console.log("AdminUsers.getUsersFromServer => error: ", err)});
    }

    removeUserDialog = (userId) => {
        let dialogData = { 
            title: 'Warning', 
            text: 'Do you want to remove user ?', 
            buttons: [{event: this.removeUser, label: 'Remove user'}], 
            show: true 
        }
        this.state.removeUserId = userId;
        store.dispatch(showDialog(dialogData));
    }

    removeUser = () => {
        let alert={};
        this.handlCloseDialog();
        getDataFromServer(config.params.server_path + '/update_user', {updateObj: {_id: this.state.removeUserId, enable: false}})
        .then(response => {        
            if(response.data.success === true){
                alert = {text: 'Disable user success.', variant: 'success', show: true, event: this.onAlertClose }
                this.getUsersFromServer();
            }else{
                alert = {text: 'Faild disable user, error: ' + response.data.text, variant: 'error', show: true, event: this.onAlertClose }
            }
            console.log("AdminUsers.removeUser => ", response.data.data);
            store.dispatch(showAlert(alert));
        })
        .catch(err => {
            console.log("AdminUsers.removeUser => error: ", err);
            alert = {text: 'Faild disable user, error: ' + err, variant: 'error', show: true, event: this.onAlertClose };
            store.dispatch(showAlert(alert));
        });
    }

    handlCloseDialog = (show) => {
        let dialogData = { title: '', text: '', buttons: [], show: false }
        store.dispatch(showDialog(dialogData));
    }

    onAlertClose = () => {
        let alert = {text: '', variant: 'success', show: false, event: ''};
        store.dispatch(showAlert(alert));
    }

    render(){
        let {classes, users, adminUserHendler, dialog, alert} = this.props;
        console.log("user properties: ", users);
        return(
            <div className={classes.root}>
               {alert.show ? 
                    <SnackbarsCustom data={alert} />
                    :''
                }
               <Dialog dialogData={ dialog } handlDialog={this.handlCloseDialog}/>
               {users.map((us, index)=> 
                    <Grid key={index} container spacing={8} className={classes.rowGrid}>
                        <Grid item xs={4} sm={7} md={9}>
                            {us.name}
                        </Grid>
                        <Grid item xs={2} sm={1} md={1}>
                            <span>{ us.enable ? 'Enabled' : 'Disabled' }</span>
                        </Grid>
                        <Grid item xs={3} sm={2} md={1}>
                            <Button className={classes.button} onClick={(node) => { adminUserHendler('edit', us._id); } }>Edit</Button>
                        </Grid>
                        <Grid item xs={3} sm={2} md={1}>
                            <Button className={classes.button} onClick={(node) => { this.removeUserDialog(us._id); } }>Delete</Button>
                        </Grid>
                    </Grid>
                )}
               
            </div>
        );
    }    
  }

  AdminUsers.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (store) => {
      return {
        user: store.userState.user,
        users: store.adminState.users,
        dialog: store.dialogState.dialog,
        alert: store.alertState.alert,
      }
  }

  export default connect(mapStateToProps)(withStyles(styles)(AdminUsers));
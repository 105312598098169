import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import AdminOrders from './AdminOrders';
import AdminProducts from './AdminProducts';
import AdminUsers from './AdminUsers';
import AdminOrderEdit from './AdminOrderEdit';
import AdminProductEdit from './AdminProductEdit';
import AdminPorductNew from './AdminPorductNew';
import AdminUserEdit from './AdminUserEdit';
import AdminSlider from './AdminSlider';

function TabContainer(props) {
    return (
      <Typography component="div" style={{ paddingTop : "20px" }}>
        {props.children}
      </Typography>
    );
  }
  
  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
  };
  
  const styles = theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      width: "100%",
      marginTop: '20px',
    },
    tabs: {
        backgroundColor: theme.palette.common.white,
     },
     tabTitle: {
        color: '#000',
     }
  });
  
  class AdminMain extends Component {
    state = {
      value: 0,
      ordersCurrentComponent: '', 
      productCurrentComponent: '',
      usersCurrentComponent: '',
      editOrderId: '',
      editProductId: '',
      editUserId: ''
    };
  
    handleChange = (event, value) => {
      this.setState({ value });
    };

    adminOrderHendler = (type, orderId) => {
        if(type === 'list'){
            this.setState({ordersCurrentComponent: 'list'});
        }
        if(type === 'edit'){
            this.setState({ordersCurrentComponent: 'edit', editOrderId: orderId});
        }
    }

    adminProductHendler = (type, productId) => {
        if(type === 'list'){
            this.setState({productCurrentComponent: 'list'});
        }
        if(type === 'edit'){
            this.setState({productCurrentComponent: 'edit', editProductId: productId});
        }
        if(type === 'new'){
            this.setState({productCurrentComponent: 'new'});
        }
    }

    adminUserHendler = (type, userId) => {
        if(type === 'list'){
            this.setState({usersCurrentComponent: 'list'});
        }
        if(type === 'edit'){
            this.setState({usersCurrentComponent: 'edit', editUserId: userId});
        }
    }
  
    render() {
      const { classes } = this.props;
      const { value } = this.state;

        let OrderComponent = AdminOrders;
        switch(this.state.ordersCurrentComponent){
            case 'list':
                OrderComponent = AdminOrders;
            break;
            case 'edit':
                OrderComponent = AdminOrderEdit;
            break;
            default:
                OrderComponent = AdminOrders;
            break;
        }

        let ProductComponent = AdminProducts;
        switch(this.state.productCurrentComponent){
            case 'list':
                ProductComponent = AdminProducts;
            break;
            case 'edit':
                ProductComponent = AdminProductEdit;
            break;
            case 'new':
                ProductComponent = AdminPorductNew;
            break;
            default:
                ProductComponent = AdminProducts;
            break;
        }

        let UserComponent = AdminUsers;
        switch(this.state.usersCurrentComponent){
            case 'list':
                UserComponent = AdminUsers;
            break;
            case 'edit':
                UserComponent = AdminUserEdit;
            break;
            default:
                UserComponent = AdminUsers;
            break;
        }
  
      return (
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs value={value} onChange={this.handleChange} indicatorColor="primary" className={classes.tabs}>
              <Tab label="Orders" className={classes.tabTitle}/>
              <Tab label="Products" className={classes.tabTitle}/>
              <Tab label="Users" className={classes.tabTitle}/>
              <Tab label="Slider" className={classes.tabTitle}/>
            </Tabs>
          </AppBar>
          {value === 0 && 
          <TabContainer>
            <OrderComponent adminOrderHendler={this.adminOrderHendler} editOrderId={this.state.editOrderId}/>
          </TabContainer>}
          {value === 1 && 
          <TabContainer>
            <ProductComponent adminProductHendler={this.adminProductHendler} editProductId={this.state.editProductId}/>
          </TabContainer>}
          {value === 2 && 
            <TabContainer>
              <UserComponent adminUserHendler={this.adminUserHendler} editUserId={this.state.editUserId}/>
            </TabContainer>}
          {value === 3 && 
            <TabContainer>
              <AdminSlider />
            </TabContainer>}
        </div>
      );
    }
  }

  AdminMain.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (store) => {
      return {
          user: store.userState.user,          
      }
  }

  export default connect(mapStateToProps)(withStyles(styles)(AdminMain));

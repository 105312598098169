import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import store from './../store';
import {setShoppingCartShippingCost} from './../actions';
import { connect } from 'react-redux';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  
});

class ProductEditInfoTab extends React.Component {
  state = {
    value: 0,
    checkBox: '',
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleCheckBoxChange = event => {
    this.setState({ checkBox: event.target.value });
    let cost = event.target.value.split('_')[1];
    store.dispatch(setShoppingCartShippingCost(cost));
  }

  render() {
    const { classes, productEdit, shippingCost } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.root}>
        <AppBar position="static" color='default'>
          <Tabs value={value} 
            onChange={this.handleChange} 
            indicatorColor="primary" 
            textColor="primary"
            scrollable
            scrollButtons="on">
            <Tab label="Product Details" />
            <Tab label="Feedbacks" />
            <Tab label="Shipping & Payment" />
          </Tabs>
        </AppBar>
        {value === 0 && 
            <TabContainer>
                { productEdit.description }
            </TabContainer>}
        {value === 1 && <TabContainer>Feedbacks</TabContainer>}
        {value === 2 && 
          <TabContainer>            
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Shipping</FormLabel>
              <RadioGroup
                aria-label="Shipping"
                name="shipping"
                className={classes.group}
                value={'cost_' + shippingCost}
                onChange={this.handleCheckBoxChange}
              >
                <FormControlLabel value="cost_0" control={<Radio />} label="Free shipping 10-14" />
                <FormControlLabel value="cost_35" control={<Radio />} label="Express shipping 5-7 35$" />
              </RadioGroup>
            </FormControl>
          </TabContainer>}
      </div>
    );
  }
}

ProductEditInfoTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => {
  return {
    shippingCost: store.shoppingCartState.shippingCost,
  }}

export default connect(mapStateToProps)(withStyles(styles)(ProductEditInfoTab));
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import  './ResetPassword.css';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import SyncIcon from '@material-ui/icons/Sync';
import $ from 'jquery';
import axios from 'axios';
import config from '../config';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import store from './../store';
import * as actionFun from './../actions';
import SnackbarsCustom from './SnackbarsCustom';

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },

  button: {
    margin: theme.spacing.unit,
    "min-width": "140px",
    "font-size": "14px"
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  rightIcon: {
    marginLeft: theme.spacing.unit,
  },

  iconSmall: {
    fontSize: 20,
  },
});

 class ResetPassword extends Component{
   constructor(props){
       super(props);      
       this.onSavePassword = this.onSavePassword.bind(this);       
   }     

    onSavePassword(e){
        e.preventDefault();
        const password = $("#txtResetPasswordNew").val();
        const confirmPassword = $("#txtResetPasswordConfirmPassword").val();
        let validData = true;
        
        if(password.trim() === ''){
            $("#txtResetPasswordNew").addClass("validate-error");
            $("#txtResetPasswordNew").on('focus', () => {
               $("#txtResetPasswordNew").removeClass("validate-error");
            });
            validData = false;
        }
        if(confirmPassword.trim() === '' || password !== confirmPassword){
            $("#txtResetPasswordConfirmPassword").addClass("validate-error");
            $("#txtResetPasswordConfirmPassword").on('focus', () => {
               $("#txtResetPasswordConfirmPassword").removeClass("validate-error");
            });
            validData = false;
        }

        if(validData){
            axios.post(config.params.server_path + "/update_password", { verificationCode: this.props.verificationCode, password: password })
            .then(res => {  
                console.log("onSavePassword -> update user password result: ", res.data != undefined ? res.data : "no data");
                if(res.data.success === true ){                    
                    alert = {text: 'Update password success.', variant: 'success', show: true, event: this.onAlertClose };
                }else{                    
                    alert = {text: 'Update password fail, error: '+ res.data.text +'.', variant: 'error', show: true, event: this.onAlertClose };
                }
                store.dispatch(actionFun.showAlert(alert));
                this.cleanResetPasswordData();
            })
            .catch(err => { 
                console.log("onLoginClick -> err login user: ", err);
                this.cleanResetPasswordData();
                alert = {text: 'Update password fail, error: '+ err +'.', variant: 'error', show: true, event: this.onAlertClose };
                store.dispatch(actionFun.showAlert(alert));
            });            
        }
    }
    
    cleanResetPasswordData = () => {
        store.dispatch(actionFun.setUserVerificationCode(''));
        store.dispatch(actionFun.setUserResetPassword(false));
    }

    onAlertClose = () => {
        let alert = {text: '', variant: 'success', show: false, event: ''};
        store.dispatch(actionFun.showAlert(alert));
    }

   render(){
    const { classes, alert } = this.props;
    const inputTextStyle = {"height": "0px;"};
    return(
        <div id="divResetPasswordContainer" className="container">
            {alert.show ? 
              <SnackbarsCustom data={alert} />
              :''
            }
            <div className="reset-password-container">
            <div>
            <TextField
                className={classNames( classes.margin, classes.textfield )}
                style={inputTextStyle}
                type="password"
                id="txtResetPasswordNew"
                placeholder="Password"
            />
            </div>
            <div>
            <TextField
                className={classes.margin}
                id="txtResetPasswordConfirmPassword"
                type="password"
                placeholder="Confirm Password"
            />
            </div>
           
            <div id="divResetPasswordSave" style={ {"margin-top": "20px"} }>
                <Button variant="contained" color="primary" className={classes.button} onClick={this.onSavePassword}>
                    Update
                    <SyncIcon className={classes.leftIcon} />
                </Button>
            </div>
            </div>
        </div>
    );
   }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => {
    return {
        user: store.userState.user,        
        verificationCode: store.userState.verificationCode,
        alert: store.alertState.alert,      
    }}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(ResetPassword)));
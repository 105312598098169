import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


const styles = {
  root: {
    color: green[600],
    '&$checked': {
      color: green[500],
    },
  },
  checked: {},
};

class CheckBoxGeneral extends React.Component {
     
    // handleChange = name => event => {
    //   this.setState({ [name]: event.target.checked });
    // };
  
    render() {
      const { data } = this.props;
      console.log("CheckBoxGeneral set check box value: ", data);
  
      return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={data.checked}
                    onChange={data.event(data.value)}
                    value={data.value}
                    color="primary"                
                />
            }
            label={data.label}
        />
      );
    }
}

CheckBoxGeneral.propTypes = {
    classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(CheckBoxGeneral);


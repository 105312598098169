import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MainProductContantTab from './MainProductContantTab';

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: '20px',
  },  
});

function ProductContant(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <MainProductContantTab  {...props}/>
    </div>
  );
}

ProductContant.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductContant);
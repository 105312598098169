import React, { Component } from 'react';
import {Grid, IconButton, Button} from '@material-ui/core';
import './ProductEdit.css';
import config from './../config';
import { getDataFromServer } from './../DataProvider';
import store from './../store';
import * as actionFun from './../actions';
import { connect } from 'react-redux';
import Square from '@material-ui/icons/CheckBoxOutlineBlank';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import Triangle from '@material-ui/icons/ChangeHistory';
import Circle from '@material-ui/icons/RadioButtonUnchecked';
import ProductEditInfoTab from './ProductEditInfoTab';
import Dialog from './Dialog';
import UUID from 'node-uuid';
import Slider from './TopSlider';
import ProductEditSelectSize from './ProductEditSelectSize';
import SelectedListGeneral from './SelectedListGeneral';
import TextField from '@material-ui/core/TextField';
import SnackbarsCustom from './SnackbarsCustom';
import $ from 'jquery';

class ProductEdit extends Component{
    componentDidMount(){               
       console.log("ProductEdit -> componentDidMount", this.props.productID);
       getDataFromServer(config.params.server_path + '/get_product_by_id', {productID: this.props.productID})
      .then(response => {        
        if(response.data.success === true){
            store.dispatch(
              actionFun.getProductById(response.data.data)
          );
          let {fromMainPage, sortColor, sortMaterial, sortShape, sortStyle} = this.props;
          if(fromMainPage){
            let color = sortColor === '' ? response.data.data.colors[0] : sortColor;
            let shape = sortShape === '' ? response.data.data.shapes[0] : sortShape;
            let style = sortStyle === '' ? response.data.data.styles[0] : sortStyle;
            let material = sortMaterial === '' ? response.data.data.materials[0] : sortMaterial;
            let size = response.data.data.sizes[0];
            store.dispatch(actionFun.setProductEditColor(color));
            store.dispatch(actionFun.setProductEditShape(shape));        
            store.dispatch(actionFun.setProductEditStyle(style));
            store.dispatch(actionFun.setProductEditMaterial(material));
            store.dispatch(actionFun.setProductEditSize(size));
          }
        }
        console.log("ProductEdit.componentDidMount => ", response.data.data);
        
      })
      .catch(err => {console.log("ProductEdit.componentDidMount => error: ", err);});
    }

    handleColors = (e, color) => {
        store.dispatch(actionFun.setProductEditColor(color));
    }

    handleShapes = (e, shape) => {
        store.dispatch(actionFun.setProductEditShape(shape));
    }

    handleStyles = (e, style) => {
        store.dispatch(actionFun.setProductEditStyle(style));
    }

    handleMaterials = (e) => {        
        store.dispatch(actionFun.setProductEditMaterial(e.target.value));
    }

    handleSize = (e, size) => {
        store.dispatch(actionFun.setProductEditSize(size));
    }

    handleNotes = (e) => {
        store.dispatch(actionFun.setProductEditNote(e.target.value));
    }

    handleShippingCost = (price) => {
        //store.dispatch(actionFun.setProductEditNote(e.target.value));
    }

    handleQuantity = (e, operator) => {
        //alert(e.target.value);
        switch(operator){
            case 'increment':
                this.qInput.value = parseInt(this.qInput.value === ''? 0 : this.qInput.value) + 1;
            break;
            case 'decrement':
                if(this.qInput.value !== '' && this.qInput.value !== '1' && this.qInput.value !== '0'){
                    this.qInput.value = parseInt(this.qInput.value === ''? 0 : this.qInput.value) - 1;
                }
            break;
        }
    }

    addToShoppingCart = (e) => {       
        if(this.validateRequiredFields()){
            let prodId = this.props.editProduct._id, itemExists = false;
            console.log("ProductEdit -> addToShoppingCart: ", this.props);
            this.props.shoppingCartItems.map((item, i) => {
                let selOpt = item.selectedOptions;
                if(item.product._id === prodId 
                   && selOpt.color === this.props.editProductColor
                   && selOpt.shape === this.props.editProductShape
                   && selOpt.size === this.props.editProductSize
                   && selOpt.style === this.props.editProductStyle
                   && selOpt.material === this.props.editProductMaterial){
                   let sum = parseInt(selOpt.quantity) + parseInt(this.qInput.value);
                   store.dispatch(actionFun.updateShoppingCart({productId: prodId, quantity: sum}));
                   itemExists = true;
                }
            });
            if(!itemExists){
                const selected = {
                    color: this.props.editProductColor, 
                    shape: this.props.editProductShape, 
                    quantity: this.qInput.value,
                    size: this.props.editProductSize,
                    style: this.props.editProductStyle,
                    material: this.props.editProductMaterial
                }
                const shoppingCartItem = {
                    id: UUID.v1(),
                    product: this.props.editProduct, 
                    selectedOptions: selected,
                    userId: this.props.user._id !== undefined ? this.props.user._id : 'anonymous',
                    status: 'new',
                    shippingAddress: '',
                    orderDescription: this.props.editProductNotes,
                }
                shoppingCartItem.product.shippingCost = this.props.shippingCost;
                store.dispatch(actionFun.addShoppingCart(shoppingCartItem));
                let count = parseInt(this.props.countShoppingCartItems) + 1;                 
                store.dispatch(actionFun.setCountShoppingCartItems(count));                
                
                if(parseInt(this.props.shippingCost) !== 0){
                    let shipping = parseInt(this.props.shippingTotal) + parseInt(this.props.shippingCost);
                    store.dispatch(actionFun.setShoppingCartShippingTotal(shipping));
                }

                $("html, body").animate({ scrollTop: 0 }, 400);                
                let alert = {text: 'Product added to cart.', variant: 'success', show: true, event: this.onAlertClose };
                store.dispatch(actionFun.showAlert(alert));
            }
        }
    }

    validateRequiredFields = () => {
        let arrNotValid = [];
        if(this.props.editProductColor === ''){
            arrNotValid.push('Color');
        }
        if(this.props.editProductShape === ''){
            arrNotValid.push('Shape');
        }
        if(this.qInput.value === '' || isNaN(parseInt(this.qInput.value))){
            arrNotValid.push('Quantity');
        }
        if(this.props.editProductMaterial === ''){
            arrNotValid.push('Material');
        }
        if(this.props.editProductSize === ''){
            arrNotValid.push('Size');
        }
        if(this.props.editProductStyle === ''){
            arrNotValid.push('Style');
        }
        if(arrNotValid.length > 0){
            let dialogData = { title: 'Warning', text: 'Not selected required options: ' + arrNotValid.join() + ' !', buttons: [], show: true };
            store.dispatch(actionFun.showDialog(dialogData));
            return false;
        }else{
            return true;
        }
    }

    buyNow = (e) => {
        if(this.validateRequiredFields()){
            const selected = {
                color: this.props.editProductColor, 
                shape: this.props.editProductShape, 
                quantity: this.qInput.value,
                size: this.props.editProductSize,
                style: this.props.editProductStyle,
                material: this.props.editProductMaterial
            }
            const buyNowItem = {
                id: UUID.v1(),
                product: this.props.editProduct, 
                selectedOptions: selected,
                userId: this.props.user._id !== undefined ? this.props.user._id : 'anonymous',
                status: 'new',
                shippingAddress: '',
                orderDescription: this.props.editProductNotes,
            }
            buyNowItem.product.shippingCost = this.props.shippingCost;
            store.dispatch(actionFun.setBuyNowItem(buyNowItem, true));       
            this.props.history.push('/shopping-address'); 
        }       
    }

    handlDialog = (show) => {
        let dialogData = { title: '', text: '', buttons: [], show: false }
        store.dispatch(actionFun.showDialog(dialogData));
    }

    componentWillUnmount(){
        store.dispatch(actionFun.setProductEditColor(''));
        store.dispatch(actionFun.setProductEditShape(''));
        store.dispatch(actionFun.setProductEditSize(''));
        store.dispatch(actionFun.setProductEditStyle(''));
        store.dispatch(actionFun.setProductEditMaterial(''));
        store.dispatch(actionFun.setProductEditNote(''));
        store.dispatch(actionFun.setShoppingCartShippingCost('0'));
    }

    onAlertClose = () => {
        let alert = {text: '', variant: 'success', show: false, event: ''};
        store.dispatch(actionFun.showAlert(alert));
    }
    
    render(){        
        const { editProduct, editProductColor, editProductShape, dialog, editProductStyle } = this.props;
        const arrColor = editProduct !== null ? editProduct.colors : [];
        const isLoaded = editProduct !== null ? true : false;
        console.log("ProductEdit.render -> ", "isLoaded: ", isLoaded, editProductColor, editProductShape);
        let arrPrice, price1, price2, shapes, arrMaterials = [];        
        if(isLoaded){
            arrMaterials = editProduct.materials.map((material, i) => {
                return {value: material, label: material};
            })

            arrPrice = editProduct.price.toString().split('.');
            price1 = arrPrice[0];
            price2 = arrPrice.length > 1 ? '.' + arrPrice[1] : ''; 
            
            shapes = editProduct.shapes.map((shape, i) =>           
                <Grid className={editProductShape === shape ? 'product-edit-options-shape-selected' : ''}>
                    <IconButton onClick={(node) => {this.handleShapes(node, shape)}} color="#000">        
                        {(() => {
                            switch(shape){
                                case 'triangle':
                                    return <Triangle />
                                case 'square':
                                    return <Square />
                                case 'circle':
                                    return <Circle />
                                default:
                                    return <Square />
                            }
                        })()}
                    </IconButton>
                </Grid>
            );
        }
        
        return(
            <div className='div-main-container' >
                <Dialog dialogData={ dialog } handlDialog={this.handlDialog}/>
                {alert.show ? 
                    <SnackbarsCustom data={alert} />
                    :''
                }
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"editProductColor
                    justify="center"
                    style={{ minHeight: '100vh' }}
                >
                    {isLoaded ?
                        <Grid item xs={12} className='grid-picture-description'>
                            <Grid container style={{'textAlign': 'center'}}>
                                <Grid item xs={12} sm={6} className='product-edit-right-border'>
                                    <Slider images={editProduct.images}/>
                                </Grid>
                                <Grid item xs={12} sm={6} className='product-edit-title'>
                                <Grid item >{ editProduct.title }</Grid>
                                <div className='product-edit-bottom-border product-edit-separator'>&nbsp;</div>
                                <Grid item className='product-edit-description'></Grid>
                                <Grid style={{display : "flex"}}>
                                        <Grid item className='product-edit-description' style={{width: "25%", marginTop: '20px'}}>Price:</Grid>
                                        <Grid item className='product-edit-description'>
                                            <span style={{'font-size': '16px'}}>$</span>
                                            <span style={{'font-size': '40px', 'font-weight': 'normal'}}>{ price1 }</span>
                                            <span style={{'font-size': '16px'}}>{price2}</span>
                                        </Grid>
                                </Grid>
                                <Grid container className='product-edit-options'>
                                        <Grid item className='product-edit-options-colors-container'>
                                            <Grid className='product-edit-options-colors-title'>Colors:</Grid>
                                            <Grid item className='product-edit-options-colors'>
                                                {arrColor.map((v, i) => 
                                                    <Grid className={editProductColor === v ? 'product-edit-options-colors-div product-edit-options-colors-selected' :  'product-edit-options-colors-div'}>
                                                        {v === 'other' || v === 'no color' ? 
                                                          <Grid className='product-edit-options-colors-label' onClick={(node) => this.handleColors(node, v)}>{v}</Grid>
                                                        :
                                                          <Grid style={{'backgroundColor': v}} onClick={(node) => this.handleColors(node, v)}></Grid>
                                                        }
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid item className='product-edit-options-shape-container'>
                                            <Grid className='product-edit-options-shape-title'>Shape:</Grid>
                                            <Grid item className='product-edit-options-shape'>
                                                {shapes}                                            
                                            </Grid>
                                        </Grid>
                                        <Grid item className='product-edit-options-style-container'>
                                            <Grid className='product-edit-options-style-title'>Style:</Grid>
                                            <Grid item className='product-edit-options-style'>
                                                {editProduct.styles.map((style, i) => 
                                                    <Grid className={editProductStyle === style ? 'product-edit-options-style-div product-edit-options-style-selected' : 'product-edit-options-style-div' }>
                                                        <Grid onClick={(node) => { this.handleStyles(node, style); }}>{style}</Grid>
                                                    </Grid> 
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid item className='product-edit-options-material-container'>
                                            <Grid className='product-edit-options-material-title'>Material:</Grid>
                                            <Grid item className='product-edit-options-material'>
                                                <SelectedListGeneral
                                                    selected={this.props.editProductMaterial}
                                                    name={''}
                                                    items={arrMaterials}
                                                    handleChange={this.handleMaterials}
                                                    placeholder='Material'
                                                />                                                
                                            </Grid>
                                        </Grid>                                        
                                        <Grid item className='product-edit-options-style-container'>
                                            <Grid className='product-edit-options-size-title'>Size:</Grid>
                                            <Grid item className='product-edit-options-style'>
                                                <ProductEditSelectSize 
                                                    sizeItems={editProduct.sizes} 
                                                    selectedSize={this.props.editProductSize} 
                                                    handleSelectSize={this.handleSize} />
                                            </Grid>
                                        </Grid>
                                        <Grid item className='product-edit-options-quantity-container'>
                                            <Grid item className='product-edit-options-quantity'>
                                                <Grid className='product-edit-options-quantity-title'>Quantity:</Grid>
                                                <Grid className='' style={{textAlign: 'left'}}>
                                                    <span>
                                                        <IconButton onClick={(btn) => this.handleQuantity(btn, 'increment')} color="#000"> 
                                                            <Add />
                                                        </IconButton>
                                                    </span>
                                                    <span>
                                                        <input className='product-edit-options-quantity-input'
                                                            value='1' 
                                                            ref={(inputQuantity) => this.qInput = inputQuantity} 
                                                            onChange={(btn) => this.handleQuantity(btn, '')}>
                                                        </input>
                                                    </span>
                                                    <span>
                                                        <IconButton onClick={(btn) => this.handleQuantity(btn, 'decrement')} color="#000">
                                                            <Remove />
                                                        </IconButton>
                                                    </span>
                                                </Grid>                                                                                       
                                            </Grid>
                                        </Grid>
                                        <Grid item className='product-edit-options-note-container'>
                                            <TextField
                                                id="productUserNote"
                                                label="Notes"
                                                multiline
                                                rowsMax="4"
                                                onChange={(node) => {this.handleNotes(node);}}                                                 
                                                margin="normal"              
                                                variant="outlined"
                                                className='product-edit-options-note'
                                                value={this.props.editProductNotes}             
                                            />
                                        </Grid>
                                </Grid>
                                <Grid container className='product-edit-buttons'>
                                    <Button variant="contained" color="primary" className='' onClick={this.addToShoppingCart}>Add to Cart</Button>
                                    <Button variant="contained" color="primary" className='' onClick={this.buyNow}>Buy Now</Button>
                                </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} className='product-edit-tabs-container'>
                                <ProductEditInfoTab productEdit={editProduct} handleShippingCost={this.handleShippingCost} />
                            </Grid>
                        </Grid>
                        
                    
                    : '' }
                     
                </Grid> 
            </div>
        )
    }
}

const mapStateToProps = (store) => {
  return {
      editProduct: store.productsState.editProduct,
      editProductColor: store.productsState.editProductColor,
      editProductShape: store.productsState.editProductShape,
      editProductStyle: store.productsState.editProductStyle,
      editProductSize: store.productsState.editProductSize,
      editProductMaterial: store.productsState.editProductMaterial,
      editProductNotes: store.productsState.editProductNotes,
      dialog: store.dialogState.dialog,
      shoppingCartItems: store.shoppingCartState.shoppingCartItems,
      countShoppingCartItems: store.shoppingCartState.countShoppingCartItems,
      shippingTotal: store.shoppingCartState.shippingTotal,
      user: store.userState.user,
      sortStyle: store.productsState.sortStyle,
      sortMaterial: store.productsState.sortMaterial,
      sortColor: store.productsState.sortColor,
      sortShape: store.productsState.sortShape,
      fromMainPage: store.productsState.fromMainPage,
      shippingCost: store.shoppingCartState.shippingCost,
  }
}

export default connect(mapStateToProps)(ProductEdit);
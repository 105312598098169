import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import $ from 'jquery';
import config from './../../config';
import * as actionFun from './../../actions';
import store from './../../store';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getDataFromServer } from './../../DataProvider';
import SnackbarsCustom from './../SnackbarsCustom';

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },

  button: {
    margin: theme.spacing.unit,
    "min-width": "140px",
    "font-size": "14px"
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  rightIcon: {
    marginLeft: theme.spacing.unit,
  },

  iconSmall: {
    fontSize: 20,
  },
  userPropertyContainer: {
    margin: 'auto',
    backgroundColor: '#f8f8f8',
    padding: '80px',
    border: 'solid 1px #ccc',
    minWidth: '720px',
    borderRadius: '3px',
  },
  btnSaveDiv: {
    marginTop: "20px",
  },
  inputTextStyle: {
    height: "0px;" 
  },
  userPropertyMainContainer: {
    textAlign: 'center',
    marginTop: '20px',
  },
  textfield: {    
    width: '500px !important',
    fontSize: '15px',    
  }
});

 class UserProfileUserProperty extends Component{
    onSaveClick = (e) => {        
        let arrNotValidData = [], { userProperty } = this.props;
        console.log("onSaveClick update property: ", userProperty);
        console.log("onSaveClick update property: ","props: ", this.props);
        
        if(userProperty.receiver_name.trim() === ''){
            this.handlerNotValidField('receiver_name');
            arrNotValidData.push('Receiver Name');
        }

        if(userProperty.country.trim() === ''){
            this.handlerNotValidField('country');
            arrNotValidData.push('Country');
        }

        if(userProperty.street_home_flat.trim() === ''){
            this.handlerNotValidField('street_home_flat');
            arrNotValidData.push('Street / Home / Flat');
        }

        if(userProperty.region.trim() === ''){
            this.handlerNotValidField('region');
            arrNotValidData.push('Region');
        }

        if(userProperty.city.trim() === ''){
            this.handlerNotValidField('city');
            arrNotValidData.push('City');
        }

        if(userProperty.zip_code.trim() === ''){
            this.handlerNotValidField('zip_code');
            arrNotValidData.push('Zip code');
        }

        if(userProperty.telephone.trim() === ''){
            this.handlerNotValidField('telephone');
            arrNotValidData.push('Telephone');
        }      

        if(arrNotValidData.length === 0){
            let alert={};
            getDataFromServer(config.params.server_path + '/update_user_property', {userProperty: userProperty})
            .then(res => {  
                console.log("UserProperty.onSaveClick -> property user result data: ", res.data !== undefined ? res.data : "no data");
                if(res.data.success === true){
                    console.log("UserProperty.onSaveClick -> save property user: ", res.data !== undefined ? res.data.user : "no data");
                    alert = {text: 'Update user properties success.', variant: 'success', show: true, event: this.onAlertClose }
                }else{
                    console.log('UserProperty.onSaveClick -> save property user error: ', res.data.text);
                    alert = {text: 'Faild update user properties, error: ' + res.data.text, variant: 'error', show: true, event: this.onAlertClose }
                }
                store.dispatch(actionFun.showAlert(alert));
            })
            .catch(err => { 
                console.log("UserProperty.onSaveClick -> err save property user: ", err);
                alert = {text: 'Faild update user properties, error: ' + err, variant: 'error', show: true, event: this.onAlertClose };
                store.dispatch(actionFun.showAlert(alert));
            });
        }
    }

    handlerNotValidField = (id) => {
        $("#" + id).addClass("validate-error");
        $("#" + id).on('focus', function(){           
          $("#" + id).removeClass("validate-error");
        });
    }

    onAlertClose = () => {
        let alert = {text: '', variant: 'success', show: false, event: ''};
        store.dispatch(actionFun.showAlert(alert));
    }

    componentDidMount(){
        let alert={};
        getDataFromServer(config.params.server_path + '/get_user_property_by_userId', {userId: this.props.user._id})
            .then(res => {  
                console.log("UserProfileUserProperty.componentDidMount -> get property user result data: ", res.data !== undefined ? res.data : "no data");
                if(res.data.success === true){
                    console.log("UserProfileUserProperty.componentDidMount -> get property user: ", res.data !== undefined ? res.data.data : "no data");
                    store.dispatch(actionFun.setUserProperty(res.data.data[0]));                    
                }else{
                    console.log('UserProfileUserProperty.componentDidMount -> get property user error: ', res.data.text);
                    alert = {text: 'Faild get user properties, error: ' + res.data.text, variant: 'error', show: true, event: this.onAlertClose };
                    store.dispatch(actionFun.showAlert(alert));
                }
                
            })
            .catch(err => { 
                console.log("UserProfileUserProperty.componentDidMount -> err get property user: ", err);
                alert = {text: 'Faild get user properties, error: ' + err, variant: 'error', show: true, event: this.onAlertClose };
                store.dispatch(actionFun.showAlert(alert));
            });
    }

    onTextChange = (e) => {        
        store.dispatch(actionFun.updateUserProperty(e.target.id, e.target.value));
    }
   
   render(){
    console.log("update user property: ", "props: ", this.props);
    const { classes, userProperty, alert} = this.props;
    const isLoaded = userProperty !== null;
    //console.log("update user property: ", "props: ", this.props, "user props:" ,userProperty);    
    return(
        <div id="divUserPropertyContainer" className={classes.userPropertyMainContainer}>
            {alert.show ? 
                    <SnackbarsCustom data={alert} />
                    :''
            }
            <div className={classes.userPropertyContainer} >
                <div>
                    <TextField
                        className={classNames( classes.margin, classes.textfield )}
                        id="receiver_name"
                        placeholder="Receiver Name"
                        value={isLoaded? userProperty.receiver_name : ''}
                        onChange={(node) => this.onTextChange(node)}
                    />
                </div>
                <div>
                    <TextField
                        className={classNames( classes.margin, classes.textfield )}
                        id="country"
                        placeholder="Country"
                        value={isLoaded? userProperty.country : ''}
                        onChange={(node) => this.onTextChange(node)}
                    />
                </div>
                <div>
                    <TextField
                        className={classNames( classes.margin, classes.textfield )}
                        id="street_home_flat"
                        placeholder="Street, house, flat"
                        value={isLoaded? userProperty.street_home_flat : ''}
                        onChange={(node) => this.onTextChange(node)}
                    />
                </div>
                <div>
                    <TextField
                        className={classNames( classes.margin, classes.textfield )}
                        id="region"
                        placeholder="Cegion"
                        value={isLoaded? userProperty.region : ''}
                        onChange={(node) => this.onTextChange(node)}
                    />
                </div>
                <div>
                    <TextField
                        className={classNames( classes.margin, classes.textfield )}
                        id="city"
                        placeholder="City"
                        value={isLoaded? userProperty.city : ''}
                        onChange={(node) => this.onTextChange(node)}
                    />
                </div>
                <div>
                    <TextField
                        className={classNames( classes.margin, classes.textfield )}
                        id="zip_code"
                        placeholder="Zip code"
                        value={isLoaded? userProperty.zip_code : ''}
                        onChange={(node) => this.onTextChange(node)}
                    />
                </div>
                <div>
                    <TextField
                        className={classNames( classes.margin, classes.textfield )}
                        id="telephone"
                        placeholder="Telephone"
                        value={isLoaded? userProperty.telephone : ''}
                        onChange={(node) => this.onTextChange(node)}
                    />
                </div>
                <div id="divUserPropertySave" className={classes.btnSaveDiv}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={() => {this.onSaveClick();}}>
                        Update
                        <SaveIcon className={classes.leftIcon} />
                    </Button>
                </div>
            </div>
        </div>
    );
   }
}

UserProfileUserProperty.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => {
    return {
        user: store.userState.user,
        userProperty: store.userState.userProperty,
        alert: store.alertState.alert,
    }
}

export default withRouter(connect(mapStateToProps)(withStyles(styles)(UserProfileUserProperty)));
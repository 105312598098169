import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { 
    getAdminEditProduct, 
    adminEditProduct, 
    adminEditProductAddImage, 
    adminEditProductRemoveImage, 
    showDialog, 
    showAlert, 
    adminNewProductAddImage 
} from './../../actions';
import { getDataFromServer } from './../../DataProvider';
import config from './../../config';
import store from './../../store';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import SnackbarsCustom from './../SnackbarsCustom';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import CheckBoxGeneral from './../CheckBoxGeneral';
import AdminPorductEditArrays from './AdminPorductEditArrays';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Dialog from './../Dialog';
import adminData from './AdminData';
import { ListItem, List, ListItemIcon, ListItemText } from '@material-ui/core';
import InboxIcon from '@material-ui/icons/Inbox';
import FileUpload from './../FileUpload';

const styles = theme => ({
    root: {
      flexGrow: 1,
      padding: '20px 80px 80px 80px',
      maxWidth: '70%',
      marginLeft: 'auto',
      marginRight: 'auto',      
    },
    tabs: {
        backgroundColor: theme.palette.common.white,
    },
    tabTitle: {
        color: '#000',
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperLink: {
        display: 'block',
        width: '100%',
        cursor: 'pointer',
    },
    textfield: {    
        width: '80%',
        fontSize: '15px',
    },
    margin: {
        margin: theme.spacing.unit,
    },
    labelSpan:{ 
        marginTop: '13px',
        fontSize: '17px',
        width: '150px',
    },
    gridItem:{
        display: 'inline-flex' 
    },
    separatorGrid:{
        marginTop: 20,
        marginBottom: 10,
        textAlign: 'center',
        borderBottom: 'solid 1px #ccc',
        fontWeight: 'bold',
    },
    multiSelectTitle:{
        marginTop: '20px',
        fontSize: '17px',
        fontWeight: 'bold',         
    },
    imageGrid:{
        marginTop: 20,
    },
     buttonSave: {
        margin: theme.spacing.unit,
        minWidth: "140px",
        fontSize: "14px",
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    backToListButton: {
        fontWeight: 'bold',
        textDecoration: 'underline',
    },
    productImage:{
        maxWidth: 320,
        maxHeight: 200,
        width: 'auto',
        height: 'auto',
    }
  });

class AdminPorductEdit extends Component{
    constructor(){
        super();
        this.state = {imageLink: '', 
        imageTitle: '',       
    };
    }

    componentDidMount(){
        store.dispatch(getAdminEditProduct(this.props.editProductId));
    }

    onTextChange = (e) => {
        store.dispatch(adminEditProduct(e.target.id, e.target.value));
    }    

    handleCheckBoxChange = name => e => {
        store.dispatch(adminEditProduct(e.target.value, e.target.checked));
    }

    handleAddImage = () =>{
        let image = {title: this.state.imageTitle, link: this.state.imageLink};        
        store.dispatch(adminEditProductAddImage(image));
    }

    handleRemoveImage = (e, image) =>{
        store.dispatch(adminEditProductRemoveImage(image));
    }
    
    handleImageTextChange = (e) =>{        
        this.setState({[e.target.id]: e.target.value});
    }

    handleAddImageDrop = (name) =>{
        let link = config.params.server_path + '/image/products/' + name;      
        let image = {title: name, link: link};        
        store.dispatch(adminEditProductAddImage(image));
    }

    handleSaveRespoceImageDrop = (err, result) => {        
        let alert={};
        if(err){
            alert = {text: 'Faild save product image, error: ' + err, variant: 'error', show: true, event: this.onAlertClose }
            store.dispatch(showAlert(alert));
        }else if(result.data.success !== true){
            alert = {text: 'Failed save product image, error: ' + result.data.text, variant: 'error', show: true, event: this.onAlertClose }
            store.dispatch(showAlert(alert));
        }
    }

    handleSaveProduct = () => {
        let arrNotValid = [], {editProduct} = this.props; ;

        if(editProduct.sku === undefined || editProduct.sku === ''){
            arrNotValid.push('Sku');
        }
        if(editProduct.title === undefined || editProduct.title === ''){
            arrNotValid.push('Title');
        }
        if(editProduct.description === undefined || editProduct.description === ''){
            arrNotValid.push('Description');
        }
        if(editProduct.price === undefined || editProduct.price === ''){
            arrNotValid.push('Price');
        }
        if(editProduct.installments === undefined || editProduct.installments === ''){
            arrNotValid.push('Installments');
        }
        if(editProduct.currencyId === undefined || editProduct.currencyId === ''){
            arrNotValid.push('CurrencyId');
        }
        if(editProduct.currencyFormat === undefined || editProduct.currencyFormat === ''){
            arrNotValid.push('CurrencyFormat');
        }
        if(editProduct.sizes.length === 0){
            arrNotValid.push('Sizes');
        }
        if(editProduct.styles.length === 0){
            arrNotValid.push('Styles');
        }
        if(editProduct.shapes.length === 0){
            arrNotValid.push('Shapes');
        }
        if(editProduct.colors.length === 0){
            arrNotValid.push('Colors');
        }
        if(editProduct.materials.length === 0){
            arrNotValid.push('Materials');
        }
        if(editProduct.images === undefined || editProduct.images.length === 0){
            arrNotValid.push('Images');
        }
        if(editProduct.isFreeShipping === true){
            if(editProduct.shippingCost !== undefined){
                editProduct.shippingCost = '0';
            }
        }else if(editProduct.isFreeShipping === false){
            if(editProduct.shippingCost === undefined || editProduct.shippingCost === ''){
                arrNotValid.push('Shipping Cost');
            }
        }
        if(arrNotValid.length > 0){
            let dialogData = { 
                title: 'Warning', 
                text: 'Required fields are not filled: ' + arrNotValid.join(', '), 
                buttons: [], 
                show: true 
            }            
            store.dispatch(showDialog(dialogData));
        }else{
            let alert={};
            getDataFromServer(config.params.server_path + '/update_product', {productObj: this.props.editProduct} )
            .then(response => {        
                if(response.data.success === true){
                    alert = {text: 'Update product success.', variant: 'success', show: true, event: this.onAlertClose }
                }else{
                    alert = {text: 'Faild update product, error: ' + response.data.text, variant: 'error', show: true, event: this.onAlertClose }
                }
                console.log("AdminPorductEdit.handleSaveProduct => ", response.data.data);
                store.dispatch(showAlert(alert));
            })
            .catch(err => {
                console.log("AdminPorductEdit.handleSaveProduct => error: ", err);
                alert = {text: 'Faild update product, error: ' + err, variant: 'error', show: true, event: this.onAlertClose }
                store.dispatch(showAlert(alert));
            });
        }
    }

    handlCloseDialog = (show) => {
        let dialogData = { title: '', text: '', buttons: [], show: false }
        store.dispatch(showDialog(dialogData));
    }

    onAlertClose = () => {
        let alert = {text: '', variant: 'success', show: false, event: ''};
        store.dispatch(showAlert(alert));
    }
        
    render(){
        let {classes, adminProductHendler, editProduct, dialog, alert} = this.props;
        console.log("new product obj: ", editProduct);        
        
        let sizesData = {column: 1, field: 'sizes', items: adminData.Sizes, label: 'Sizes:'};
        let stylesData = {column: 2, field: 'styles', items: adminData.Styles, label: 'Styles:'};
        let shapesData = {column: 2, field: 'shapes', items: adminData.Shapes, label: 'Shapes:'};
        let materialsData = {column: 2, field: 'materials', items: adminData.Materials, label: 'Materials:'};
        let colorsData = {column: 1, field: 'colors', items: adminData.Colors, label: 'Colors:'};

        let isLoad = editProduct !== null && editProduct !== undefined;
        let freeShipping = isLoad && editProduct.isFreeShipping === 'true' ? true : false;
        if(isLoad){
            console.log("edit product shipping value: ", editProduct.isFreeShipping, isLoad, isLoad && editProduct.isFreeShipping === 'true', isLoad && editProduct.isFreeShipping == 'true');
        }
        
        return(
            <div>
                {alert.show ? 
                    <SnackbarsCustom data={alert} />
                    :''
                }
                <div className={classes.root}>
                    <Dialog dialogData={ dialog } handlDialog={this.handlCloseDialog}/>
                    <Grid container spacing={8}>
                        <Button className={classNames(classes.button, classes.backToListButton) } onClick={(node) => { adminProductHendler('list', null); } }>
                            <KeyboardArrowLeft />
                            Back to list
                        </Button>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item sm={12} className={classes.separatorGrid}>
                            <span className={classes.labelSpan}>New product</span>
                        </Grid>                        
                        <Grid item sm={12} className={classes.gridItem}>
                            <CheckBoxGeneral
                                data={
                                    {checked: isLoad && editProduct.enable !== undefined ? editProduct.enable : '', 
                                     event: this.handleCheckBoxChange,
                                     value: 'enable',
                                     label: 'Enabled'}}
                            />           
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>SKU:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="sku"
                                placeholder="SKU"
                                value={isLoad && editProduct.sku !== undefined ? editProduct.sku : ''}                                
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Title:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="title"
                                placeholder="Title"
                                value={isLoad && editProduct.title !== undefined ? editProduct.title : ''}
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>                    
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>CurrencyId:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="currencyId"
                                placeholder="CurrencyId"
                                value={isLoad && editProduct.currencyId !== undefined ? editProduct.currencyId : ''}                                
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>

                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Currency Format:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="currencyFormat"
                                placeholder="Currency Format"
                                value={isLoad && editProduct.currencyFormat !== undefined ? editProduct.currencyFormat : ''}                                
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>

                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Installments:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="installments"
                                placeholder="Installments"
                                value={isLoad && editProduct.installments !== undefined ? editProduct.installments : ''}                                
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Price:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="price"
                                placeholder="Price"
                                value={isLoad && editProduct.price !== undefined ? editProduct.price : ''}                                
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>   
                        <Grid item sm={6} className={classes.gridItem}>
                            <CheckBoxGeneral
                                data={
                                    {checked: isLoad && editProduct.isFreeShipping !== undefined ? editProduct.isFreeShipping: false, 
                                     event: this.handleCheckBoxChange,
                                     value: 'isFreeShipping',
                                     label: 'Free Shipping'}}
                            />                                       
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Shipping Cost:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="shippingCost"
                                placeholder="Shipping Cost"
                                value={isLoad && editProduct.shippingCost !== undefined ? editProduct.shippingCost : ''}                                
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>
                        <Grid item sm={12} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Description:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="description"
                                label="Description"
                                value={isLoad && editProduct.description !== undefined ? editProduct.description : ''}                                
                                onChange={(node) => this.onTextChange(node)}
                                multiline
                                rowsMax="6"
                                margin="normal"              
                                variant="outlined"
                            />           
                        </Grid>                        
                    </Grid>
                    
                    <Grid container spacing={8}>
                        <AdminPorductEditArrays data={sizesData} />      
                    </Grid>
                    <Grid container spacing={8}>
                        <AdminPorductEditArrays data={stylesData} />      
                    </Grid>
                    <Grid container spacing={8}>
                        <AdminPorductEditArrays data={shapesData} />      
                    </Grid>
                    <Grid container spacing={8}>
                        <AdminPorductEditArrays data={materialsData} />      
                    </Grid>
                    <Grid container spacing={8}>
                        <AdminPorductEditArrays data={colorsData} />      
                    </Grid>

                    <Grid container spacing={8} className={classes.imageGrid}>
                        <Grid item sm={12} className={classes.gridItem}>
                                <span className={classes.labelSpan}>Image name:</span>
                                <TextField
                                    className={classNames( classes.margin, classes.textfield )}
                                    id="imageTitle"
                                    placeholder="Image name"
                                    value= {this.state.imageTitle}
                                    onChange={this.handleImageTextChange}                                                               
                                />           
                        </Grid>
                        <Grid item sm={10} className={classes.gridItem}>
                                <span className={classes.labelSpan}>Image link:</span>
                                <TextField
                                    className={classNames( classes.margin, classes.textfield )}
                                    id="imageLink"
                                    placeholder="Image link"
                                    value= {this.state.imageLink}
                                    onChange={this.handleImageTextChange}
                                />           
                        </Grid>
                        <Grid item sm={2} className={classes.gridItem}>
                                <Button className={classes.button} onClick={(node) => { this.handleAddImage(); } }>
                                    Add Image
                                </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item sm={12} className={classes.gridItem}>
                            <FileUpload handleAddImageDrop={this.handleAddImageDrop} 
                                        handleSaveRespoceImageDrop={this.handleSaveRespoceImageDrop} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        {isLoad && editProduct.images.map((image, index) =>
                             <Grid key={index} item sm={2} className={classes.gridItem}>
                                <Card className={classes.card}>
                                    <div>
                                        <img className={classes.productImage} alt={image.title} src={image.link} />
                                    </div>
                                    <CardActions className={classes.actions} disableActionSpacing>
                                    <IconButton aria-label="Delete Image" 
                                        onClick={(node) => {
                                            let obj = {title: image.title, link: image.link}; 
                                            this.handleRemoveImage(node, obj);
                                            }}>
                                        <Delete />
                                    </IconButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )}
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item sm={12} className={classes.separatorGrid}>
                            <span className={classes.labelSpan}>Favorite users</span>
                        </Grid>   
                        <Grid item sm={12} className={classes.gridItem}>
                            <List component="nav">
                                {isLoad && editProduct.favorite_by !== undefined ? 
                                    editProduct.favorite_by.map((favorite, index) => 
                                        <ListItem button>
                                            <ListItemIcon>
                                                <InboxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={favorite} />
                                        </ListItem>
                                    ) : ''}            
                            </List>

                        </Grid>                     
                    </Grid>
                    <Grid container spacing={8} className={classes.imageGrid}>
                        <Grid item sm={12} className={classes.gridItem}>
                            <Button variant="contained" color="primary" className={classes.buttonSave} onClick={this.handleSaveProduct}>
                                Save
                                <SaveIcon className={classes.rightIcon} />
                            </Button>
                        </Grid>
                    </Grid> 
                </div>
            </div>
        );
    }
}

AdminPorductEdit.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (store) => {
      return {
          user: store.userState.user,
          editProduct: store.adminState.editProduct,
          dialog: store.dialogState.dialog,
          alert: store.alertState.alert,          
      }
  }

  export default connect(mapStateToProps)(withStyles(styles)(AdminPorductEdit));

//product
export function getProducts(products){
    return {type: 'GET_PRODUCTS', products: products};
}

export function setActiveTab(activeTab){
    return {type: 'CHANGE_TAB', tab: activeTab};
}

export function sortProductPrice(inSort){
    return {type: 'SORT_PRODUCT_PRICE', sortPrice: inSort};
}

export function sortProductStyle(inSort){
    return {type: 'SORT_PRODUCT_STYLE', sortStyle: inSort};
}

export function sortProductMaterial(inSort){
    return {type: 'SORT_PRODUCT_MATERIAL', sortMaterial: inSort};
}

export function sortProductsObject(inSortObj){
    return {type: 'SORT_PRODUCTS_OBJECT', sortObj: inSortObj};
}

export function sortProductColor(color){
    return {type: 'SORT_PRODUCT_COLOR', sortColor: color};
}

export function sortProductShape(shape){
    return {type: 'SORT_PRODUCT_SHAPE', sortShape: shape};
}

export function searchProduct(inText){
    return {type: 'SEARCH_PRODUCT', searchText: inText};
}

export function colorProductSelectOpen(isOpen, element){
    return {type: 'SORT_COLOR_IS_OPEN', sortColorIsOpen: isOpen, anchorEl: element};
}

export function shapeProductSelectOpen(isOpen, element){
    return {type: 'SORT_SHAPE_IS_OPEN', sortShapeIsOpen: isOpen, sortShapeEl: element};
}

export function getProductById(product){
    return {type: 'GET_PRODUCT_BY_ID', editProduct: product};
}

export function setProductEditColor(color){
    return {type: 'SET_PRODUCT_EDIT_COLOR', editProductColor: color};
}

export function setProductEditShape(shape){
    return {type: 'SET_PRODUCT_EDIT_SHAPE', editProductShape: shape};
}

export function setProductEditStyle(style){
    return {type: 'SET_PRODUCT_EDIT_STYLE', editProductStyle: style};
}

export function setProductEditSize(size){
    return {type: 'SET_PRODUCT_EDIT_SIZE', editProductSize: size};
}

export function setProductEditMaterial(material){
    return {type: 'SET_PRODUCT_EDIT_MATERIAL', editProductMaterial: material};
}

export function setProductEditNote(note){
    return {type: 'SET_PRODUCT_EDIT_NOTES', editProductNotes: note};
}

export function setFromMainPage(mainPage){
    return {type: 'SET_FROM_MAIN_PAGE', fromMainPage: mainPage};
}

export function showSocialButtons(show, productId){
    return {type: 'SHOW_SOCIAL_BUTTONS', showSocialButtons: show, productID: productId};
}

//shopping cart
export function addShoppingCart(shoppingCartItem){
    return {type: 'ADD_SHOPPING_CART', shoppingCartItems: shoppingCartItem};
}

export function updateShoppingCart(shoppingCartItem){
    return {type: 'UPDATE_SHOPPING_CART', shoppingCartItems: shoppingCartItem};
}

export function updateShoppingCartField(productId, key, value){
    return {type: 'UPDATE_SHOPPING_CART_FIELD', productId: productId, key: key, value: value};
}

export function removeShoppingCart(shoppingCartItemId){
    return {type: 'REMOVE_SHOPPING_CART', shoppingCartItem: shoppingCartItemId};
}

export function setCountShoppingCartItems(count){
    return {type: 'COUNT_SHOPPING_CART_ITEMS', countShoppingCartItems: count};
}

export function setFromPayPage(payPage){
    return {type: 'COME_FROM_PAY_PAGE', payPage: payPage};
}

export function setShoppingCartUserDetails(shoppingCartUserDetails){
    return {type: 'SET_SHOPPING_CART_USER_PROPERTY', shoppingCartUserDetails: shoppingCartUserDetails};
}

export function updateShoppingCartUserDetails(shoppingCartUserDetailsKey, shoppingCartUserDetailsValue){
    return {type: 'UPDATE_SHOPPING_CART_USER_PROPERTY', shoppingCartUserDetailsKey: shoppingCartUserDetailsKey, 
        shoppingCartUserDetailsValue: shoppingCartUserDetailsValue};
}

export function updateShoppingCartDescription(text){
    return {type: 'UPDATE_SHOPPING_CART_DESCRIPTION', orderDescriptionState: text};
}

export function clearShoppingCart(){
    return {type: 'CLEAR_SHOPPING_CART', shoppingCartItems: []};
}

export function setBuyNowItem(item, buyNow){
    return {type: 'SET_BUY_NOW_ITEM', buyNowItem: item, buyNow: buyNow};
}

export function setShoppingCartShippingTotal(shopping){
    return {type: 'SET_SHOPPING_CART_SHIPPING_TOTAL', shippingTotal: shopping};
}

export function setShoppingCartShippingCost(shippingCost){
    return {type: 'SET_SHOPPING_CART_SHIPPING_COST', shippingCost: shippingCost};
}

//dialog
export function showDialog(dialogData){
    return {type: 'SHOW_DIALOG', dialog: dialogData};
}

//alert
export function showAlert(alertData){
    return {type: 'SHOW_ALERT', alert: alertData};
}

//user
export function setUser(userData){
    return {type: 'SET_USER', user: userData};
}

export function setUserForgetPassword(forgetPasswordData){
    return {type: 'USER_FORGET_PASSWORD', forgetPassword: forgetPasswordData};
}

export function setUserProperty(userPropertyData){
    return {type: 'SET_USER_PROPERTY', userProperty: userPropertyData};
}

export function updateUserProperty(key, value){
    return {type: 'UPDATE_USER_PROPERTY', userPropertyKey: key, userPropertyValue: value};
}

export function setUserResetPassword(resetPassword){
    return {type: 'SET_USER_RESET_PASSWORD', resetPassword: resetPassword};
}

export function setUserVerificationCode(verificationCode){
    return {type: 'SET_USER_VERIFICATION_CODE', verificationCode: verificationCode};
}

//user profile
export function setHistoryOrders(historiOrders){
    return {type: 'SET_HISTORY_ORDERS', historyOrders: historiOrders};
}

export function setFavorites(favorites){
    return {type: 'SET_FAVORITES', favorites: favorites};
}

// =============================== admin ================================= //
//orders
export function setAdminOrders(orders){
    return {type: 'SET_ADMIN_ORDERS', orders: orders};
}

export function setAdminEditOrder(order){
    return {type: 'SET_ADMIN_EDIT_ORDER', editOrder: order};
}

export function getAdminEditOrder(orderID){
    return {type: 'GET_ADMIN_EDIT_ORDER', orderId: orderID};
}

export function updateAdminEditOrder(key, value){
    return {type: 'UPDATE_ADMIN_EDIT_ORDER', editOrderKey: key, editOrderValue: value};
}

export function adminOrdersUpdateStatus(orders){
    return {type: 'ADMIN_ORDERS_UPDATE_STATUS', orders: orders};
}

//product
export function setAdminProducts(products){
    return {type: 'SET_ADMIN_PRODUCTS', products: products};
}

export function setAdminEditProduct(product){
    return {type: 'SET_ADMIN_EDIT_PRODUCT', editProduct: product};
}

export function getAdminEditProduct(productID){
    return {type: 'GET_ADMIN_EDIT_PRODUCT', productId: productID};
}

export function updateAdminEditProduct(key, value){
    return {type: 'UPDATE_ADMIN_EDIT_PRODUCT', editProductKey: key, editProductValue: value};
}

export function adminNewProduct(key, value){
    return {type: 'NEW_ADMIN_PRODUCT', newProductKey: key, newProductValue: value};
}

export function adminNewProductUpdateArrays(key, value){
    return {type: 'UPDATE_ADMIN_NEW_PRODUCT_ARRAYS', newProductKey: key, newProductValue: value};
}

export function adminNewProductDeleteArrays(key, value){
    return {type: 'DELETE_ADMIN_NEW_PRODUCT_ARRAYS', newProductKey: key, newProductValue: value};
}

export function adminNewProductAddImage(image){
    return {type: 'ADMIN_NEW_PRODUCT_ADD_IMAGE', newProductImage: image};
}

export function adminNewProductRemoveImage(image){
    return {type: 'ADMIN_NEW_PRODUCT_REMOVE_IMAGE', newProductImage: image};
}

//edit product
export function adminEditProduct(key, value){
    return {type: 'ADMIN_EDIT_PRODUCT', editProductKey: key, editProductValue: value};
}

export function adminEditProductUpdateArrays(key, value){
    return {type: 'ADMIN_EDIT_PRODUCT_UPDATE_ARRAYS', editProductKey: key, editProductValue: value};
}

export function adminEditProductDeleteArrays(key, value){
    return {type: 'ADMIN_EDIT_PRODUCT_DELETE_ARRAYS', editProductKey: key, editProductValue: value};
}

export function adminEditProductAddImage(image){
    return {type: 'ADMIN_EDIT_PRODUCT_ADD_IMAGE', editProductImage: image};
}

export function adminEditProductRemoveImage(image){
    return {type: 'ADMIN_EDIT_PRODUCT_REMOVE_IMAGE', editProductImage: image};
}

//user
export function setAdminUsers(users){
    return {type: 'ADMIN_SET_USERS', users: users};
}

export function setAdminEditUser(user){
    return {type: 'ADMIN_SET_EDIT_USER', editUser: user};
}

export function getAdminEditUser(userID){
    return {type: 'ADMIN_GET_EDIT_USER', userId: userID};
}

export function updateAdminEditUser(key, value){
    return {type: 'ADMIN_UPDATE_EDIT_USER', editUserKey: key, editUserValue: value};
}

export function updateAdminEditUserProperty(key, value){
    return {type: 'ADMIN_UPDATE_EDIT_USER_PROPERTY', userPropertyKey: key, userPropertyValue: value};
}

//slider
export function setAdminSliderImages(sliderImages){
    return {type: 'ADMIN_SET_SLIDER_IMAGES', sliderImages: sliderImages};
}

//contact us
export function constactUsSetData(key, value){
    return {type: 'CONATACTUS_SET_DATA', constactUsKey: key, constactUsValue: value};
}



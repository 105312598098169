const initialDialogtState = {
    orders: [],
    editOrder: null,
    products: [],
    editProduct: null,
    newProduct: {sizes:[], styles:[], colors:[], materials:[], images:[], shapes:[], enable: true, isFreeShipping: false},
    users: [],
    editUser: null,
    sliderImages: [],         
  };
  
  export default function userProfile(state = initialDialogtState, action){
      switch(action.type){
          //orders
          case "SET_ADMIN_ORDERS":
            return { ...state, orders: action.orders };          
          case "SET_ADMIN_EDIT_ORDER":
            return { ...state, editOrder: action.editOrder };
          case "GET_ADMIN_EDIT_ORDER":             
            return { ...state, editOrder: state.orders.filter(order => order._id === action.orderId)[0]};
          case "UPDATE_ADMIN_EDIT_ORDER":          
            return { ...state, editOrder: {...state.editOrder, [action.editOrderKey]: action.editOrderValue}};
          
          case "ADMIN_ORDERS_UPDATE_STATUS":
            let result;
            result = state.shoppingCartItems.map((item, index)=>{
                if(item.product._id === action.shoppingCartItems.productId){
                  item.selectedOptions.quantity = action.shoppingCartItems.quantity;
                  return item; 
                }
                return item;
            });            
            return {...state, result};
          
          //products
          case "SET_ADMIN_PRODUCTS":
            return { ...state, products: action.products };          
          case "SET_ADMIN_EDIT_PRODUCT":
            return { ...state, editProduct: action.editProduct };
          case "GET_ADMIN_EDIT_PRODUCT":             
            return { ...state, editProduct: state.products.filter(product => product._id === action.productId)[0]};
          case "UPDATE_ADMIN_EDIT_PRODUCT":          
            return { ...state, editProduct: {...state.editProduct, [action.editProductKey]: action.editProductValue}};
          case "NEW_ADMIN_PRODUCT":          
            return { ...state, newProduct: {...state.newProduct, [action.newProductKey]: action.newProductValue}};
          case "UPDATE_ADMIN_NEW_PRODUCT_ARRAYS":                     
            return { ...state, newProduct: {...state.newProduct, [action.newProductKey]: [...state.newProduct[action.newProductKey], action.newProductValue]}};
          case "DELETE_ADMIN_NEW_PRODUCT_ARRAYS":
            return { ...state, newProduct: {...state.newProduct, [action.newProductKey]: [...state.newProduct[action.newProductKey].filter(x => x !== action.newProductValue)]}};
          case "ADMIN_NEW_PRODUCT_ADD_IMAGE":                     
            return { ...state, newProduct: {...state.newProduct, images: [...state.newProduct.images, action.newProductImage]}};
          case "ADMIN_NEW_PRODUCT_REMOVE_IMAGE":
            return { ...state, newProduct: {...state.newProduct, images: 
              [...state.newProduct.images.filter(
                x => x.title !== action.newProductImage.title 
                && x.link !== action.newProductImage.link)]}};
          
          case "ADMIN_EDIT_PRODUCT":          
            return { ...state, editProduct: {...state.editProduct, [action.editProductKey]: action.editProductValue}};
          case "ADMIN_EDIT_PRODUCT_UPDATE_ARRAYS":                     
            return { ...state, editProduct: {...state.editProduct, [action.editProductKey]: [...state.editProduct[action.editProductKey], action.editProductValue]}};
          case "ADMIN_EDIT_PRODUCT_DELETE_ARRAYS":
            return { ...state, editProduct: {...state.editProduct, [action.editProductKey]: [...state.editProduct[action.editProductKey].filter(x => x !== action.editProductValue)]}};
          case "ADMIN_EDIT_PRODUCT_ADD_IMAGE":                     
            return { ...state, editProduct: {...state.editProduct, images: [...state.editProduct.images, action.editProductImage]}};
          case "ADMIN_EDIT_PRODUCT_REMOVE_IMAGE":
            return { ...state, editProduct: {...state.editProduct, images: 
              [...state.editProduct.images.filter(
                x => x.title !== action.editProductImage.title 
                && x.link !== action.editProductImage.link)]}};

          //users
          case "ADMIN_SET_USERS":
            return { ...state, users: action.users };          
          case "ADMIN_SET_EDIT_USER":
            return { ...state, editUser: action.editUser };
          case "ADMIN_GET_EDIT_USER":             
            return { ...state, editUser: state.users.filter(user => user._id === action.userId)[0]};
          case "ADMIN_UPDATE_EDIT_USER":          
            return { ...state, editUser: {...state.editUser, [action.editUserKey]: action.editUserValue}};
          case "ADMIN_UPDATE_EDIT_USER_PROPERTY":                    
            return {...state, editUser: 
              {...state.editUser, properties: 
                [{...state.editUser.properties[0], [action.userPropertyKey]: action.userPropertyValue}]}};
          default:
            return state;

          //Slider
          case "ADMIN_SET_SLIDER_IMAGES":
            return { ...state, sliderImages: action.sliderImages };           
      }   
  }
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { updateAdminEditUser, getAdminEditUser, showAlert, updateAdminEditUserProperty, showDialog } from './../../actions';
import { getDataFromServer } from './../../DataProvider';
import config from './../../config';
import store from './../../store';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import Dialog from './../Dialog';
import CheckBoxGeneral from './../CheckBoxGeneral';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import SelectedListGeneral from './../SelectedListGeneral';
import adminData from './AdminData';
import SnackbarsCustom from './../SnackbarsCustom';

const styles = theme => ({
    root: {
      flexGrow: 1,
      padding: '20px 80px 80px 80px',
      maxWidth: '70%',
      marginLeft: 'auto',
      marginRight: 'auto',      
    },
    tabs: {
        backgroundColor: theme.palette.common.white,
     },
     tabTitle: {
        color: '#000',
     },
     paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
      paperLink: {
        display: 'block',
        width: '100%',
        cursor: 'pointer',
      },
      textfield: {    
        width: '80%',
        fontSize: '15px',
      },
      margin: {
        margin: theme.spacing.unit,
      },
      labelSpan:{ 
        marginTop: '13px',
        fontSize: '17px',
        width: '150px',
      },
      gridItem:{
        display: 'inline-flex' 
      },
      separatorGrid:{
        marginTop: 20,
        marginBottom: 10,
        textAlign: 'center',
        borderBottom: 'solid 1px #ccc',
        fontWeight: 'bold',
      },
      multiSelectTitle:{
          marginTop: '20px',
          fontSize: '17px',
          fontWeight: 'bold',         
      },
      imageGrid:{
          marginTop: 20,
      },
      buttonSave: {
        margin: theme.spacing.unit,
        minWidth: "140px",
        fontSize: "14px",
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      backToListButton: {
        fontWeight: 'bold',
        textDecoration: 'underline',
    }
  });

class AdminUserEdit extends Component{
    componentDidMount(){
        this.getEditUser();
    }

    getEditUser = () => {
        store.dispatch(getAdminEditUser(this.props.editUserId));
    }   

    handleSelectChange = (event) => {        
        store.dispatch(updateAdminEditUser('permission', event.target.value));
    }

    handleCheckBoxChange = name => e => {
        store.dispatch(updateAdminEditUser(e.target.value, e.target.checked));
    }

    handleSaveUser = () => {
        let alert={};        
        getDataFromServer(config.params.server_path + '/update_user', {updateObj: this.props.editUser})
        .then(response => {        
            if(response.data.success === true){
                alert = {text: 'Update user success.', variant: 'success', show: true, event: this.onAlertClose }
            }else{
                alert = {text: 'Faild update user, error: ' + response.data.text, variant: 'error', show: true, event: this.onAlertClose }
            }
            console.log("AdminUserEdit.handleSaveUser => ", response.data.data);
            store.dispatch(showAlert(alert));
        })
        .catch(err => {
            console.log("AdminUserEdit.handleSaveUser => error: ", err);
            alert = {text: 'Faild update user, error: ' + err, variant: 'error', show: true, event: this.onAlertClose };
            store.dispatch(showAlert(alert));
        });
    }

    onTextChange = (e) => {
        console.log("user property text change: ", e.target.id, e.target.value);        
        store.dispatch(updateAdminEditUserProperty(e.target.id, e.target.value));
    }

    onSaveClickUserProperty = (e) => {
        e.preventDefault();
        let arrNotValidData = [], { editUser } = this.props;
        let userProperty = editUser.properties[0];
        console.log("onSaveClick update property: ", userProperty);
        console.log("onSaveClick update property: ","props: ", this.props);
        
        if(userProperty.receiver_name.trim() === ''){            
            arrNotValidData.push('Receiver Name');
        }
        if(userProperty.country.trim() === ''){           
            arrNotValidData.push('Country');
        }
        if(userProperty.street_home_flat.trim() === ''){            
            arrNotValidData.push('Street / Home / Flat');
        }
        if(userProperty.region.trim() === ''){            
            arrNotValidData.push('Region');
        }
        if(userProperty.city.trim() === ''){            
            arrNotValidData.push('City');
        }
        if(userProperty.zip_code.trim() === ''){            
            arrNotValidData.push('Zip code');
        }
        if(userProperty.telephone.trim() === ''){            
            arrNotValidData.push('Telephone');
        } 
        if(arrNotValidData.length === 0){
            let alert={};
            getDataFromServer(config.params.server_path + '/update_user_property', {userProperty: userProperty})
            .then(res => {  
                console.log("UserProperty.onSaveClick -> property user result data: ", res.data !== undefined ? res.data : "no data");
                if(res.data.success === true){
                    console.log("UserProperty.onSaveClick -> save property user: ", res.data !== undefined ? res.data.user : "no data");
                    alert = {text: 'Update user properties success.', variant: 'success', show: true, event: this.onAlertClose }
                }else{
                    console.log('UserProperty.onSaveClick -> save property user error: ', res.data.text);
                    alert = {text: 'Faild update user properties, error: ' + res.data.text, variant: 'error', show: true, event: this.onAlertClose }
                }
                store.dispatch(showAlert(alert));
            })
            .catch(err => { 
                console.log("UserProperty.onSaveClick -> err save property user: ", err);
                alert = {text: 'Faild update user properties, error: ' + err, variant: 'error', show: true, event: this.onAlertClose };
                store.dispatch(showAlert(alert));
            });
        }else{
            if(arrNotValidData.length > 0){
                let dialogData = { 
                    title: 'Warning', 
                    text: 'Required fields are not filled: ' + arrNotValidData.join(', '), 
                    buttons: [], 
                    show: true 
                }            
                store.dispatch(showDialog(dialogData));
            }
        }
    }
    
    onAlertClose = () => {
        let alert = {text: '', variant: 'success', show: false, event: ''};
        store.dispatch(showAlert(alert));
    }

    handlCloseDialog = (show) => {
        let dialogData = { title: '', text: '', buttons: [], show: false }
        store.dispatch(showDialog(dialogData));
    }
    
    render(){

        let {classes, editUser, adminUserHendler, dialog, alert} = this.props;
        let isLoad = editUser !== null && editUser !== undefined;
        let isLoadProperty = isLoad && editUser.properties !== undefined;
        let userProperty = isLoadProperty ? editUser.properties[0] : null; 
        
        return(
            <div className={classes.root}>
                {alert.show ? 
                    <SnackbarsCustom data={alert} />
                    :''
                }
                 <Dialog dialogData={ dialog } handlDialog={this.handlCloseDialog}/>
                    <Grid container spacing={8}>
                        <Button className={classNames(classes.button, classes.backToListButton) } onClick={(node) => { adminUserHendler('list', null); } }>
                            <KeyboardArrowLeft />
                            Back to list
                        </Button>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item sm={12} className={classes.separatorGrid}>
                            <span className={classes.labelSpan}>Edit user</span>
                        </Grid>                        
                        <Grid item sm={2} className={classes.gridItem}>
                            <CheckBoxGeneral
                                data={
                                    {checked: isLoad && editUser.enable !== undefined ? editUser.enable : '', 
                                     event: this.handleCheckBoxChange,
                                     value: 'enable',
                                     label: 'Enabled'}}
                            />           
                        </Grid>
                        <Grid item sm={2} className={classes.gridItem}>
                            <CheckBoxGeneral
                                data={
                                    {checked: isLoad && editUser.identified !== undefined ? editUser.identified : '', 
                                     event: this.handleCheckBoxChange,
                                     value: 'identified',
                                     label: 'Identified'}}
                            />           
                        </Grid>
                        <Grid item sm={2} className={classes.gridItem}>
                            <CheckBoxGeneral
                                data={
                                    {checked: isLoad && editUser.verification_by_email !== undefined ? editUser.verification_by_email : '', 
                                     event: this.handleCheckBoxChange,
                                     value: 'verification_by_email',
                                     label: 'Verification by email'}}
                            />           
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Permission:</span>
                            {isLoad ? 
                                <SelectedListGeneral
                                    selected={editUser.permission}
                                    name={editUser.permission}
                                    items={adminData.UserPermission}
                                    handleChange={this.handleSelectChange}
                                /> : ''
                            }           
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Name:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="name"
                                placeholder="name"
                                value={isLoad && editUser.name !== undefined ? editUser.name : ''}
                                onChange={(node) => this.onTextChange(node)}
                                disabled
                            />           
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Login:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="login"
                                placeholder="login"
                                value={isLoad && editUser.login !== undefined ? editUser.login : ''}
                                onChange={(node) => this.onTextChange(node)}
                                disabled
                            />           
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Email:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="email"
                                placeholder="email"
                                value={isLoad && editUser.email !== undefined ? editUser.email : ''}
                                onChange={(node) => this.onTextChange(node)}
                                disabled
                            />           
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Verification Code:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="verification_code"
                                placeholder="verification_code"
                                value={isLoad && editUser.verification_code !== undefined ? editUser.verification_code : ''}
                                onChange={(node) => this.onTextChange(node)}
                                disabled
                            />           
                        </Grid>
                 </Grid>
                 <Grid container spacing={8} className={classes.imageGrid}>
                    <Grid item sm={12} className={classes.gridItem}>
                        <Button variant="contained" color="primary" className={classes.buttonSave} onClick={this.handleSaveUser}>
                            Update
                            <SaveIcon className={classes.rightIcon} />
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={8}>
                    <Grid item sm={12} className={classes.separatorGrid}>
                        <span className={classes.labelSpan}>User property</span>
                    </Grid>                    
                </Grid>
                {isLoad && editUser.identified === true ?
                <Grid container spacing={8}>
                    <Grid item sm={6} className={classes.gridItem}>
                        <span className={classes.labelSpan}>Receiver Name:</span>
                        <TextField
                            className={classNames( classes.margin, classes.textfield )}
                            id="receiver_name"
                            placeholder="Receiver Name"
                            value={isLoadProperty && userProperty !== null? userProperty.receiver_name : ''}
                            onChange={(node) => this.onTextChange(node)}
                        />
                    </Grid>
                    <Grid item sm={6} className={classes.gridItem}>
                        <span className={classes.labelSpan}>Country:</span>
                        <TextField
                            className={classNames( classes.margin, classes.textfield )}
                            id="country"
                            placeholder="Country"
                            value={isLoadProperty && userProperty !== null? userProperty.country : ''}
                            onChange={(node) => this.onTextChange(node)}
                        />
                    </Grid>
                    <Grid item sm={6} className={classes.gridItem}>
                        <span className={classes.labelSpan}>Street, house, flat:</span>
                        <TextField
                            className={classNames( classes.margin, classes.textfield )}
                            id="street_home_flat"
                            placeholder="Street, house, flat"
                            value={isLoadProperty && userProperty !== null? userProperty.street_home_flat : ''}
                            onChange={(node) => this.onTextChange(node)}
                        />
                    </Grid>
                    <Grid item sm={6} className={classes.gridItem}>
                        <span className={classes.labelSpan}>Region:</span>
                        <TextField
                            className={classNames( classes.margin, classes.textfield )}
                            id="region"
                            placeholder="Region"
                            value={isLoadProperty && userProperty !== null? userProperty.region : ''}
                            onChange={(node) => this.onTextChange(node)}
                        />
                    </Grid>
                    <Grid item sm={6} className={classes.gridItem}>
                        <span className={classes.labelSpan}>City:</span>
                        <TextField
                            className={classNames( classes.margin, classes.textfield )}
                            id="city"
                            placeholder="City"
                            value={isLoadProperty && userProperty !== null? userProperty.city : ''}
                            onChange={(node) => this.onTextChange(node)}
                        />
                    </Grid>
                    <Grid item sm={6} className={classes.gridItem}>
                        <span className={classes.labelSpan}>Zip code:</span>
                        <TextField
                            className={classNames( classes.margin, classes.textfield )}
                            id="zip_code"
                            placeholder="Zip code"
                            value={isLoadProperty && userProperty !== null? userProperty.zip_code : ''}
                            onChange={(node) => this.onTextChange(node)}
                        />
                    </Grid>
                    <Grid item sm={6} className={classes.gridItem}>
                        <span className={classes.labelSpan}>Telephone:</span>
                        <TextField
                            className={classNames( classes.margin, classes.textfield )}
                            id="telephone"
                            placeholder="Telephone"
                            value={isLoadProperty && userProperty !== null? userProperty.telephone : ''}
                            onChange={(node) => this.onTextChange(node)}
                        />
                    </Grid>
                    <Grid item sm={12} className={classes.gridItem}>
                        <Button variant="contained" color="primary" className={classes.buttonSave} onClick={this.onSaveClickUserProperty}>
                            Update
                            <SaveIcon className={classes.rightIcon} />
                        </Button>
                    </Grid>
                </Grid>
                : ''}
            </div>
        );
    }
}

AdminUserEdit.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (store) => {
      return {
          user: store.userState.user,
          dialog: store.dialogState.dialog,
          alert: store.alertState.alert,
          editUser: store.adminState.editUser,
      }
  }

  export default connect(mapStateToProps)(withStyles(styles)(AdminUserEdit));
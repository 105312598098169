import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "./Home.css";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import config from './../config';
import { getDataFromServer } from './../DataProvider';
//import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    root:{
        marginTop: 20,
    },    
    sladerContainer:{
        // width: '100%',
        padding: 10,
    },
})
class Home extends Component{
    constructor(props){
        super(props);
        this.state = {listSliderImages: []}
    }
    componentDidMount(){
        this.getSliderImagesFromServer();
    }

    getSliderImagesFromServer = () => {
        getDataFromServer(config.params.server_path + '/list_slider_images_enabled')
        .then(response => {        
            if(response.data.success === true){
                this.setState({listSliderImages: response.data.data});
            }
            console.log("AdminSlider.getImagesFromServer => ", response.data.data);
        })
        .catch(err => {console.log("AdminSlider.getImagesFromServer => error: ", err)});
    }

    onProductClick = () => {
        this.props.history.push('/products');
    }

    render(){
        let {classes} = this.props;
        let images;

        images = this.state.listSliderImages.map((image, index) => {
            return <img src={image.link} key={index} />
        })

       let images1 = this.state.listSliderImages.map((image, index) => {
           let imgSet = image.link_mobile !== undefined || '' ? [{media: '(max-width: 750px)', srcSet: image.link_mobile}] : ''
            return { 
                original: image.link,
                originalClass: 'home-slider-image',
                imageSet: imgSet,                  
            };
        })

        return(
         <div className={classes.root}>
            <div className={classes.sladerContainer}>
                {/* <Carousel showStatus={false} showThumbs={false} autoPlay={true} infiniteLoop={true} transitionTime={700} stopOnHover={false}>
                    {images}                    
                </Carousel> */}
                <ImageGallery showPlayButton={false} autoPlay={true} isRTL={true} showFullscreenButton={false} items={images1} 
                    showBullets={false} showNav={false}/>
            </div>
            <div className='home-products-btn'>                
                <Button variant="contained" color="primary" className={classes.button} onClick={() => {this.onProductClick();}}>
                    Products
                </Button>               
            </div>
         </div>
    )}
}
Home.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(Home);
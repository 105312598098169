import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import $ from 'jquery';
import config from '../config';
import * as actionFun from './../actions';
import store from './../store';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getDataFromServer } from './../DataProvider';
import SnackbarsCustom from './SnackbarsCustom';

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },

  button: {
    margin: theme.spacing.unit,
    "min-width": "140px",
    "font-size": "14px"
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  rightIcon: {
    marginLeft: theme.spacing.unit,
  },

  iconSmall: {
    fontSize: 20,
  },
  userPropertyContainer: {
    margin: 'auto',
    backgroundColor: '#f8f8f8',
    padding: '80px',
    border: 'solid 1px #ccc',
    minWidth: '720px',
    borderRadius: '3px',
  },
  btnSaveDiv: {
    marginTop: "20px",
  },
  inputTextStyle: {
    height: "0px;" 
  },
  userPropertyMainContainer: {
    textAlign: 'center',
    marginTop: '20px',
  },
  textfield: {    
    width: '500px !important',
    fontSize: '15px',    
  }
});

 class UserProperty extends Component{
    onSaveClick = (e) => {
        e.preventDefault();
        let arrNotValidData = [], userProperty = {};        
        
        if($('#txtUserPropertyReceiverName').val().trim() === ''){
            this.handlerNotValidField('txtUserPropertyReceiverName');
            arrNotValidData.push('Receiver Name');
        }else{
            userProperty.receiver_name = $('#txtUserPropertyReceiverName').val();
        }

        if($('#txtUserPropertyCountry').val().trim() === ''){
            this.handlerNotValidField('txtUserPropertyCountry');
            arrNotValidData.push('Country');
        }else{
            userProperty.country = $('#txtUserPropertyCountry').val(); 
        }

        if($('#txtUserPropertyStreetHomeFlat').val().trim() === ''){
            this.handlerNotValidField('txtUserPropertyStreetHomeFlat');
            arrNotValidData.push('Street / Home / Flat');
        }else{
            userProperty.street_home_flat = $('#txtUserPropertyStreetHomeFlat').val();
        }

        if($('#txtUserPropertyRegion').val().trim() === ''){
            this.handlerNotValidField('txtUserPropertyRegion');
            arrNotValidData.push('Region');
        }else{
            userProperty.region = $('#txtUserPropertyRegion').val();
        }

        if($('#txtUserPropertyCity').val().trim() === ''){
            this.handlerNotValidField('txtUserPropertyCity');
            arrNotValidData.push('City');
        }else{
            userProperty.city = $('#txtUserPropertyCity').val();
        }

        if($('#txtUserPropertyZipCode').val().trim() === ''){
            this.handlerNotValidField('txtUserPropertyZipCode');
            arrNotValidData.push('Zip code');
        }else{
            userProperty.zip_code = $('#txtUserPropertyZipCode').val();
        }

        if($('#txtUserPropertyTelephone').val().trim() === ''){
            this.handlerNotValidField('txtUserPropertyTelephone');
            arrNotValidData.push('Telephone');
        }else{
            userProperty.telephone = $('#txtUserPropertyTelephone').val();
        }
        
        userProperty.user_id = this.props.user._id;

        if(arrNotValidData.length === 0){
            let alert={};
            getDataFromServer(config.params.server_path + '/new_user_property', {userProperty: userProperty})
            .then(res => {  
                console.log("UserProperty.onSaveClick -> property user result data: ", res.data !== undefined ? res.data : "no data");
                if(res.data.success === true){
                    console.log("UserProperty.onSaveClick -> save property user: ", res.data !== undefined ? res.data.user : "no data");
                    alert = {text: 'Save new user properties success.', variant: 'success', show: true, event: this.onAlertClose }
                    this.props.history.push('/userprofile');
                }else{
                    console.log('UserProperty.onSaveClick -> save property user error: ', res.data.text);
                    alert = {text: 'Faild save new user properties, error: ' + res.data.text, variant: 'error', show: true, event: this.onAlertClose }
                }
                store.dispatch(actionFun.showAlert(alert));
            })
            .catch(err => { 
                console.log("UserProperty.onSaveClick -> err save property user: ", err);
                alert = {text: 'Faild save new user properties, error: ' + err, variant: 'error', show: true, event: this.onAlertClose };
                store.dispatch(actionFun.showAlert(alert));
            });
        }
    }

    handlerNotValidField = (id) => {
        $("#" + id).addClass("validate-error");
        $("#" + id).on('focus', function(){           
          $("#" + id).removeClass("validate-error");
        });
    }

    onAlertClose = () => {
        let alert = {text: '', variant: 'success', show: false, event: ''};
        store.dispatch(actionFun.showAlert(alert));
    }   

   render(){
    const { classes, alert } = this.props;    
    return(
        <div id="divUserPropertyContainer" className={classes.userPropertyMainContainer}>
            {alert.show ? 
                <SnackbarsCustom data={alert} />
                :''
            }
            <div className={classes.userPropertyContainer} >
                <div>
                    <TextField
                        className={classNames( classes.margin, classes.textfield )}
                        id="txtUserPropertyReceiverName"
                        placeholder="Receiver Name"
                    />
                </div>
                <div>
                    <TextField
                        className={classNames( classes.margin, classes.textfield )}
                        id="txtUserPropertyCountry"
                        placeholder="Country"
                    />
                </div>
                <div>
                    <TextField
                        className={classNames( classes.margin, classes.textfield )}
                        id="txtUserPropertyStreetHomeFlat"
                        placeholder="Street, house, flat"
                    />
                </div>
                <div>
                    <TextField
                        className={classNames( classes.margin, classes.textfield )}
                        id="txtUserPropertyRegion"
                        placeholder="Region"
                    />
                </div>
                <div>
                    <TextField
                        className={classNames( classes.margin, classes.textfield )}
                        id="txtUserPropertyCity"
                        placeholder="City"
                    />
                </div>
                <div>
                    <TextField
                        className={classNames( classes.margin, classes.textfield )}
                        id="txtUserPropertyZipCode"
                        placeholder="Zip code"
                    />
                </div>
                <div>
                    <TextField
                        className={classNames( classes.margin, classes.textfield )}
                        id="txtUserPropertyTelephone"
                        placeholder="Telephone"
                    />
                </div>
                <div id="divUserPropertySave" className={classes.btnSaveDiv}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={this.onSaveClick}>
                        Save
                        <SaveIcon className={classes.leftIcon} />
                    </Button>
                </div>
            </div>
        </div>
    );
   }
}


UserProperty.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => {
    return {
        user: store.userState.user,
        alert: store.alertState.alert,
    }
}

export default withRouter(connect(mapStateToProps)(withStyles(styles)(UserProperty)));
import React, { Component } from 'react';
import './TopSlider.css';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';

class TopSlider extends Component{    
    render(){
        let {classes} = this.props;       
        const arrImages = this.props.images.map((image, index) => {
                                return {
                                            original: image.link,                                            
                                            originalClass: 'project-edit-image',
                                            thumbnail: image.link,
                                            thumbnailClass: 'project-edit-thumbnail',
                                        };
                            });
                
        return(
            <div className='carousel-container'>
               <ImageGallery showPlayButton={false} 
               showFullscreenButton={true} 
               items={arrImages} 
               />
            </div>
        );
    }
}

export default TopSlider;
import React, { Component }  from 'react';
import Dropzone from 'react-dropzone';
import './FileUploadSlider.css';
import { getDataFromServerWithHeader } from './../DataProvider';
import config from './../config';

export default class FileUpload extends Component{
    constructor(props){
        super(props);        
        this.onDrop = this.onDrop.bind(this);        
    }

    onDrop(files){
       console.log('file droped: ', files);      
       let name = files[0].name;       
       const fdata = new FormData();
       fdata.append('picture', files[0]);
       getDataFromServerWithHeader(config.params.server_path + '/save_slider_image', fdata, { 'Content-Type': 'multipart/form-data' })
       .then(response => {        
            if(response.data.success === true){
                if(this.props.imageType === 'desktop'){
                    this.props.handleAddImageDrop(name);
                }
                if(this.props.imageType === 'mobile'){
                    this.props.handleUpdateImageDrop(this.props.imageId, name);
                }                
            }            
            console.log("FileUpload.onDrop => ", response.data.data);
            this.props.handleSaveRespoceImageDrop(null, response);
        })
        .catch(err => {
            console.log("FileUpload.onDrop => error: ", err);            
            this.props.handleSaveRespoceImageDrop(err, null);
        });
    }    

    render(){    
        return(        
            <Dropzone className="upload-file-contaner" onDrop={this.onDrop} multiple={false} >
                <div><span className="glyphicon glyphicon-open upload-file-span"></span></div>
                <div>Upload slider image.</div>
            </Dropzone>
        );
  }
}

const initialDialogtState = {
  user: {},
  forgetPassword: false,
  userProperty: null,
  resetPassword: false,
  verificationCode: '',
};

export default function shoppingCart(state = initialDialogtState, action){
    switch(action.type){
        case "SET_USER":
          return {...state, user: action.user};
        case "USER_FORGET_PASSWORD":
          return {...state, forgetPassword: action.forgetPassword};
        case "SET_USER_PROPERTY":
          return {...state, userProperty: action.userProperty};
        case "UPDATE_USER_PROPERTY":       
          return {...state, userProperty: {...state.userProperty, [action.userPropertyKey]: action.userPropertyValue}};
        case "SET_USER_RESET_PASSWORD":
          return {...state, resetPassword: action.resetPassword};
        case "SET_USER_VERIFICATION_CODE":
          return {...state, verificationCode: action.verificationCode};
        default:
          return state;
    }   
}
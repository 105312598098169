const initialAlertState = {
    messageObj: {},
  };
  
  export default function conatactUs(state = initialAlertState, action){
      switch(action.type){
          case "CONATACTUS_SET_DATA":
            return {...state, messageObj: {...state.messageObj, [action.constactUsKey]: action.constactUsValue}};
          default:
            return state;
      }    
  }
import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import './SortColor.css';
import AdminData from './admin/AdminData';

function SortColor(props){
    let { sortColorObj } = props;
    console.log("SortColor props: ", sortColorObj, props);
    return(
        <Menu
        id='ulSortColor'
        anchorEl={sortColorObj.anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: -40, horizontal: 10 }}
        open={sortColorObj.sortColorIsOpen}
        onClose={sortColorObj.handleSortColorMenuClose}
      >
      {AdminData.Colors.map((color, index) => 
          <MenuItem key={index} onClick={(node) => sortColorObj.handleSortColorClose(node, color)}>
          <div className='div-sort-color' style={{'backgroundColor': color}}></div>
          <span className='span-color-name'>{color}</span>
        </MenuItem>
      )}
      </Menu>
    )
}

export default SortColor;
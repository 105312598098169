import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import $ from 'jquery';
import config from '../config';
import * as actionFun from './../actions';
import store from './../store';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getDataFromServer } from './../DataProvider';
import SnackbarsCustom from './SnackbarsCustom';
import ShoppingCart from '@material-ui/icons/ShoppingCart';

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },

  button: {
    margin: theme.spacing.unit,
    "min-width": "140px",
    "font-size": "14px"
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  rightIcon: {
    marginLeft: theme.spacing.unit,
  },

  iconSmall: {
    fontSize: 20,
  },
  userPropertyContainer: {
    margin: 'auto',
    backgroundColor: '#f8f8f8',
    padding: '80px',
    border: 'solid 1px #ccc',
    minWidth: '720px',
    borderRadius: '3px',
  },
  btnSaveDiv: {
    marginTop: "20px",
  },
  inputTextStyle: {
    height: "0px;" 
  },
  userPropertyMainContainer: {
    textAlign: 'center',
    marginTop: '20px',
  },
  textfield: {    
    width: '500px !important',
    fontSize: '15px',    
  },
  loadingProjectWaitNone: {
    display: 'none'
  },

});

 class ShoppingCartUserProperty extends Component{
    componentDidMount(){
        const {payPage, user} = this.props;
        if(payPage && user.properties !== undefined){            
            store.dispatch(actionFun.setShoppingCartUserDetails(user.properties[0]));
        }
    }

    onSaveClick = (e) => {
        e.preventDefault();
        let arrNotValidData = [], {shoppingCartUserDetails} = this.props;        
        
        if(shoppingCartUserDetails !== null 
            && (shoppingCartUserDetails.receiver_name === undefined || shoppingCartUserDetails.receiver_name === '')){
            this.handlerNotValidField('txtUserPropertyReceiverName');
            arrNotValidData.push('Receiver Name');
        }

        if(shoppingCartUserDetails !== null 
            && (shoppingCartUserDetails.country === undefined || shoppingCartUserDetails.country === '')){
            this.handlerNotValidField('txtUserPropertyCountry');
            arrNotValidData.push('Country');
        }

        if(shoppingCartUserDetails !== null 
            && (shoppingCartUserDetails.street_home_flat === undefined || shoppingCartUserDetails.street_home_flat === '')){
            this.handlerNotValidField('txtUserPropertyStreetHomeFlat');
            arrNotValidData.push('Street / Home / Flat');
        }

        if(shoppingCartUserDetails !== null 
            && (shoppingCartUserDetails.region === undefined || shoppingCartUserDetails.region === '')){
            this.handlerNotValidField('txtUserPropertyRegion');
            arrNotValidData.push('Region');
        }

        if(shoppingCartUserDetails !== null 
            && (shoppingCartUserDetails.city === undefined || shoppingCartUserDetails.city === '')){
            this.handlerNotValidField('txtUserPropertyCity');
            arrNotValidData.push('City');
        }

        if(shoppingCartUserDetails !== null 
            && (shoppingCartUserDetails.zip_code === undefined || shoppingCartUserDetails.zip_code === '')){
            this.handlerNotValidField('txtUserPropertyZipCode');
            arrNotValidData.push('Zip code');
        }

        if(shoppingCartUserDetails !== null 
            && (shoppingCartUserDetails.telephone === undefined || shoppingCartUserDetails.telephone === '')){
            this.handlerNotValidField('txtUserPropertyTelephone');
            arrNotValidData.push('Telephone');
        }       

        if(arrNotValidData.length === 0){            
            this.handleSaveOrders();
        }
    }
    
    handlerNotValidField = (id) => {
        $("#" + id).addClass("validate-error");
        $("#" + id).on('focus', function(){           
          $("#" + id).removeClass("validate-error");
        });
    }    

    handleSaveOrders = () => {
        let alert={}, orders, {shoppingCartItems, buyNowItem, buyNow} = this.props;
        orders = buyNow ? [buyNowItem] : shoppingCartItems;
        $("#divWaitShoppingCartUserProperty").removeClass("wait-dialog-none").addClass("wait-dialog"); 
        getDataFromServer(config.params.server_path + '/new_order', {orders: orders, 
            shippingAddress: this.props.shoppingCartUserDetails})
        .then(response => {                   
            if(response.data.success === true){
                // alert = {text: 'Send orders success.', variant: 'success', show: true, event: this.onAlertClose };
                // if(!buyNow){
                //     store.dispatch(actionFun.clearShoppingCart());
                //     store.dispatch(actionFun.setCountShoppingCartItems(0));
                // }else{
                //     store.dispatch(actionFun.setBuyNowItem({}, false));
                // }                
                window.location.href = response.data.data;              
            }else{
                alert = {text: 'Faild send order, error: ' + response.data.text, variant: 'error', show: true, event: this.onAlertClose };
            }
            console.log("ShoppingCartUserProperty.handleSaveOrders => ", response.data);
            store.dispatch(actionFun.showAlert(alert));
        })
        .catch(err => {
            console.log("ShoppingCartUserProperty.handleSaveOrders => error: ", err);
            alert = {text: 'Faild send order, error: ' + err, variant: 'error', show: true, event: this.onAlertClose };
            store.dispatch(actionFun.showAlert(alert));
        });
    }    

    onTextChange = (e) => {
        store.dispatch(actionFun.updateShoppingCartUserDetails(e.target.id, e.target.value));
    }

    onAlertClose = () => {
        let alert = {text: '', variant: 'success', show: false, event: ''};
        store.dispatch(actionFun.showAlert(alert));
    }
    
    onAddToCart = () => {
        let prodId = this.props.buyNowItem.product._id, itemExists = false, {buyNowItem} = this.props;
            console.log("ProductEdit -> addToShoppingCart: ", this.props);
            this.props.shoppingCartItems.map((item, i) => {
                let selOpt = item.selectedOptions;
                if(item.product._id === prodId 
                   && selOpt.color === buyNowItem.selectedOptions.color
                   && selOpt.shape === buyNowItem.selectedOptions.shape
                   && selOpt.size === buyNowItem.selectedOptions.size
                   && selOpt.style === buyNowItem.selectedOptions.style
                   && selOpt.material === buyNowItem.selectedOptions.material){
                   let sum = parseInt(selOpt.quantity) + parseInt(buyNowItem.selectedOptions.quantity);
                   store.dispatch(actionFun.updateShoppingCart({productId: prodId, quantity: sum}));
                   itemExists = true;
                }
            });
            if(!itemExists){                
                const shoppingCartItem = {
                    id: buyNowItem.id,
                    product: buyNowItem.product,
                    selectedOptions: buyNowItem.selectedOptions,
                    userId: buyNowItem.userId,
                    status: 'new',
                    shippingAddress: buyNowItem.shippingAddress,
                    orderDescription: buyNowItem.orderDescription,
                }
                store.dispatch(actionFun.addShoppingCart(shoppingCartItem));
                let count = parseInt(this.props.countShoppingCartItems) + 1;                 
                store.dispatch(actionFun.setCountShoppingCartItems(count));                               
                              
                let alert = {text: 'Product added to cart.', variant: 'success', show: true, event: this.onAlertClose };
                store.dispatch(actionFun.showAlert(alert));
            }

            store.dispatch(actionFun.setBuyNowItem({}, false)); 
    }

   render(){
    const { classes, alert, shoppingCartUserDetails, buyNow} = this.props;
    let isLoad = shoppingCartUserDetails !== null;
    
    return(
        <div id="divUserPropertyContainer" className={classes.userPropertyMainContainer}>
             <div id="divWaitShoppingCartUserProperty" className="wait-dialog-none">
                    <div className="wait-dialog-div-image">
                        <img alt="wait" src="/image/wait.gif"></img>
                        <span>Saving...</span>
                    </div>
             </div>
            {alert.show ? 
                <SnackbarsCustom data={alert} />
                :''
            }
            <div className={classes.userPropertyContainer} >
                <div>
                    <TextField
                        className={classNames( classes.margin, classes.textfield )}
                        id="receiver_name"
                        placeholder="Receiver Name"
                        value={isLoad && shoppingCartUserDetails.receiver_name !== undefined ? shoppingCartUserDetails.receiver_name : ''}
                        onChange={this.onTextChange}
                    />
                </div>
                <div>
                    <TextField
                        className={classNames( classes.margin, classes.textfield )}
                        id="country"
                        placeholder="Country"
                        value={isLoad && shoppingCartUserDetails.country !== undefined ? shoppingCartUserDetails.country : ''}
                        onChange={this.onTextChange}
                    />
                </div>
                <div>
                    <TextField
                        className={classNames( classes.margin, classes.textfield )}
                        id="street_home_flat"
                        placeholder="Street, house, flat"
                        value={isLoad && shoppingCartUserDetails.street_home_flat !== undefined ? shoppingCartUserDetails.street_home_flat : ''}
                        onChange={this.onTextChange}
                    />
                </div>
                <div>
                    <TextField
                        className={classNames( classes.margin, classes.textfield )}
                        id="region"
                        placeholder="Region"
                        value={isLoad && shoppingCartUserDetails.region !== undefined ? shoppingCartUserDetails.region : ''}
                        onChange={this.onTextChange}
                    />
                </div>
                <div>
                    <TextField
                        className={classNames( classes.margin, classes.textfield )}
                        id="city"
                        placeholder="City"
                        value={isLoad && shoppingCartUserDetails.city !== undefined ? shoppingCartUserDetails.city : ''}
                        onChange={this.onTextChange}
                    />
                </div>
                <div>
                    <TextField
                        className={classNames( classes.margin, classes.textfield )}
                        id="zip_code"
                        placeholder="Zip code"
                        value={isLoad && shoppingCartUserDetails.zip_code !== undefined ? shoppingCartUserDetails.zip_code : ''}
                        onChange={this.onTextChange}
                    />
                </div>
                <div>
                    <TextField
                        className={classNames( classes.margin, classes.textfield )}
                        id="telephone"
                        placeholder="Telephone"
                        value={isLoad && shoppingCartUserDetails.telephone !== undefined ? shoppingCartUserDetails.telephone : ''}
                        onChange={this.onTextChange}
                    />
                </div>
                <div id="divUserPropertySave" className={classes.btnSaveDiv}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={this.onSaveClick}>
                        Pay Order
                        <SaveIcon className={classes.leftIcon} />
                    </Button>
                    {buyNow === true ? 
                        <Button variant="contained" color="primary" className={classes.button} onClick={this.onAddToCart}>
                            Add To Cart
                            <ShoppingCart className={classes.leftIcon} />
                        </Button>
                    :''
                    }
                    
                </div>
            </div>
        </div>
    );
   }
}


ShoppingCartUserProperty.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => {
    return {
        user: store.userState.user,
        alert: store.alertState.alert,
        payPage: store.shoppingCartState.payPage,
        shoppingCartUserDetails: store.shoppingCartState.shoppingCartUserDetails,
        shoppingCartItems: store.shoppingCartState.shoppingCartItems,
        countShoppingCartItems: store.shoppingCartState.countShoppingCartItems,
        buyNowItem: store.shoppingCartState.buyNowItem,
        buyNow: store.shoppingCartState.buyNow,
    }
}

export default withRouter(connect(mapStateToProps)(withStyles(styles)(ShoppingCartUserProperty)));
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { fade } from '@material-ui/core/styles/colorManipulator';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  card: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
    textAlign: 'center',    
    [theme.breakpoints.down(1380)]: {
      paddingLeft: '10px',
      paddingRight: '0',
    },
  },
  cover: {
    width: 151,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  button: {
     background: '#000',
     'margin-top': '10px',
     '&:hover': {
       backgroundColor: fade('#efce49', 0.9) 
     },
  },
  title: {
    width: '250px',
  },
  input: {
    width: '40px',
  },
  column: {
      width: '80px',
  },
  separator: {
     width: '80%',
     marginLeft: '10%',
     marginBottom: '10px',
     borderBottom: 'solid 1px #ccc',
  },
  total: {
    paddingLeft: '20%',
  },
  totalListItem: {
    paddingBottom: '5px',
    paddingTop: '0px',
    fontWeight: 'bold',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '80%',
  },
  cartImage: {
    webkitTransform: 'scale(1)',
    transform: 'scale(1)',
    webkitTransition: '.3s ease-in-out',
    transition: '.3s ease-in-out',
    width: 'auto',
    height: 'auto',
    maxWidth: '250px',
    maxHeight: '195px',
    '&:hover':{
      webkitTransform: 'scale(1.3)',
      transform: 'scale(1.3)',
    }
  },
  figure: {
    width: '100%',
    height: '200px',
    margin: '0',
    padding: '0',
    background: '#fff',
    overflow: 'hidden',
  }, 
  divImageContaner:{
    width: 250,
    textAlign: 'center',
  }
});

class ShoppingCatItem extends Component {
  constructor(props){
    super(props);
    this.state = {orderDescription: '', orderQuantity: ''};
  }

  onDescriprionChange = (e) => {
    this.setState({orderDescription: e.target.value}); 
    this.props.onDescriprionChange(e, this.props.shoppingCatItem.product._id);
  }

  onTextChange = (e) => {    
    //if(e.target.value !== '' && isNaN(parseInt(e.target.value)) === false && e.target.value !== '0'){
      this.setState({orderQuantity: e.target.value});
      this.props.onShoppingCartQuantityChange(e, this.props.shoppingCatItem.product._id);
    //}    
  }

  render(){
    const { classes, shoppingCatItem } = this.props;
    const product = shoppingCatItem.product;
    const selectedOptions = shoppingCatItem.selectedOptions;
    let shipping = product.shippingCost !== undefined ? parseFloat(product.shippingCost) : 0;
    shipping *= parseInt(selectedOptions.quantity);
    console.log("shopping cart item: ", shoppingCatItem);
    
    const arrPrice = product.price.toString().split('.');
    const price1 = arrPrice[0];
    const price2 = arrPrice.length > 1 ? '.' + arrPrice[1] : '';
    const subtotal = parseFloat(product.price) * parseInt(selectedOptions.quantity);
    const total = subtotal + shipping;   

    return (
      <Card className={classes.card}>        
        <div className={classes.divImageContaner}>
          <figure className={classes.figure}>
            <img className={classes.cartImage} src={product.images[0].link} title={ product.images[0].title } alt={ product.images[0].title } />
          </figure>
        </div>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography component="h6" variant="h6" className={classes.title}>
                { product.title }
              </Typography>
              <Typography variant="subtitle" color="textSecondary">
              
              </Typography>
            </CardContent>
          </div>
          <div>
            <CardContent className={classes.content}>
              <Typography component="h6" variant="h6" className={classes.column}>
                Shape
              </Typography>
              <Typography variant="subtitle" color="textSecondary">
                { selectedOptions.shape }
              </Typography>
            </CardContent>
          </div>
          <div>
            <CardContent className={classes.content}>
              <Typography component="h6" variant="h6" className={classes.column}>
                Quantity
              </Typography>
              <input type='text' className={classes.input} 
                value={ isNaN(selectedOptions.quantity) ? '' : selectedOptions.quantity } 
                onChange={(node) => {this.onTextChange(node);}}>
              </input>
            </CardContent>
          </div>
          <div>
            <CardContent className={classes.content}>
              <Typography component="h6" variant="h6" className={classes.column}>
              Color
              </Typography>
              <Typography variant="subtitle" color="textSecondary">
                { selectedOptions.color }
              </Typography>
            </CardContent>
          </div>
          <div>
            <CardContent className={classes.content}>
              <Typography component="h6" variant="h6" className={classes.column}>
              Material
              </Typography>
              <Typography variant="subtitle" color="textSecondary">
                { selectedOptions.material }
              </Typography>
            </CardContent>
          </div>
          <div>
            <CardContent className={classes.content}>
              <Typography component="h6" variant="h6" className={classes.column}>
              Style
              </Typography>
              <Typography variant="subtitle" color="textSecondary">
                { selectedOptions.style }
              </Typography>
            </CardContent>
          </div>
          <div>
            <CardContent className={classes.content}>
              <Typography component="h6" variant="h6" className={classes.column}>
              Size
              </Typography>
              <Typography variant="subtitle" color="textSecondary">
                { selectedOptions.size }
              </Typography>
            </CardContent>
          </div>     
          <div>
            <CardContent className={classes.content}>
              <Typography component="h6" variant="h6" className={classes.column}>
                Price
              </Typography>
              <Typography variant="subtitle" color="textSecondary" style={{'text-align': 'center'}}>
                <span style={{'font-size': '16px'}}>$</span>
                <span style={{'font-size': '24px', 'font-weight': 'bold'}}>{price1}</span>
                <span style={{'font-size': '16px'}}>{price2}</span>
              </Typography>
            </CardContent>
          </div>
          <div style={{ flex: 1 }}>
            <CardContent className={classes.content}>
              <Button variant="contained" color="primary" className={classes.button} onClick={() => this.props.removeItem(shoppingCatItem.id)}>
                Remove
              </Button>
            </CardContent>
          </div>
          <div className={classes.separator}>&nbsp;</div>
          <Grid container spacing={16}>
            <Grid item xs={9} md={9}>
              <TextField
                id="orderDescription"
                label="Order Notes"
                multiline
                rowsMax="4"
                value={shoppingCatItem.orderDescription}
                onChange={(node) => {this.onDescriprionChange(node);}}
                className={classes.textField}
                margin="normal"              
                variant="outlined"
                disabled   
              />
            </Grid>
            <Grid item xs={3} md={3}>
              <List className={classes.total}>
                <ListItem className={classes.totalListItem}>Subtotal: ${isNaN(subtotal) ? 0 : subtotal }</ListItem>
                <ListItem className={classes.totalListItem}>Shipping: ${isNaN(shipping) ? 0 : shipping}</ListItem>
                <ListItem className={classes.totalListItem}>Total: ${isNaN(total) ? 0 : total}</ListItem>
              </List>
            </Grid>
          </Grid>      
      </Card>
    );
  }
}

ShoppingCatItem.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ShoppingCatItem);


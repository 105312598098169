
const initialProductState = {
  products: [], 
  sortPrice: '',
  sortStyle: '',
  sortMaterial: '', 
  activeTab: 0, 
  searchText: '', 
  anchorEl: null,
  sortShapeEl: null,
  sortColorIsOpen: false,
  sortShapeIsOpen: false,
  sortColor: '',
  sortShape: '',
  editProduct: null,
  editProductColor: '',
  editProductShape: '',
  editProductStyle: '',
  editProductSize: '',
  editProductMaterial: '',
  editProductNotes: '',
  fromMainPage: false,
  showSocialButtons: false,
  showSocialProductId: '',
};

export default function products(state = initialProductState, action){    
    switch(action.type){
        case "GET_PRODUCTS":
          return {...state, products: action.products};
        case "SORT_PRODUCT_PRICE":
          return {...state, sortPrice: action.sortPrice};
        case "SORT_PRODUCT_STYLE":
          return {...state, sortStyle: action.sortStyle};
        case "SORT_PRODUCT_MATERIAL":
          return {...state, sortMaterial: action.sortMaterial};
        case "CHANGE_TAB":
          return {...state, activeTab: action.tab};
        case "SEARCH_PRODUCT":
          return {...state, searchText: action.searchText};
        case "SORT_COLOR_IS_OPEN":
          return {...state, sortColorIsOpen: action.sortColorIsOpen, anchorEl: action.anchorEl};
        case "SORT_SHAPE_IS_OPEN":
          return {...state, sortShapeIsOpen: action.sortShapeIsOpen, sortShapeEl: action.sortShapeEl};
        case "GET_PRODUCT_BY_ID":
          return {...state, editProduct: action.editProduct};
        case "SET_PRODUCT_EDIT_COLOR":
          return {...state, editProductColor: action.editProductColor};
        case "SET_PRODUCT_EDIT_SHAPE":
          return {...state, editProductShape: action.editProductShape};
        case "SET_PRODUCT_EDIT_STYLE":
          return {...state, editProductStyle: action.editProductStyle};
        case "SET_PRODUCT_EDIT_SIZE":
          return {...state, editProductSize: action.editProductSize};
        case "SET_PRODUCT_EDIT_MATERIAL":
          return {...state, editProductMaterial: action.editProductMaterial};
        case "SORT_PRODUCT_SHAPE":
          return {...state, sortShape: action.sortShape};
        case "SORT_PRODUCT_COLOR":
          return {...state, sortColor: action.sortColor};
        case "SET_PRODUCT_EDIT_NOTES":
          return {...state, editProductNotes: action.editProductNotes};
        case "SET_FROM_MAIN_PAGE":
          return {...state, fromMainPage: action.fromMainPage};
        case "SHOW_SOCIAL_BUTTONS":
          return {...state, showSocialButtons: action.showSocialButtons, showSocialProductId: action.productID};
        default:
          return state;
    }    
    
}
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import  './UserSignUp.css';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import $ from 'jquery';
import axios from 'axios';
import config from '../config';
import * as actionFun from './../actions';
import store from './../store';
import { connect } from 'react-redux';
import SnackbarsCustom from './SnackbarsCustom';

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },

  button: {
    margin: theme.spacing.unit,
    "min-width": "140px",
    "font-size": "14px"
  },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },

  rightIcon: {
    marginLeft: theme.spacing.unit,
  },

  iconSmall: {
    fontSize: 20,
  },
});

 class UserSignUp extends Component{
   constructor(props){
       super(props);
       
       this.newUserHandler = this.newUserHandler.bind(this);
       this.isValidEmail = this.isValidEmail.bind(this);
       this.showAlert = this.showAlert.bind(this);
   }

   newUserHandler(e){

       let arrValid = [];
       if($("#txtUserSignUpName").val() === ''){
           arrValid.push("#txtUserSignUpName");
       }      
       if($("#txtUserSignUpPassword").val() === ''){
           arrValid.push("#txtUserSignUpPassword");
       }
       if($("#txtUserSignUpEmail").val() === '' || !this.isValidEmail($("#txtUserSignUpEmail").val())){
           arrValid.push("#txtUserSignUpEmail");
       }

       console.log("newUserHandler -> no valid values lenght: ", arrValid.length)
       if(arrValid.length === 0){
           let userObj = {
                name: $("#txtUserSignUpName").val(),                
                password: $("#txtUserSignUpPassword").val(),
                email: $("#txtUserSignUpEmail").val()  
            }
         let alert={};
         axios.post(config.params.server_path + "/new_user", userObj)
         .then(res => {  
           console.log("newUserHandler -> save user result: ", res.data !== undefined ? res.data : "no data");
           if(res.data.success === true){
                alert = {text: 'Create new user success.', variant: 'success', show: true, event: this.onAlertClose };
                this.props.history.push('/login');
                store.dispatch(actionFun.setUser(res.data.user));
           }else if(res.data.success === false){
                alert = {text: 'Faild create new user, error: ' + res.data.message, variant: 'error', show: true, event: this.onAlertClose }
           }
           store.dispatch(actionFun.showAlert(alert));
        })
         .catch(err => { 
             console.log("newUserHandler -> err save user: ", err);
             alert = {text: 'Faild create new user, error: ' + err, variant: 'error', show: true, event: this.onAlertClose };
             store.dispatch(actionFun.showAlert(alert));
            });

       }else{
           arrValid.map((v, i) => {
               console.log("set not valid class: " , v)
                $(v).addClass("validate-error");
                $(v).on('focus', () => {
                    console.log("focus !!!!");
                    $(v).removeClass("validate-error");
                });
            })
       }

       console.log("newUserHandler -> save user form");
   }

   isValidEmail(email) {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        return pattern.test(email);
    }
   
    showAlert(){
        this.setState({showAlert: true});
        setTimeout(() => { this.setState({showAlert: false, alertClass: 'success'}); }, 3000);
    }
    
    onAlertClose = () => {
        let alert = {text: '', variant: 'success', show: false, event: ''};
        store.dispatch(actionFun.showAlert(alert));
    }

   render(){

    const { classes, alert } = this.props;
    const inputTextStyle = {"height": "0px;"};
    return(
        <div id="divUserSignUpContainer" className="container">
            {alert.show ? 
                <SnackbarsCustom data={alert} />
                :''
            }
            <div className="signup-container">
            <div>
            <TextField
                className={classNames( classes.margin, classes.textfield )}
                style={inputTextStyle}
                id="txtUserSignUpName"
                placeholder="Name"
            />
            </div>
            <div>
            <TextField
                className={classNames( classes.margin, classes.textfield )}
                style={inputTextStyle}
                id="txtUserSignUpEmail"
                type="email"
                placeholder="Email"
            />
            </div>
            <div>
            <TextField
                className={classes.margin}
                id="txtUserSignUpPassword"
                type="password"
                placeholder="Password"
            />
            </div>            
            <div id="divUserSignUpSave" className='div-user-save-button'>
                <Button variant="contained" color="primary" className={classes.button} onClick={this.newUserHandler}>
                    Save
                    <SaveIcon className={classes.rightIcon} />
                </Button>
            </div>
            </div>
        </div>
    );
   }
}


UserSignUp.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => {
    return {        
        alert: store.alertState.alert,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(UserSignUp));

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import Button from '@material-ui/core/Button';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Link } from 'react-router-dom';

const styles = theme => ({
  card: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  button: {
     background: '#000',
     'margin-top': '10px',
     '&:hover': {
       backgroundColor: fade('#efce49', 0.9) 
     },
  },
  title: {
    width: '350px',
  },
  cartImage: {
    webkitTransform: 'scale(1)',
    transform: 'scale(1)',
    webkitTransition: '.3s ease-in-out',
    transition: '.3s ease-in-out',
    width: 'auto',
    height: 'auto',
    maxWidth: '250px',
    maxHeight: '195px',
    '&:hover':{
      webkitTransform: 'scale(1.3)',
      transform: 'scale(1.3)',
    }
  },
  figure: {
    width: '100%',
    height: '200px',
    margin: '0',
    padding: '0',
    background: '#fff',
    overflow: 'hidden',
  },
  column: {
      width: '170px',
  },
  divImageContaner:{
    width: 250,
    textAlign: 'center',
  }
});

function HeadlineCart(props) {
  const { classes, product } = props;
  
  const arrColor = ['red', 'blue', 'black', 'grey', 'green', 'yellow'];
  const arrShapes = ['triangle', 'square', 'circle'];
  let colors = arrColor.join(', ');
  let shapes = arrShapes.join(', ');
  const arrPrice = product.price.toString().split('.');
  const price1 = arrPrice[0];
  const price2 = arrPrice.length > 1 ? '.' + arrPrice[1] : '';

  return (
    <Card className={classes.card}>
      <Link to={'/product/' + product._id} style={{textDecoration: 'none'}}>
       <div className={classes.divImageContaner}>
          <figure className={classes.figure}>
            <img className={classes.cartImage} src={product.images[0].link} title={ product.images[0].title } alt={ product.images[0].title } />
          </figure>
        </div>
      </Link>
      <div className={classes.details}>
        <CardContent className={classes.content}>
        <Link to={'/product/' + product._id} style={{textDecoration: 'none'}}>
          <Typography component="h5" variant="h5" className={classes.title}>
            { product.title }
          </Typography>
          </Link>
          <Typography variant="subtitle1" color="textSecondary">            
          </Typography>
        </CardContent>
      </div>
      <div>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5" className={classes.column}>
            Colors
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" className={classes.column}>
            { colors }
          </Typography>
        </CardContent>
      </div>
      <div>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5" className={classes.column}>
           Shapes
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" className={classes.column}>
            { shapes }
          </Typography>
        </CardContent>
      </div>      
      <div>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5" className={classes.column}>
            Price
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            <span style={{'font-size': '16px'}}>$</span>
            <span style={{'font-size': '24px', 'font-weight': 'bold'}}>{price1}</span>
            <span style={{'font-size': '16px'}}>{price2}</span>
          </Typography>
        </CardContent>
      </div>
      <div style={{ display: 'none', flex: 1 }}>
        <CardContent className={classes.content}>
          <Button variant="contained" color="primary" className={classes.button}>
            Add to Card
            <ShoppingCart className={classes.rightIcon}>send</ShoppingCart>
        </Button>
        </CardContent>
      </div>
    </Card>
  );
}

HeadlineCart.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(HeadlineCart);
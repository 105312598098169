import React, { Component } from 'react';
import './ContactUs.css';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { getDataFromServer } from './../DataProvider';
import SnackbarsCustom from './SnackbarsCustom';
import Dialog from './Dialog';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import config from './../config';
import store from './../store';
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';
import { constactUsSetData, showDialog, showAlert } from './../actions';

const successAlert = "The request was send success.";
const errorAlert = "The request was failed to send.";

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: '20px 80px 80px 80px',
        maxWidth: '70%',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: '#f8f8f8',
        border: "solid 1px #ccc",
        borderRadius: '3px',
        marginTop: '12%',
    },
    gridItem:{
        display: 'inline-flex' 
    },
    textfield: {    
        width: '80%',
        fontSize: '15px',
    },
    margin: {
        margin: theme.spacing.unit,
    },
    labelSpan:{ 
        marginTop: '13px',
        fontSize: '17px',
        width: '150px',
    },
    button: {
        marginTop: '20px',
    },
    buttonGrid:{
        justifyContent: 'flex-end',
    },

})

class ContactUs extends Component{    

    handlSend = (e) => {
        let arrNotValid = [], 
        nameIn = this.props.messageObj.name !== undefined ? this.props.messageObj.name : '', 
        emailIn = this.props.messageObj.email !== undefined ? this.props.messageObj.email : '', 
        textIn = this.props.messageObj.message !== undefined ? this.props.messageObj.message : '' ;

        if(nameIn === ''){
            arrNotValid.push('Name');
        }
        if(emailIn === ''){
            arrNotValid.push('Email');
        }
        if(textIn === ''){
            arrNotValid.push('Message');
        }
        if(arrNotValid.length > 0){
            let text = 'The required fields: "' + arrNotValid.join(', ') + '" not filled !'; 
            let dialogData = {title: 'Warning', text: text, buttons: [], show: true};
            store.dispatch(showDialog(dialogData));            
        }else{
            if(!this.isValidEmail(emailIn)){
                let text = 'The email is not valid !';
                let dialogData = {title: 'Warning', text: text, buttons: [], show: true};
                store.dispatch(showDialog(dialogData));
            }else{
                    $("#divContactUsWait").removeClass("loading-contactus-wait-none").addClass("loading-contactus-wait");
                    let alert = {};
                    getDataFromServer(config.params.server_path + "/contact_us", {name: nameIn, email: emailIn, text: textIn})
                    .then(data => { 
                        console.log("ContactUs handlSend -> send contact us result: ", data.data != undefined ? data.data : "no data");
                        $("#divContactUsWait").removeClass("loading-contactus-wait").addClass("loading-contactus-wait-none");
                        if(data.data.success === true){
                            alert = {text: 'Send message success.', variant: 'success', show: true, event: this.onAlertClose }
                        }else{                
                            alert = {text: 'Faild send message, error: ' + data.data.text, variant: 'error', show: true, event: this.onAlertClose }
                        }
                        console.log("ContactUs handlSend => ", data);
                        store.dispatch(showAlert(alert));                       
                    })
                    .catch(err => {           
                        console.log("ContactUs handlSend -> err send contact us: ", err);
                        alert = {text: 'Faild send message, error: ' + err, variant: 'error', show: true, event: this.onAlertClose }
                        store.dispatch(showAlert(alert));
                    });
            }
        }        
    }   

    isValidEmail = (email) => {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        return pattern.test(email);
    }

    onTextChange = (e) => {
        store.dispatch(constactUsSetData(e.target.id, e.target.value));
    }

    handlCloseDialog = (show) => {
        let dialogData = { title: '', text: '', buttons: [], show: false }
        store.dispatch(showDialog(dialogData));
    }

    onAlertClose = () => {
        let alert = {text: '', variant: 'success', show: false, event: ''};
        store.dispatch(showAlert(alert));
    }

    render(){
        let {classes, dialog, alert, messageObj} = this.props;
        return(
            <div>
                <div id="divContactUsWait" className="loading-contactus-wait-none">
                    <div className="wait-dialog-div-image">
                        <img alt="wait" src="/image/wait.gif"></img>
                        <span>Saving...</span>
                    </div>
                </div>
                <div className={classes.root}>
                    {alert.show ? 
                        <SnackbarsCustom data={alert} />
                        :''
                    }
                    <Dialog dialogData={ dialog } handlDialog={this.handlCloseDialog}/>
                    <Grid container spacing={8} className={classes.rowGrid}>
                        <Grid item sm={12} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Name:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="name"
                                placeholder="Name"
                                value= {messageObj.name !== undefined ? messageObj.name : ''}
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>
                        <Grid item sm={12} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Email:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="email"
                                placeholder="email"
                                value= {messageObj.email !== undefined ? messageObj.email : ''}                                                            
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>
                        <Grid item sm={12} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Message:</span>                       
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="message"
                                placeholder="Your Advice/Question"                                                            
                                onChange={(node) => this.onTextChange(node)}
                                multiline
                                rowsMax="6"
                                value= {messageObj.message !== undefined ? messageObj.message : ''}
                            />           
                        </Grid>
                        <Grid item sm={12} className={classNames(classes.gridItem, classes.buttonGrid)}>
                            <Button variant="contained" color="primary" className={classes.button} onClick={(node) => {this.handlSend();}}>
                                Send
                                <SendIcon className={classes.rightIcon} />
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}

ContactUs.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (store) => {
      return {
          user: store.userState.user,          
          dialog: store.dialogState.dialog,
          alert: store.alertState.alert,
          messageObj: store.contactUsState.messageObj,         
      }
  }

export default connect(mapStateToProps)(withStyles(styles)(ContactUs));



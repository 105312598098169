import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import config from './../config';
import {
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    PinterestShareButton,
    FacebookIcon,
    WhatsappIcon,
    TwitterIcon,
    PinterestIcon,


} from 'react-share';

const styles = theme => ({
    socialButtonsFlexContainer:{
        display: 'flex',
        flexWrap: 'nowrap',
        position: 'absolute',
        width: '167px',
        height: '40px',
        zIndex: 10,
        backgroundColor: '#ccc',
        marginTop: -47,
        marginLeft: 100,
        paddingTop: 10,
        paddingLeft: 10,
        borderRadius: 20,
    },
    socialButtonDiv:{
        marginRight: 10,
    },
    someNetworkShareButton:{
        cursor: 'pointer',
    }   
})

class SocialButtons extends React.Component{
    render(){
        let {classes, productId, name, description} = this.props;
        let shareUrl = config.params.server_path + '/product/' + productId, title = name + ': ' + description;
         
        return(
            <div className={classes.socialButtonsFlexContainer}>
                <div className={classes.socialButtonDiv}>
                    <FacebookShareButton url={shareUrl} quote={title} className={classes.someNetworkShareButton}>
                        <FacebookIcon size={32} round />
                    </FacebookShareButton>
                </div>
                <div className={classes.socialButtonDiv}>
                    <WhatsappShareButton url={shareUrl} quote={title} className={classes.someNetworkShareButton}>
                        <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                </div>
                <div className={classes.socialButtonDiv}>
                    <TwitterShareButton url={shareUrl} quote={title} className={classes.someNetworkShareButton}>
                        <TwitterIcon size={32} round />
                    </TwitterShareButton>
                </div>
                <div className={classes.socialButtonDiv}>
                    <PinterestShareButton url={shareUrl} quote={title} className={classes.someNetworkShareButton}>
                        <PinterestIcon size={32} round />
                    </PinterestShareButton>
                </div>
            </div>
        );
    }
}

SocialButtons.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(SocialButtons);

import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {setFromPayPage} from './../actions';
import store from './../store';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import DoneOutline from '@material-ui/icons/DoneOutline';
import { getDataFromServer } from './../DataProvider';
import config from './../config';
import $ from 'jquery';

const styles = theme => ({
    margin: {
      margin: theme.spacing.unit,
    },
  
    button: {
      margin: theme.spacing.unit,
      "min-width": "140px",
      "font-size": "14px",
      display: 'none',
    },
  
    leftIcon: {
      marginRight: theme.spacing.unit,
    },
  
    rightIcon: {
      marginLeft: theme.spacing.unit,
    },
  
    iconSmall: {
      fontSize: 20,
    },
    userPropertyContainer: {
      margin: 'auto',
      backgroundColor: '#f8f8f8',
      padding: '80px',
      border: 'solid 1px #ccc',
      minWidth: '720px',
      borderRadius: '3px',
    },
    btnSaveDiv: {
      marginTop: "20px",
    },
    inputTextStyle: {
      height: "0px;" 
    },
    userPropertyMainContainer: {
      textAlign: 'center',
      marginTop: '20px',
    },
    textfield: {    
      width: '500px !important',
      fontSize: '15px',    
    },
    divPaymentComplete: {
      marginLeft:'auto',
      marginRight: 'auto',
      marginTop: '10%',
      width: '50%',
      backgroundColor: '#ccc',
      textAlign: 'center',
      minHeight: '100px',
      height: 100,
      borderRadius: 10,
      padding: 20,
      fontSize: 24,
      color: 'green',
      [theme.breakpoints.down('600')]: {        
        width: '90%',
        padding: 5,
        height: 150,        
      },
    },
    divTransactionID: {
      marginTop: 20,
    },
    spanTransactionID:{
      fontWeight: 'bold',
    },
    divIconDone:{
      position: 'absolute',
      zIndex: 1000,
      marginTop: '-75px',
      marginLeft: '50px',
      [theme.breakpoints.down('1400')]: {
        marginLeft: '0px',
      },
      [theme.breakpoints.down('1200')]: {
        display: 'none',
      },
    },
    iconDone:{
      fontSize: 96,
    },
  });

class Pay extends Component{
    constructor(props){
      super(props);
      this.state = {payStatus: '', payTransaction: ''};
    }
    onContinueClick = () =>{
      getDataFromServer(config.params.server_path + '/pay_order', {orders: this.props.shoppingCartItems})
      .then(response => {        
        if(response.data.success === true){
          window.location.href = response.data.data; 
        }
        console.log("Pay.onContinueClick => ", response.data.data);
      })
      .catch(err => {console.log("Pay.onContinueClick => error: ", err)});
      //store.dispatch(setFromPayPage(true));
      //this.props.history.push('/shopping-address');
    }

    componentDidMount(){
      if(this.props.payObj !== undefined){
        let payObject = this.props.payObj;
        $("#divWaitShoppingCartPay").removeClass("wait-dialog-none").addClass("wait-dialog");        
        getDataFromServer(config.params.server_path + '/pay_execute', {payObj: payObject})
        .then(response => {
          console.log("Pay.componentDidMount => ", response.data);
          if(response.data.success === true){
            let transactionID = response.data.data.transactions[0].related_resources[0].sale.id;
            let state = response.data.data.transactions[0].related_resources[0].sale.state;
            this.setState({payStatus: state, payTransaction: transactionID});
            $("#divWaitShoppingCartPay").removeClass("wait-dialog").addClass("wait-dialog-none");
          }          
        })
      .catch(err => {console.log("Pay.componentDidMount => error: ", err)});
      }
    }

    render(){
        let {classes} = this.props;
        return(
          <div>
              <div id="divWaitShoppingCartPay" className="wait-dialog-none">
                    <div className="wait-dialog-div-image">
                        <img alt="wait" src="/image/wait.gif"></img>
                        <span>Saving...</span>
                    </div>
             </div>
              <div className={classes.divPaymentComplete}>
                <div>
                    Payment {this.state.payStatus}!
                </div>
                <div className={classes.divTransactionID}>
                    Transaction ID: <span className={classes.spanTransactionID}>{this.state.payTransaction}</span> 
                </div>
                <div className={classes.divIconDone}>
                  <DoneOutline className={classes.iconDone}/>
                </div>
              </div>
              <Button variant="contained" color="primary" className={classes.button} onClick={this.onContinueClick}>
                  Continue pay
                  <SaveIcon className={classes.leftIcon} />
              </Button>            
          </div>
        );
    }
}

Pay.propTypes = {
    classes: PropTypes.object.isRequired,
  };

const mapStateToProps = (store) => {
    return {
        user: store.userState.user,
        alert: store.alertState.alert,
        shoppingCartItems: store.shoppingCartState.shoppingCartItems,
    }
}

export default withRouter(connect(mapStateToProps)(withStyles(styles)(Pay)));
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { getAdminEditOrder, updateAdminEditOrder, showAlert } from './../../actions';
import { getDataFromServer } from './../../DataProvider';
import config from './../../config';
import store from './../../store';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import SelectedListGeneral from './../SelectedListGeneral';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import SnackbarsCustom from './../SnackbarsCustom';
import adminData from './AdminData';
import SaveIcon from '@material-ui/icons/Save';



const styles = theme => ({
    root: {
      flexGrow: 1,
      padding: '20px 80px 80px 80px',
      maxWidth: '70%',
      marginLeft: 'auto',
      marginRight: 'auto',      
    },
    tabs: {
        backgroundColor: theme.palette.common.white,
     },
     tabTitle: {
        color: '#000',
     },
     paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
      paperLink: {
        display: 'block',
        width: '100%',
        cursor: 'pointer',
      },
      textfield: {    
        width: '80%',
        fontSize: '15px',
      },
      margin: {
        margin: theme.spacing.unit,
      },
      labelSpan:{ 
        marginTop: '13px',
        fontSize: '17px',
        width: '150px',
      },
      gridItem:{
        display: 'inline-flex' 
      },
      separatorGrid:{
        marginTop: 20,
        marginBottom: 10,
        textAlign: 'center',
        borderBottom: 'solid 1px #ccc',
        fontWeight: 'bold',
      },
      buttonSave: {
        margin: theme.spacing.unit,
        minWidth: "140px",
        fontSize: "14px",
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      selectStatus:{
        width: '100%',
        marginTop: '-9px',
      },
      selectStatusRoot:{
        width: '100%',
      },
  });

class AdminOrderEdit extends Component{
    componentDidMount(){
        this.getEditOrder();
    }

    getEditOrder = () => {
        store.dispatch(getAdminEditOrder(this.props.editOrderId));
    }

    onTextChange = (e) => {
        store.dispatch(updateAdminEditOrder(e.target.id, e.target.value));
    }

    handleSelectChange = (event) => {
        store.dispatch(updateAdminEditOrder('status', event.target.value));
    }

    onAlertClose = () => {
        let alert = {text: '', variant: 'success', show: false, event: ''};
        store.dispatch(showAlert(alert));
    }

    updateEditOrder = () => {
        let alert={};        
        getDataFromServer(config.params.server_path + '/orders_update_status', {orderId: this.props.editOrder._id, updateObj: this.props.editOrder})
        .then(response => {        
            if(response.data.success === true){
                alert = {text: 'Update order success.', variant: 'success', show: true, event: this.onAlertClose }
            }else{
                alert = {text: 'Faild update order, error: ' + response.data.text, variant: 'error', show: true, event: this.onAlertClose }
            }
            console.log("AdminOrders.updateEditOrder => ", response.data.data);
            store.dispatch(showAlert(alert));            
        })
        .catch(err => {
            console.log("AdminOrders.updateEditOrder => error: ", err);
            alert = {text: 'Faild update order, error: ' + err, variant: 'error', show: true, event: this.onAlertClose };
            store.dispatch(showAlert(alert));
        });
    }
    
    render(){
        let {classes, editOrder, user, adminOrderHendler, alert} = this.props;
        const isLoaded = editOrder !== null;
        let shipping = user.properties[0], datePurchase = '';
        if(isLoaded && editOrder.date_purchase !== undefined){
            let date = new Date(editOrder.date_purchase);
            let month = (date.getMonth() + 1).length > 1 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
            datePurchase = month + '/' + date.getDate() + '/' + date.getFullYear() + ' ' + date.getHours()  + ':' + date.getMinutes();
        }       
        
        console.log('admin order edit render: ', user , 'is loaded ', user.properties);
        console.log('admin order edit render props: ', this.props);
        console.log('admin order edit render props: ', editOrder);
        return(
            <div>
                {alert.show ? 
                    <SnackbarsCustom data={alert} />
                    :''
                }
                <div className={classes.root}>
                    <Grid container spacing={8}>
                        <Button className={classes.button} onClick={(node) => { adminOrderHendler('list', null); } }>
                            <KeyboardArrowLeft />
                            Back to list
                        </Button>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item sm={12} className={classes.separatorGrid}>
                            <span className={classes.labelSpan}>Transaction</span>
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Transaction ID:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="pay_transaction_id"
                                placeholder="Transaction ID:"
                                value={isLoaded && editOrder.pay_transaction_id !== undefined ? editOrder.pay_transaction_id : ''}
                                onChange={(node) => this.onTextChange(node)}
                                disabled
                            />           
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Payment status:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="pay_status"
                                placeholder="Payment status"
                                value={isLoaded && editOrder.pay_status !== undefined ? editOrder.pay_status : ''}
                                onChange={(node) => this.onTextChange(node)}
                                disabled
                            />           
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item sm={12} className={classes.separatorGrid}>
                            <span className={classes.labelSpan}>Order details</span>
                        </Grid>

                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>SKU:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="sku"
                                placeholder="SKU"
                                value={isLoaded ? editOrder.sku : ''}
                                onChange={(node) => this.onTextChange(node)}
                                disabled
                            />           
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Title:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="receiver_name"
                                placeholder="Receiver Name"
                                value={isLoaded ? editOrder.title : ''}
                                onChange={(node) => this.onTextChange(node)}
                                disabled
                            />           
                        </Grid>                        
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Sizes:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="sizes"
                                placeholder="Sizes"
                                value={isLoaded ? editOrder.sizes : ''}
                                onChange={(node) => this.onTextChange(node)}
                                disabled
                            />           
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Styles:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="styles"
                                placeholder="Styles"
                                value={isLoaded ? editOrder.styles : ''}
                                onChange={(node) => this.onTextChange(node)}
                                disabled
                            />           
                        </Grid>

                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Price:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="price"
                                placeholder="Price"
                                value={isLoaded ? editOrder.price : ''}
                                onChange={(node) => this.onTextChange(node)}
                                disabled
                            />           
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>CurrencyId:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="currencyId"
                                placeholder="CurrencyId"
                                value={isLoaded ? editOrder.currencyId : ''}
                                onChange={(node) => this.onTextChange(node)}
                                disabled
                            />           
                        </Grid>

                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Currency Format:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="currencyFormat"
                                placeholder="Currency Format"
                                value={isLoaded ? editOrder.currencyFormat : ''}
                                onChange={(node) => this.onTextChange(node)}
                                disabled
                            />           
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Free Shipping:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="isFreeShipping"
                                placeholder="Free Shipping"
                                value={isLoaded ? editOrder.isFreeShipping : ''}
                                onChange={(node) => this.onTextChange(node)}
                                disabled
                            />           
                        </Grid>

                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Shape:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="shapes"
                                placeholder="Shape"
                                value={isLoaded ? editOrder.shapes : ''}
                                onChange={(node) => this.onTextChange(node)}
                                disabled
                            />           
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Color:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="colors"
                                placeholder="Colors"
                                value={isLoaded ? editOrder.colors : ''}
                                onChange={(node) => this.onTextChange(node)}
                                disabled
                            />           
                        </Grid>

                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Material:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="materials"
                                placeholder="Material"
                                value={isLoaded ? editOrder.materials : ''}
                                onChange={(node) => this.onTextChange(node)}
                                disabled
                            />           
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Quantity:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="quantity"
                                placeholder="Quantity"
                                value={isLoaded ? editOrder.quantity : ''}
                                onChange={(node) => this.onTextChange(node)}
                                disabled
                            />           
                        </Grid>

                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Status:</span>
                            {isLoaded ? 
                                <SelectedListGeneral
                                    selected={editOrder.status}
                                    name={editOrder.status}
                                    items={adminData.ShippingStatus}
                                    handleChange={this.handleSelectChange}
                                    styles={classes.selectStatus}
                                    rootStyle={classes.selectStatusRoot}                             
                                /> : ''
                            }           
                        </Grid>                        
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Date purchase :</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="date_purchase"
                                placeholder="Date purchase"
                                value={datePurchase}
                                onChange={(node) => this.onTextChange(node)}
                                disabled
                            />
                        </Grid>
                        <Grid item sm={12} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Description:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="description"
                                placeholder="Description"
                                value={isLoaded ? editOrder.description : ''}
                                onChange={(node) => this.onTextChange(node)}
                                disabled
                            />           
                        </Grid>

                        <Grid item sm={12} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Tracking number:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="trackingNumber"
                                placeholder="Tracking number"
                                value={isLoaded ? editOrder.trackingNumber : ''}
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>

                        <Grid item sm={12} className={classes.gridItem}>
                            <span className={classes.labelSpan}>User notes:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="order_description"
                                placeholder="User notes"
                                value={isLoaded ? editOrder.order_description : ''}
                                onChange={(node) => this.onTextChange(node)}
                                disabled
                            />           
                        </Grid>
                        
                        <Grid item sm={12} className={classes.separatorGrid}>
                            <span className={classes.labelSpan}>Shipping details</span>
                        </Grid>

                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Receiver Name:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="receiver_name"
                                placeholder="Receiver Name"
                                value={isLoaded ? shipping.receiver_name : ''}
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Street, Home, Flat:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="street_home_flat"
                                placeholder="Street, Home, Flat"
                                value={isLoaded ? shipping.street_home_flat : ''}
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>

                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>City:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="city"
                                placeholder="City"
                                value={isLoaded ? shipping.city : ''}
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Country:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="country"
                                placeholder="Country"
                                value={isLoaded ? shipping.country : ''}
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>

                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Region:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="region"
                                placeholder="Region"
                                value={isLoaded ? shipping.region : ''}
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Zip Code:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="zip_code"
                                placeholder="Zip Code"
                                value={isLoaded ? shipping.zip_code : ''}
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>

                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Telephone:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="telephone"
                                placeholder="Telephone"
                                value={isLoaded ? shipping.telephone : ''}
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <span className={classes.labelSpan}>Shipping cost:</span>
                            <TextField
                                className={classNames( classes.margin, classes.textfield )}
                                id="shippingCost"
                                placeholder="Shipping cost"
                                value= {isLoaded ? editOrder.shippingCost + '$' : ''}
                                onChange={(node) => this.onTextChange(node)}
                            />           
                        </Grid>
                    </Grid>
                    <Grid container spacing={8} className={classes.imageGrid}>
                        <Grid item sm={12} className={classes.gridItem}>
                            <Button variant="contained" color="primary" className={classes.buttonSave} onClick={this.updateEditOrder}>
                                Update
                                <SaveIcon className={classes.rightIcon} />
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

AdminOrderEdit.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (store) => {
      return {
          user: store.userState.user,
          editOrder: store.adminState.editOrder,
          alert: store.alertState.alert,
      }
  }

  export default connect(mapStateToProps)(withStyles(styles)(AdminOrderEdit));
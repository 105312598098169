import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { fade } from '@material-ui/core/styles/colorManipulator';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  card: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
    textAlign: 'center',    
    [theme.breakpoints.down(1380)]: {
      paddingLeft: '10px',
      paddingRight: '0',
    },
  },
  cover: {
    width: 151,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  button: {
     background: '#000',
     'margin-top': '10px',
     '&:hover': {
       backgroundColor: fade('#efce49', 0.9) 
     },
  },
  title: {
    width: '250px',
  },
  input: {
    width: '40px',
  },
  column: {
      width: '80px',
  },
  separator: {
     width: '80%',
     marginLeft: '10%',
     marginBottom: '10px',
     borderBottom: 'solid 1px #ccc',
  },
  total: {
    paddingLeft: '85%',
  },
  totalListItem: {
    paddingBottom: '5px',
    paddingTop: '0px',
    fontWeight: 'bold',
  },
  cartImage: {
    webkitTransform: 'scale(1)',
    transform: 'scale(1)',
    webkitTransition: '.3s ease-in-out',
    transition: '.3s ease-in-out',
    width: 'auto',
    height: 'auto',
    maxWidth: '250px',
    maxHeight: '195px',
    '&:hover':{
      webkitTransform: 'scale(1.3)',
      transform: 'scale(1.3)',
    }
  },  
  divImageContaner:{
    width: 250,
    textAlign: 'center',
  }
});

function UserProfileOrderHistoryItem(props) {
  const { classes, historyOrderItem } = props;
    
  const arrPrice = historyOrderItem.price.toString().split('.');
  const price1 = arrPrice[0];
  const price2 = arrPrice.length > 1 ? '.' + arrPrice[1] : '';
  const subtotal = parseFloat(historyOrderItem.price) * parseInt(historyOrderItem.quantity);
  const total = subtotal + 10;

  return (
    <Card className={classes.card}>       
        <div className={classes.divImageContaner}>
          <figure className={classes.figure}>
            <img className={classes.cartImage} src={historyOrderItem.images.link } title={ historyOrderItem.images.title } alt={ historyOrderItem.images.title } />
          </figure>
        </div>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h6" variant="h6" className={classes.title}>
              { historyOrderItem.title }
            </Typography>            
          </CardContent>
        </div>
        <div>
          <CardContent className={classes.content}>
            <Typography component="h6" variant="h6" className={classes.column}>
              Shape
            </Typography>
            <Typography variant="subtitle" color="textSecondary">
              { historyOrderItem.shape }
            </Typography>
          </CardContent>
        </div>
        <div>
          <CardContent className={classes.content}>
            <Typography component="h6" variant="h6" className={classes.column}>
              Quantity
            </Typography>
            <input type='text' className={classes.input} value={ historyOrderItem.quantity }></input>
          </CardContent>
        </div>
        <div>
          <CardContent className={classes.content}>
            <Typography component="h6" variant="h6" className={classes.column}>
            Color
            </Typography>
            <Typography variant="subtitle" color="textSecondary">
              { historyOrderItem.color }
            </Typography>
          </CardContent>
        </div>
        <div>
          <CardContent className={classes.content}>
            <Typography component="h6" variant="h6" className={classes.column}>
            Material
            </Typography>
            <Typography variant="subtitle" color="textSecondary">
              { historyOrderItem.material }
            </Typography>
          </CardContent>
        </div>
        <div>
          <CardContent className={classes.content}>
            <Typography component="h6" variant="h6" className={classes.column}>
            Style
            </Typography>
            <Typography variant="subtitle" color="textSecondary">
              { historyOrderItem.style }
            </Typography>
          </CardContent>
        </div>
        <div>
          <CardContent className={classes.content}>
            <Typography component="h6" variant="h6" className={classes.column}>
            Size
            </Typography>
            <Typography variant="subtitle" color="textSecondary">
              { historyOrderItem.size }
            </Typography>
          </CardContent>
        </div>     
        <div>
          <CardContent className={classes.content}>
            <Typography component="h6" variant="h6" className={classes.column}>
              Price
            </Typography>
            <Typography variant="subtitle" color="textSecondary" style={{'text-align': 'center'}}>
              <span style={{'font-size': '16px'}}>$</span>
              <span style={{'font-size': '24px', 'font-weight': 'bold'}}>{price1}</span>
              <span style={{'font-size': '16px'}}>{price2}</span>
            </Typography>
          </CardContent>
        </div>
        <div>
          <CardContent className={classes.content}>
            <Typography component="h6" variant="h6" className={classes.column}>
              Status
            </Typography>
            <Typography variant="subtitle" color="textSecondary">
              { historyOrderItem.status }
            </Typography>
          </CardContent>
        </div>
        <div className={classes.separator}>&nbsp;</div>
        <Grid container spacing={16}>
          <Grid item xs={12} md={12}>
            <List className={classes.total}>
              <ListItem className={classes.totalListItem}>Subtotal: {'$' + subtotal}</ListItem>
              <ListItem className={classes.totalListItem}>Shipping: {'$10'}</ListItem>
              <ListItem className={classes.totalListItem}>Total: {'$' + total}</ListItem>
            </List>
          </Grid>
        </Grid>      
    </Card>
  );
}

UserProfileOrderHistoryItem.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(UserProfileOrderHistoryItem);


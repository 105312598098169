exports.Styles = ['abstract', 'modern', 'antique'];
exports.Shapes = ['square','triangle','circle'];
exports.Materials = ['iron', 'aluminium', 'silver', 'perspex', 'ceramics', 'glass', 'plastic', 'other'];
exports.Colors = ['red','blue','grey','green','yellow', 'purple', 'pink', 'orange', 'brown', 'other', 'no color'];
exports.Sizes = ['6','7','8','9','10','11','12','13','14','15','16','17'];
exports.UserPermission = [
    {value: 'user', label: 'user'}, 
    {value: 'admin', label: 'admin'}
];
exports.ShippingStatus = [
    {value: 'new', label: 'new'}, 
    {value: 'inprogress', label: 'inprogress'}, 
    {value: 'shipped', label: 'shipped'}, 
    {value: 'finish', label: 'finish'},
];
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import config from './../config';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    color: 'white',
    'margin-bottom' : '10px',
    'margin-left' : '20px',    
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
    color: '#000',
  },
  select: {
      '&:before':{
          borderColor: '#000',
      },
      '&:after': {
            borderColor: '#000',
        },
        '&:focus':{
            color: '#000',
        }
  },
  selectMenu: {color: '#000'},
  
  icon: {
      fill: '#000'
  }
});

class ProductEditSelectSize extends React.Component {
  
  handleChange = event => {    
    this.props.handleSelectSize(event, event.target.value);
  };

  render() {    
    const { classes, sizeItems, selectedSize } = this.props;

    return (
      <form className={classes.root} autoComplete="off">
        <FormControl className={classes.formControl}>
          <Select
            value={selectedSize}
            onChange={this.handleChange}
            name="size"
            displayEmpty
            className={classes.select}
            inputProps={{
                    classes: {
                        icon: classes.icon,
                        select: classes.select,
                        selectMenu: classes.selectMenu,
                    },
                }}
          >
            <MenuItem value='' disabled>Size</MenuItem>
            {sizeItems.map((size, i) => 
              <MenuItem value={size}>{size + ' ' + config.params.measurement}</MenuItem>
            )}            
          </Select>
        </FormControl>
      </form>
    );
  }
}

ProductEditSelectSize.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductEditSelectSize);
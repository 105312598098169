import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import ProductContant from './components/ProductContant';
import Home from './components/Home';
import ProductEdit from './components/ProductEdit';
import ShoppingCart from './components/ShoppingCart';
import UserSignUp from './components/UserSignUp'
import LoginUser from './components/LoginUser';
import UserProfile from './components/userprofile/UserProfile';
import UserProperty from './components/UserProperty';
import AdminMain from './components/admin/AdminMain';
import Pay from './components/Pay';
import ShoppingCartUserProperty from './components/ShoppingCartUserProperty';
import { connect } from 'react-redux';
import store from './store';
import * as actionFun from './actions';
import SnackbarsCustom from './components/SnackbarsCustom';
import ResetPassword from './components/ResetPassword';
import config from './config';
import { getDataFromServer } from './DataProvider';
import queryString from "query-string";
import ContactUs from './components/ContactUs';


class App extends Component {
  
  componentWillMount(){    
    this.direcLinks();
  }

  direcLinks(){
    if(window.location.href.indexOf('verification') != -1){
      let arrParams = window.location.href.split('/');
      let vcode = arrParams[arrParams.length - 1];
      this.verificationUser(vcode);
    }
    if(window.location.href.indexOf('reset_password') != -1){
      let arrParams = window.location.href.split('/');
      let vcode = arrParams[arrParams.length - 1];
      console.log("reset password: ", vcode);
      if(vcode.trim().length > 0){
        store.dispatch(actionFun.setUserVerificationCode(vcode));
        store.dispatch(actionFun.setUserResetPassword(true));        
      }      
    }
  }

  verificationUser(verificationCode){
      getDataFromServer(config.params.server_path + '/verification_user', { verificationCode: verificationCode })      
      .then((data) => {
        alert = {text: 'Verification success.', variant: 'success', show: true, event: this.onAlertClose };
        store.dispatch(actionFun.showAlert(alert));                  
      })
      .catch((err) => {
          console.log("Verification fail, error: ", err);
          alert = {text: 'Verification fail, error: '+ err +'.', variant: 'error', show: true, event: this.onAlertClose };
          store.dispatch(actionFun.showAlert(alert));           
      })
      window.location.href = config.params.server_path +'/products';
  }   
  
  onAlertClose = () => {
      let alert = {text: '', variant: 'success', show: false, event: ''};
      store.dispatch(actionFun.showAlert(alert));
  }

  render() {
    let {user, alert, resetPassword, verificationCode} = this.props;
    console.log("reset password load module: ", resetPassword);
    return (
      <Router>
        <div>
          {alert.show ? 
              <SnackbarsCustom data={alert} />
              :''
          }
          <NavBar />
          <Route exact path='/' component={(routeProps) =>  {
            console.log("reset password load module: ", resetPassword);
            if(resetPassword === false) {
               return <Home {...routeProps}/>
            }else{
              return <ResetPassword />
            }            
          }} />
          <Route path='/products' component={(routeProps) =>  <ProductContant {...routeProps}/>} />
          <Route path='/product/:id' component={(props) =>  
            {
              store.dispatch(actionFun.setFromMainPage(true)); 
              return <ProductEdit productID = {props.match.params.id} {...props}/> 
            }} />
          <Route path='/shopping-cart' component={(routeProps) =>  <ShoppingCart {...routeProps}/>} />
          <Route path='/signup' component={(routeProps) =>  <UserSignUp  {...routeProps}/>} />
          <Route path='/login' component={() =>  <LoginUser />} />
          <Route path='/userprofile' component={() => { return user._id !== undefined ?  <UserProfile /> : <LoginUser /> }} />
          <Route path='/userproperty' component={() =>  <UserProperty />} />
          <Route path='/admin' component={() =>  {return user._id !== undefined ? <AdminMain /> : <LoginUser />}} />
          <Route path='/pay' component={() =>  <Pay />} />
          <Route path='/shopping-address' component={() =>  <ShoppingCartUserProperty />} />
          <Route path='/reset_password/:id' component={() =>  <ResetPassword verificationCode = {verificationCode} />} />
          <Route path='/pay_success' component={(routeProps) => 
            { 
              let payObj = queryString.parse(routeProps.location.search);
              return <Pay payObj={payObj}/>
            }} />
          <Route path='/pay_cancel' component={() =>  { return <ProductContant /> }} />
          <Route path='/contact-us' component={() =>  <ContactUs />} />
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (store) => {
  return {
      user: store.userState.user,
      resetPassword: store.userState.resetPassword,
      verificationCode: store.userState.verificationCode,
      alert: store.alertState.alert,      
  }}
export default connect(mapStateToProps)(App);

import { combineReducers } from 'redux';
import products from './products';
import shoppingCart from './shoppingCart';
import dialog from './dialog';
import user from './user';
import userProfile from './userProfile';
import admin from './admin';
import alert from './alert';
import contactUs from './contactUs';

export default combineReducers({
    productsState: products,
    shoppingCartState: shoppingCart,
    dialogState: dialog,
    userState: user,
    userProfileState: userProfile,
    adminState: admin,
    alertState: alert,
    contactUsState: contactUs,
})
import React from 'react';
import {Grid} from '@material-ui/core';
import UserProfileOrderHistoryItem from './UserProfileOrderHistoryItem';
import store from './../../store';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { setHistoryOrders } from './../../actions';
import { getDataFromServer } from './../../DataProvider';
import config from './../../config';

const styles = theme => ({
  root: {
      marginTop: '20px',
      padding: '20px',
  },
  divButton: {
      paddingLeft: '90%'
  },
  button: {
     background: '#000',
     color: '#fff',
     'margin-top': '10px',
     '&:hover': {
       backgroundColor: fade('#efce49', 0.9) 
     },
  },
  allTotal: {
      fontWeight: 'bold',
      fontSize: '20px',
  },
  divAllTotalText:{
      paddingLeft: '80%',
      marginTop: '30px',
  },
  divSubTotalText:{
      paddingLeft: '80%',
      marginTop: '10px',
  },
  subTotalText: {
      fontSize: '20px',
  },
});

class UserProfileOrderHistory extends React.Component {

    componentDidMount(){
        getDataFromServer(config.params.server_path + '/orders_by_userId', { userId: this.props.user._id })
        .then(response => {        
            if(response.data.success === true){
                store.dispatch(setHistoryOrders(response.data.data));
            }
            console.log("UserProfileOrderHistory.componentDidMount => ", response.data.data);
        })
        .catch(err => {console.log("UserProfileOrderHistory.componentDidMount => error: ", err)}); 
    }     

    render(){
        const {classes, historyOrders} = this.props;
        // console.log("ShoppingCart render: ", this.props);
        // console.log("ShoppingCart render: ", shoppingCartItems);

        return(
            <Grid container spacing={16} className={classes.root}>
                {historyOrders.map((m,i) => 
                    <Grid item xs={12} sm={12}  md={12} lg={12}>
                        <UserProfileOrderHistoryItem historyOrderItem={m} />
                    </Grid>
                )}               
            </Grid>
        )
    }
}

const mapStateToProps = (store) => {
  return {      
      user: store.userState.user,
      historyOrders: store.userProfileState.historyOrders,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(UserProfileOrderHistory));



import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { setAdminProducts, showDialog, showAlert } from './../../actions';
import { getDataFromServer } from './../../DataProvider';
import config from './../../config';
import store from './../../store';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from './../Dialog';
import classNames from 'classnames';
import SnackbarsCustom from './../SnackbarsCustom';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: '20px 80px 80px 80px',      
      },
      tabs: {
          backgroundColor: theme.palette.common.white,
       },
       tabTitle: {
          color: '#000',
       },
       paper: {
          padding: theme.spacing.unit * 2,
          textAlign: 'center',
          color: theme.palette.text.secondary,
        },
        paperLink: {
          display: 'block',
          width: '100%',
          cursor: 'pointer',
        },
        rowGrid: {
          height: '40px',
          borderBottom: 'solid 1px #ccc',
          padding: '10px',
          '&:hover':{
              backgroundColor: '#ccc',
          }
        },
        button: {
          marginTop: '-10px',
        },
        newProductButton: {
            fontWeight: 'bold',
            textDecoration: 'underline',
        }
  });

  class AdminProducts extends Component{
    constructor(props){
        super(props);
        this.state = {removeProductId: ''};
    }

    componentDidMount(){
        this.getProductsFromServer();
    }

    getProductsFromServer = () =>{
        getDataFromServer(config.params.server_path + '/products')
        .then(response => {        
            if(response.data.success === true){
                store.dispatch(setAdminProducts(response.data.data));
            }
            console.log("AdminOrders.getProductsFromServer => ", response.data.data);
        })
        .catch(err => {console.log("AdminOrders.getProductsFromServer => error: ", err)});
    }

    removeProductDialog = (productId) => {
        let dialogData = { 
            title: 'Warning', 
            text: 'Do you want to remove product ?', 
            buttons: [{event: this.removeProduct, label: 'Remove product'}], 
            show: true 
        }
        this.state.removeProductId = productId;
        store.dispatch(showDialog(dialogData));
    }

    removeProduct = () => {
        let alert = {};
        this.handlCloseDialog();
        getDataFromServer(config.params.server_path + '/remove_product', {productId: this.state.removeProductId} )
        .then(response => {        
            if(response.data.success === true){
                alert = {text: 'Remove product success.', variant: 'success', show: true, event: this.onAlertClose }
                this.getProductsFromServer();
            }else{
                alert = {text: 'Faild remove product, error: ' + response.data.text, variant: 'error', show: true, event: this.onAlertClose } 
            }
            console.log("AdminOrders.removeProduct => ", response.data.data);
            store.dispatch(showAlert(alert));
        })
        .catch(err => {
            console.log("AdminOrders.removeProduct => error: ", err);
            alert = {text: 'Faild remove product, error: ' + err, variant: 'error', err};
            store.dispatch(showAlert(alert));
        });
    }

    handlCloseDialog = (show) => {
        let dialogData = { title: '', text: '', buttons: [], show: false }
        store.dispatch(showDialog(dialogData));
    }

    onAlertClose = () => {
        let alert = {text: '', variant: 'success', show: false, event: ''};
        store.dispatch(showAlert(alert));
    }

    render(){
        let {classes, products, adminProductHendler, dialog, alert} = this.props;
        return(
            <div className={classes.root}>
                {alert.show ? 
                    <SnackbarsCustom data={alert} />
                    :''
                }
               <Dialog dialogData={ dialog } handlDialog={this.handlCloseDialog}/>
               <Grid container spacing={8}>
                    <Button className={classNames(classes.button, classes.newProductButton)} onClick={(node) => { adminProductHendler('new', null); } }>
                        New product
                    </Button>
                </Grid>
               {products.map((product, index)=> 
                    <Grid key={index} container spacing={8} className={classes.rowGrid}>
                        <Grid item xs={6} sm={8} md={10}>
                            {product.title}
                        </Grid>
                        <Grid item xs={3} sm={2} md={1}>
                            <Button className={classes.button} onClick={(node) => { adminProductHendler('edit', product._id); } }>Edit</Button>
                        </Grid>
                        <Grid item xs={3} sm={2} md={1}>
                            <Button className={classes.button} onClick={(node) => { this.removeProductDialog(product._id); } }>Delete</Button>
                        </Grid>
                    </Grid>
                )}
            </div>
        );
    }
  }

  AdminProducts.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (store) => {
      return {
          user: store.userState.user,
          products: store.adminState.products,
          dialog: store.dialogState.dialog,
          alert: store.alertState.alert,
      }
  }

  export default connect(mapStateToProps)(withStyles(styles)(AdminProducts));